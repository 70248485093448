import React from 'react';
import axios from 'axios';
import { Modal, Button, message, Input, Icon, Switch } from 'antd';
import { Grid } from '@material-ui/core';

const EditUserModal = props => {

    const {
        openModal, setOpenModal, data
    } = props;

    const [ID, setID] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [contact, setContact] = React.useState('');
    const [completed, setCompleted] = React.useState(false);
    const [verified, setVerified] = React.useState(false);

    React.useEffect(() => {
        console.log(data);
        
        if (data) {
            console.log(data.contact)
            setID(data.id || '');
            setEmail(data.email || '');
            setFirstName(data.first_name || '');
            setLastName(data.last_name || '');
            setContact(data.contact || '');
            setCompleted(data.completed || false);
            setVerified(data.verified || false);
        }
    }, [data]);

    const errorMessage = (text) => {
        message.destroy()
        message.error(text, 3)
    }

    const editUser = () =>{
        if(firstName.length == 0){
            errorMessage('Please fill up First name')
        }
        else if(lastName.length == 0){
            errorMessage('Please fill up Last name')
        }
        else if(email.length == 0){
          errorMessage("Please fill up email")
        }
        else{
          editUserNow()
        }
    }

    const autoFormat = (value) => value == null ? null : value.replace(/[^\d.]/g, '');

    const editUserNow = async () => {
        let params = {
          id: ID,
          first_name: firstName,
          last_name: lastName,
          email: email,
          verified: verified,
          completed: completed,
          contact: contact,
        }
    
        let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
    
        let response = await axios.post(
          process.env.REACT_APP_API_URL + '/api/edit_user/', params,yourConfig
        );
    
        if(response.data.status == 'success'){
            setOpenModal(false);
            window.location.reload();
        }
        else{
          errorMessage(response.data.message)
        }
    }

    return(
        <Modal
            title="Edit User"
            centered
            visible={openModal}
            onCancel={() => setOpenModal(false)}
            footer={[
                <Button key="back" onClick={() => setOpenModal(false)} className="admin-download-btn">
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={()=>editUser()} className="add-user">
                    Save
                </Button>,
            ]}>

                <Grid container>
                    <Grid item lg={12} sm={12} style={{marginBottom: '20px', marginTop: '20px'}}>
                        <label style={{fontSize: 12, color: '#000' }}>Email</label>
                        <Input
                          placeholder="email"
                          style={{width: '100%', height: '50px'}}
                          onChange={ e => setEmail(e.target.value)}
                          value={email}
                          />
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <label style={{fontSize: 12, color: '#000' }}>First Name</label>
                      <Input
                          placeholder="First Name"
                          style={{width: '98%', marginRight: '4%', height: '50px', marginBottom: '20px'}}
                          onChange={ e => setFirstName(e.target.value)}
                          value={firstName}
                          />
                    </Grid>
                  
                    <Grid item lg={6} sm={12}>
                        <label style={{fontSize: 12, color: '#000' }}>Last Name</label>
                      <Input
                          placeholder="Last Name"
                          style={{width: '100%', height: '50px', marginBottom: '20px'}}
                          onChange={ e => setLastName(e.target.value)}
                          value={lastName}
                          />
                    </Grid>

                    <Grid item lg={12} sm={12} style={{marginBottom: '10px', marginTop: '10px'}}>
                        <label style={{fontSize: 12, color: '#000' }}>Mobile Number</label>
                        <Input
                          placeholder="Mobile Number"
                          style={{width: '100%', height: '50px'}}
                          onChange={ e => setContact(autoFormat(e.target.value))}
                          value={contact}
                          maxLength={10}
                          />
                    </Grid>

                    <Grid item lg={6} sm={12}>
                        <div>Verified</div>
                        <Switch
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                        checked={verified}
                        onChange={(checked)=>setVerified(checked)}
                        />
                    </Grid>
                  
                    <Grid item lg={6} sm={12}>
                        <div>Completed</div>
                        <Switch
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                        checked={completed}
                        onChange={(checked)=>setCompleted(checked)}
                        />
                    </Grid>
                </Grid>
        
        </Modal>
    );
}

export default EditUserModal;