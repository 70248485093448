import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Input } from 'antd';
import { TextField, InputAdornment, Grid, styled } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const QRAmount = props => {

    const {
        data, getFees,
        settingAmount, amount, customPlaceholder,
        selectedAmount, numberWithCommax
    } = props;

    const spanRef = useRef(null);

    const valueLimit = ({floatValue}) => floatValue !== null && floatValue !== undefined;       

    const StyledTextField = styled(TextField)({
        '.MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
    })

    const inputWidth = spanRef.current ? `${spanRef.current.offsetWidth + 100}px` : 180;

    return(
        <div className="qr-amount top-20">
            <span ref={spanRef} style={{fontSize: 48, visibility: 'hidden',position:'absolute' }}>{amount}</span>
            {/* For WEB text field amount only */}
            {
                (!isMobile && (data && data.any_amount)) &&
                <div>
                    <div className="qr-text-amount liveColor">Enter Amount</div>
                    <NumberFormat 
                        thousandSeparator={true}
                        value={amount}
                        onBlur={() => getFees()}
                        isAllowed={valueLimit}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        style={{ height: '48px', width: inputWidth, maxWidth:'100%', minWidth:180, marginBottom: 32 }}
                        onValueChange={(values) =>{
                            const {formattedValue, value} = values;
                            settingAmount(value)
                        }}
                        customInput={
                            TextField
                        }
                        InputProps={{
                            type: "text",
                            style: { fontSize: 48, color: '#2b2d32'},
                            startAdornment: <InputAdornment position="start">
                                <div style={{ color: '#2b2d32', fontWeight: 'bold', fontSize: '24px', padding: 20 }}>₱</div>
                            </InputAdornment>
                        }} />
                    {/* <TextField id="standard-basic"
                        onBlur={() => getFees()}
                        size="normal"
                        type="text"
                        value={amount}
                        // pattern="[0-9]+"
                        style={{ height: '48px', width: 316, marginBottom: 32, textAlign: 'center' }}
                        onChange={(e) => settingAmount(e.target.value)}
                        InputProps={{
                            type: "text",
                            style: { fontSize: 48, color: '#2b2d32', paddingLeft: paddingLeft },
                            startAdornment: <InputAdornment position="start">
                                <div style={{ color: '#2b2d32', fontWeight: 'bold', fontSize: '24px', padding: 20 }}>₱</div>
                            </InputAdornment>
                        }} /> */}
                </div>
            }
            {
                data && !data.any_amount &&
                <div hidden={data && data.any_amount}>
                    <div className="qr-text-amount liveColor">
                        <span hidden={data && !data.allow_enter_amount}>
                            Enter or</span> Select Amount
                        </div>
                    <div>
                        <NumberFormat 
                            disabled={data && !data.allow_enter_amount}
                            hidden={isMobile}
                            thousandSeparator={true}
                            value={amount}
                            onBlur={() => getFees()}
                            isAllowed={valueLimit}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{ height: '48px', width: inputWidth, maxWidth:'100%', minWidth:180, marginBottom: 32 }}
                            onValueChange={(values) =>{
                                const {formattedValue, value} = values;
                                settingAmount(value)
                            }}
                            customInput={
                                TextField
                            }
                            InputProps={{
                                type: "text",
                                style: { fontSize: 48, color: '#2b2d32' },
                                startAdornment: <InputAdornment position="start">
                                    <div style={{ color: '#2b2d32', fontWeight: 'bold', fontSize: '24px', padding: 20 }}>₱</div>
                                </InputAdornment>
                            }} />
                        {/* <TextField id="standard-basic" hidden={isMobile || data && !data.allow_enter_amount}
                            onBlur={() => getFees()}
                            size="normal"
                            type="text"
                            // pattern="[0-9]+"
                            value={amount}
                            style={{ height: '48px', width: 316, marginBottom: 32, textAlign: 'center' }}
                            onChange={(e) => settingAmount(e.target.value)}
                            InputProps={{
                                type: "text",
                                style: { fontSize: 48, color: '#2b2d32', paddingLeft: 48 },
                                startAdornment: <InputAdornment position="start">
                                    <div style={{ color: '#2b2d32', fontWeight: 'bold', fontSize: '24px', padding: 20 }}>₱</div>
                                </InputAdornment>
                            }} /> */}

                        <Input hidden={data && !data.allow_enter_amount || !isMobile}
                            onBlur={() => getFees()}
                            size="large"
                            style={{ height: '40px' }}
                            name="amount" value={amount}
                            addonBefore={data && !data.allow_enter_amount || !isMobile ? '' : '₱'}
                            onChange={(e) => settingAmount(e.target.value)}
                        />

                        <div style={{width: isMobile ? '100%' : '450px'}}>
                            <Grid container>
                                {
                                    data && data.customize_amount.map((item, key) => {
                                        return (
                                            <Grid item lg={3} sm={4} xs={4} style={{ marginTop: 12 }}>
                                                <span style={{paddingBottom:'5px'}}>{data.customize_amount_label[key]}</span>
                                                <button 
                                                    className={amount == parseFloat(item) ? "qr-btn-selected" : "qr-btn"} 
                                                    onClick={() => selectedAmount(item)} 
                                                    disabled={amount == parseFloat(item)}
                                                >
                                                    <span style={{ fontSize: 12 }}>₱</span> <span>{numberWithCommax(item)}</span>
                                                </button>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </div>
                    </div>
                </div>
            }
            
            <div hidden={!isMobile || data && !data.any_amount} align="left">
                {customPlaceholder('Enter Amount')}
                <Input
                    onBlur={() => getFees()}
                    size="large"
                    style={{ height: '40px', width: '100%'}}
                    name="amount" value={amount}
                    addonBefore={'₱'}
                    onChange={(e) => settingAmount(e.target.value)} />
            </div>
        </div>
    )
}

export default QRAmount;
