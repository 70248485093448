import React from 'react';
import { useState, useEffect } from 'react';
import { Card, Typography, Button, Switch, Modal, Image, message, Spin } from 'antd';
import { Grid, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { setBatchGenerated } from '../../../../../orders/orderAction';
import axios from 'axios';


const MultiwalletSettings = (props) => {

    const { data, id } = props

    const SETTING_NAME_MY_QR_CODE_ENABLED = "my_qr_code_enabled";

    const [paymentLinkEnabled, setPaymentLinkEnabled] = useState(data.generate_payment_link_enabled);
    const [checkoutEnabled, setCheckoutEnabled] = useState(data.checkout_enabled);
    const [myQRCodeEnabled, setMyQRCodeEnabled] = useState(data.my_qr_code_enabled == "enabled");
    const [addMoneyEnabled, setAddMoneyEnabled] = useState(data.add_money_enabled);
    const [transferMoneyEnabled, setTransferMoneyEnabled] = useState(data.transfer_money_enabled);
    const [payoutEnabled, setPayoutEnabled] = useState(data.payout_enabled);
    const [linkStoreEnabled, setLinkStoreEnabled] = useState(data.link_store_enabled);
    const [bannerEnabled, setBannerEnabled] = useState(data.exclusive_banners_enabled);
    const [previousPayoutType, setPreviousPayoutType] = useState(data.payout_type);
    const [payoutType, setPayoutType] = useState(data.payout_type);
    const [payoutTypeDisabled, setPayoutTypeDisabled] = useState(!data.payout_enabled);
    const [openModal, setOpenModal] = useState(false);
    const userType = localStorage.getItem('userType');
    const [settingName, setSettingName] = useState(null);
    const [settingValue, setSettingValue] = useState(null);
    const [settingIsToggleable, setSettingIsToggleable] = useState({
        [SETTING_NAME_MY_QR_CODE_ENABLED]: false,
    });
    const notAdmin = ['SW', 'ME', 'CO'].includes(userType);

    const payment_request_settings = [
        {
            title: 'Generate Payment Link',
            key: 'generate_payment_link_enabled',
            description: data.multiwallet ? 'Master wallet can share links to customers.'
                : 'Sub-wallet can share links to customers.',
            checked: paymentLinkEnabled,
            hidden: false,
            onChange: (value) => {
                setOpenModal(true)
                setPaymentLinkEnabled(!paymentLinkEnabled);
                setSettingName('generate_payment_link_enabled')
                setSettingValue(value)
            },
            onCancelUpdate: () => {
                setPaymentLinkEnabled(!paymentLinkEnabled)
            },
            disabled: false,
        },
        {
            title: 'My Checkout',
            key: 'checkout_enabled',
            description: data.multiwallet ? 'Master Wallet can share MyCheckout link to customers or scan the QR code.'
                : 'Sub-wallet can share MyCheckout link to customers or scan the QR code.',
            checked: checkoutEnabled,
            hidden: false,
            onChange: (value) => {
                setOpenModal(true)
                setCheckoutEnabled(!checkoutEnabled);
                setSettingName('checkout_enabled')
                setSettingValue(value)
            },
            disabled: false,

        },
    ]

    const my_qr_code_enabled_settings = [
        {
            title: 'My QR Code',
            key: SETTING_NAME_MY_QR_CODE_ENABLED,
            description: data.multiwallet ? 'Master wallet can generate a static QRPH code. Turning this off disables the master wallet\'s current static QRPH code, if existing.'
                : 'Sub-wallet can generate a static QRPH code. Turning this off disables the sub-wallet\'s current static QRPH code, if existing.',
            checked: myQRCodeEnabled,
            hidden: false,
            onChange: (value) => {
                setOpenModal(true)
                setSettingName(SETTING_NAME_MY_QR_CODE_ENABLED)
                setSettingValue(value ? "enabled" : "disabled")
            },
            disabled: !settingIsToggleable[SETTING_NAME_MY_QR_CODE_ENABLED],

        }
    ]

    const add_money_settings = [
        {
            title: 'Add Money',
            key: 'add_money_enabled',
            description: data.multiwallet ? 'Master wallet can add money through our partners.'
                : 'Sub-wallet can add money through our partners.',
            checked: addMoneyEnabled,
            hidden: false,
            onChange: (value) => {
                setOpenModal(true)
                setAddMoneyEnabled(!addMoneyEnabled);
                setSettingName('add_money_enabled')
                setSettingValue(value)
            },
            disabled: false,

        }
    ]

    const transfer_money_settings = [
        {
            title: 'Transfer Money',
            key: 'transfer_money_enabled',
            description: data.multiwallet ? 'Master wallet can transfer money to master wallet.'
                : 'Sub-wallet can transfer money to master wallet.',
            checked: transferMoneyEnabled,
            hidden: false,
            onChange: (value) => {
                setOpenModal(true)
                setTransferMoneyEnabled(!transferMoneyEnabled);
                setSettingName('transfer_money_enabled')
                setSettingValue(value)
            },
            disabled: false,

        },
    ]

    const payout_settings = [
        {
            title: 'Payout',
            key: 'payout_enabled',
            description: data.multiwallet ? 'Master wallet can cash out his Bux wallet balance'
                : 'Sub-wallet can cash out his Bux wallet balance',
            checked: payoutEnabled,
            hidden: false,
            onChange: (value) => {
                setOpenModal(true)
                setPayoutEnabled(!payoutEnabled)
                setPayoutTypeDisabled(!value)
                setSettingName('payout_enabled')
                setSettingValue(value)
            },
            disabled: false,

        },
    ]

    const link_store_settings = [
        {
            title: 'Link Store',
            key: 'link_store_enabled',
            description: data.multiwallet ? 'Master wallet can integrate existing e-commerce store.'
                : 'Sub-wallet can integrate existing e-commerce store.',
            checked: linkStoreEnabled,
            hidden: (data && !data.multiwallet),
            onChange: (value) => {
                setOpenModal(true)
                setLinkStoreEnabled(!linkStoreEnabled)
                setSettingName('link_store_enabled')
                setSettingValue(value)
            },
            disabled: false,

        },
    ]

    const banner_settings = [
        {
            title: 'Exclusive For You Banners',
            key: 'exclusive_banners_enabled',
            description: data.multiwallet ? 'Master wallet can see promotional banners in the dashboard.'
                : 'Sub-wallet can see promotional banners in the dashboard.',
            checked: bannerEnabled,
            hidden: false,
            onChange: (value) => {
                setOpenModal(true)
                setBannerEnabled(!bannerEnabled)
                setSettingName('exclusive_banners_enabled')
                setSettingValue(value)
            },
            disabled: false,

        },
    ]

    const onPayoutTypeChange = (e) => {
        setPreviousPayoutType(payoutType)
        setPayoutType(e);
        setOpenModal(true);
        setSettingName('payout_type');
        setSettingValue(e)
    };

    const onCancelSettingUpdate = () => {
        setOpenModal(false);
        if(settingName === 'payout_type'){
            setPayoutType(previousPayoutType);
            setPreviousPayoutType(previousPayoutType);
        } else {
            if(settingName === 'generate_payment_link_enabled') {
                setPaymentLinkEnabled(!paymentLinkEnabled);
            } else if (settingName === 'checkout_enabled') {
                setCheckoutEnabled(!checkoutEnabled);
            } else if (settingName === 'add_money_enabled') {
                setAddMoneyEnabled(!addMoneyEnabled);
            } else if (settingName === 'transfer_money_enabled') {
                setTransferMoneyEnabled(!transferMoneyEnabled)
            } else if (settingName === 'payout_enabled') {
                setPayoutEnabled(!payoutEnabled);
            } else if (settingName === 'link_store_enabled') {
                setLinkStoreEnabled(!linkStoreEnabled);
            } else if (settingName === 'exclusive_banners_enabled') {
                setBannerEnabled(!bannerEnabled);
            }
        }
    }

    const radioStyles = createMuiTheme({
        palette: {
            primary: { main: '#0A315E' },
        },
        disabled: {
            cursor: 'not-allowed'
        },
        root: {
            cursor: 'not-allowed',
            '&:disabled': {
                cursor: 'not-allowed'
            }
        },
        radio: {
            '&$checked': {
                color: '#0A315E'
            },
            '&:disabled': {
                cursor: 'not-allowed',
                pointerEvents: 'all !important'
            }
        },
    });

    const MultiwalletSettingsCard = ({ setting_group, setting_card_header }) => {
        return (
            <div style={styles.body}>
                <div style={styles.table}>
                    {setting_card_header ? <Typography style={{ ...styles.rowTitle, padding: '20px 24px 20px 24px' }}>{setting_card_header}</Typography> : ''}
                    {
                        setting_group.map((row, i) => {
                            return (
                                <Grid item lg={12} xs={6} style={{ ...styles.row, borderTop: '1px solid #E6EAF0', paddingTop: '20px', paddingBottom: '20px' }} >
                                    <div>
                                        <Typography style={styles.rowTitle} >{row.title}</Typography>
                                        <Typography style={styles.rowDesc} >{row.description}</Typography>
                                    </div>
                                    <Switch
                                        size="small"
                                        style={{ margin: '0px 20px 0px 12px', backgroundColor: `${row.checked ? '#0A315E' : '#D1D5DD'}` }} disabled={row.disabled}
                                        onChange={row.onChange}
                                        checked={row.checked} />
                                </Grid>
                            )
                        })
                    }
                    { setting_group[0].title === "Payout" ? <PayoutModeGroup value={payoutType} /> : '' }
                </div>
            </div>
        )
    };

    const PayoutModeGroup = (value) => {
        return (
            <div>
                <Typography style={{ ...styles.rowTitle, padding: '20px 24px 10px 24px' }}>Select Payout Mode</Typography>
                <Grid item lg={12} xs={6} style={{ ...styles.row, paddingBottom: '10px' }}>
                    <FormControl>
                        <RadioGroup
                            defaultValue={payoutType}
                            name="payout-type-group"
                            value={payoutType}
                        >
                            <div style={{ ...styles.radioRow }}>
                                <div style={{ ...styles.radioColumn }}>

                                    <FormControlLabel value={1}
                                        control={
                                            <MuiThemeProvider theme={radioStyles}>
                                                <Radio size="small" color="primary" checked={payoutType === 1} onClick={() => onPayoutTypeChange(1)} disabled={!payoutEnabled} />
                                            </MuiThemeProvider>
                                        }
                                    />
                                </div>
                                <div style={{ width: '95%', paddingLeft: '50px' }}>
                                    <div>
                                        <Typography style={styles.rowTitle} >Auto Sweeping</Typography>
                                        <Typography style={styles.rowDesc} >Payout by schedule</Typography>
                                    </div>
                                </div>
                            </div>
                            <div style={{ ...styles.radioRow }}>
                                <div style={{ ...styles.radioColumn }}>
                                    <FormControlLabel value={2}
                                        control={
                                            <MuiThemeProvider theme={radioStyles}>
                                                <Radio size="small" color="primary" checked={payoutType === 2} onClick={() => onPayoutTypeChange(2)} disabled={!payoutEnabled} />
                                            </MuiThemeProvider>
                                        }
                                    />

                                </div>
                                <div style={{ width: '95%', paddingLeft: '50px' }}>
                                    <div>
                                        <Typography style={styles.rowTitle} >Manual Payout</Typography>
                                        <Typography style={styles.rowDesc} >Payout triggered by sub-wallet</Typography>
                                    </div>
                                </div>
                            </div>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </div>
        )
    };

    const UpdateSettingsConfirmationModal = () => {
        const [settingIsUpdating, setSettingIsUpdating] = useState(false);

        const updateMultiwalletDefinedSettings = async () => {
            setSettingIsUpdating(true);
            try {
                
                const endpoint = notAdmin ? '/api/user/multiwallet/' + id + '/settings/' : '/api/admin/multiwallet/' + id + '/settings/'
    
                let params = {
                    'user_id': id,
                    'setting_name': settingName,
                    'setting_value': settingValue
                }
    
                const res = await axios.post(
                    process.env.REACT_APP_API_URL + endpoint, params, yourConfig
                );
                if (res.data.status) {
                    message.success(res.data.message)
    
                    if (settingName == SETTING_NAME_MY_QR_CODE_ENABLED) {
                        setMyQRCodeEnabled(settingValue == "enabled");
                    }
                } else {
                    message.error('Error, try again later');
                }
                setOpenModal(false);
    
            } catch (error) {
                message.error('Error, try again later');
                setOpenModal(false);
            }
            setSettingIsUpdating(false);
        };

        return (
            <Modal
                title={<Typography style={{fontWeight: 700, fontSize: 28, padding: '10px 0px', color: '#2B2D32'}} >
                    {settingName === "payout_type" ? "Change Payout Mode" : "Enable / Disable Feature"}
                </Typography>}
                centered
                visible={openModal}
                width={450}
                onCancel={() => setOpenModal(false)}
                footer={[
                    <Button key="back" onClick={() => onCancelSettingUpdate()} className="admin-download-btn">
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => updateMultiwalletDefinedSettings()} className="add-user">
                        {
                            !settingIsUpdating ?
                            <span>
                                Confirm
                            </span> :
                            <span>
                                <Spin />
                            </span>
                        }
                    </Button>,
                ]}>
                <Grid container>

                    <Grid item lg={12} xs={12}>
                    {   settingName !== 'payout_type' ?
                        <span style={{color: '#2B2D32', fontSize: 16, fontWeight: 400}}>Are you sure you want to toggle this setting?</span> :
                        <span style={{color: '#2B2D32', fontSize: 16, fontWeight: 400}}>Are you sure you want to change Payout Mode??</span>}
                    </Grid>

                </Grid>
            </Modal>
        )
    };

    let yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    };

    useEffect(() => {
        fetchMultiWalletDefinedSettings();
        fetchMultiWalletDefinedSettingToggleability();
    }, []);

    const fetchMultiWalletDefinedSettings = async () => {
        try {
            const endpoint = notAdmin ? '/api/user/multiwallet/' + id + '/settings/' : '/api/admin/multiwallet/' + id + '/settings/'
            const res = await axios.get(
                process.env.REACT_APP_API_URL + endpoint, yourConfig
            );
            if (res.data) {
                props = res.data
                setPaymentLinkEnabled(res.data.generate_payment_link_enabled);
                setCheckoutEnabled(res.data.checkout_enabled);
                setMyQRCodeEnabled(res.data[SETTING_NAME_MY_QR_CODE_ENABLED] == "enabled")
                setAddMoneyEnabled(res.data.add_money_enabled);
                setTransferMoneyEnabled(res.data.transfer_money_enabled);
                setPayoutEnabled(res.data.payout_enabled);
                setLinkStoreEnabled(res.data.link_store_enabled);
                setBannerEnabled(res.data.exclusive_banners_enabled);
                setPayoutType(res.data.payout_type);

            } else {
                message.error(res.data.message);
            }

        } catch (error) {
            console.log(error);
            message.error('Error, try again later');
        }
    };

    const fetchMultiWalletDefinedSettingToggleability = async () => {
        try {
            const endpoint = notAdmin ? '/api/user/multiwallet/' + id + '/settings/is_toggleable/' : '/api/admin/multiwallet/' + id + '/settings/is_toggleable/'
            const res = await axios.get(
                process.env.REACT_APP_API_URL + endpoint, yourConfig
            );

            setSettingIsToggleable(res.data.settings)
        } catch (error) {
            message.error('Error, try again later');
        }
    };

    return (
        <div>
            {
                (data.user_type === 'SW') ?
                    <Typography style={styles.mwHeader}> Master Wallet: <span style={{ fontWeight: '800', textDecoration: 'underline' }}>{data.business_name}</span></Typography> : ''
            }
            <Card style={styles.root} bodyStyle={styles.cardBody}>

                {
                    (data && data.user_type !== 'SW') ?
                        <Typography style={styles.header}> Enable / Disable features </Typography> :
                        <Typography style={styles.header}> Sub-Wallet Enable / Disable features </Typography>
                }

                <MultiwalletSettingsCard setting_group={payment_request_settings} setting_card_header={"Payment Request"} />
                <MultiwalletSettingsCard setting_group={my_qr_code_enabled_settings} />
                <MultiwalletSettingsCard setting_group={add_money_settings} />
                <MultiwalletSettingsCard setting_group={transfer_money_settings} />
                <MultiwalletSettingsCard setting_group={payout_settings} />
                {
                    link_store_settings[0].hidden ? '' : <MultiwalletSettingsCard setting_group={link_store_settings} />
                }
                <MultiwalletSettingsCard setting_group={banner_settings} />
                <UpdateSettingsConfirmationModal />
            </Card >
        </div>
    )
}

const styles = {
    root: {
        width: '684px',
        display: 'flex',
        marginTop: 20,
        justifyContent: 'center'
    },
    title: {
        fontWeight: '400',
        fontSize: 16,
        lineHeight: 24,
    },
    cardBody: {
        // display: 'flex',
        // alignItems: 'center'
        width: '100%'
    },
    body: {
        padding: '20px 0 0 0'
    },
    row: {
        // border: '1px solid #E6EAF0'
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px 24px',
        alignItems: 'center'
    },
    table: {
        border: '1px solid #E6EAF0',
        borderRadius: '4px 4px 4px 4px',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: '#2b2d32'
    },
    label: {
        color: '#2B2D32',
        fontSize: 16,
        paddingBottom: 8
    },
    rowTitle: {
        color: '#000',
        fontSize: 16,
        fontWeight: '400'
    },
    rowDesc: {
        color: "#2B2D32",
        opacity: 0.64,
        fontSize: 14,
        fontWeight: '400',
    },
    header: {
        color: '#000000',
        fontSize: 16,
        fontWeight: '500'
    },
    mwHeader: {
        color: '#000000',
        fontSize: 16,
        fontWeight: '300'
    },
    subheader: {
        fontWeight: '400',
        fontSize: '16',
        lineHeight: '24',
    },
    radioColumn: {
        float: 'left',
        width: '5%',
    },
    radioRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 0px',
    },
    right: {
        width: '80%'
    },
}

export default MultiwalletSettings;