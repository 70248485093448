import React, { useCallback } from 'react';
import UploadCsvModal from './uploadCsvModal';
import TablePreviewModal from './tablePreviewModal';
import { jsonToCSV } from 'react-papaparse';
import multiwalletServices from '../../multiwalletServices';
import { Typography, Button, Icon, Table, Pagination, Input, message, Form, InputNumber } from 'antd';
import { useSelector, useDispatch } from '../../../../__test__/react-redux-hooks';
import SuccessTransferModal from './success_transfer_modal';
import OtpModal from '../otp_modal';
import moment from 'moment';
import multiwalletActions from '../../multiwalletActions';
import { history } from '../../../../store/history';

const template = [
    {
        "Full Name": 'Johny Bravo',
        "Email": 'johny@sample.com',
        "Mobile Number": '09123456789',
        "Amount": 200,
    },
    {
        "Full Name": 'John Week',
        "Email": 'weekjohn@ubx.ph',
        "Mobile Number": '09221234555',
        "Amount": 300,
    },
    {
        "Full Name": 'Sam Smyth',
        "Email": 'sammy@sample.com',
        "Mobile Number": '09191231232',
        "Amount": 400,
    },
]

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

const EditableCell = (props) => {
    const [isEditing, setIsEditing] = React.useState(false);
    const transferMoneyBoxData = useSelector(state => state.multiwallet.transMoneyBoxData);
    const dispatch = useDispatch();

    const hasEmptyAmount = (data) => {
        const isEmpty = data.every(item => item.amount && item.amount && item.amount >= 1);
        if (isEmpty) {
            return false;
        }
        return true;
    }

    const getTotalAmount = (data) => {
        let total = 0;
        data && data.map((row, i) => {
            total = total + parseFloat(row.amount ? row.amount : 0);
        })
        return total;
    }

    const onTransBoxChangeAmount = (row, key) => {
        const newData = transferMoneyBoxData && transferMoneyBoxData;
        const index = newData && newData.findIndex(item => row.email === item.email);
        const item = newData && newData[index];
        newData && newData.splice(index, 1, {
            ...item,
            ...row,
        });
        dispatch(multiwalletActions.setTransferMoneyBoxData(newData));
        dispatch(multiwalletActions.setTransferBtn(!hasEmptyAmount(newData)));
        dispatch(multiwalletActions.setTransferBoxTotalAmount(getTotalAmount(newData)));
    }

    const toggleEdit = () => {
        const editing = !isEditing;
        setIsEditing({ editing }, () => {
            if (editing) {
                props.input.focus();
            }
        });
    };

    const autoFormat = (str) => {
        str = str.toString();
        if(str !== null && str.indexOf(".") > -1){
            str = str.slice(0, (str.indexOf(".")) + 2 + 1);
            return parseFloat(str.replace(/[^\d\.]/g, ''));
        }
        return str;
    }

    const save = (e, form) => {
        const { record, handleSave, handleChange } = props;
        form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            // toggleEdit();
            onTransBoxChangeAmount({ ...record, amount: autoFormat(e.target.value) })
            // handleSave({ ...record, ...values });
        });
    };

    const remove = (email) => {
        props.handleRemove(email);
    }

    const renderCell = (form, input) => {
        // this.form = form;
        const { children, dataIndex, record, title } = props;
        // const { editing } = this.state;
        return props.editable && record[dataIndex] ? (
            <div className="editable-cell-value-wrap" style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                        rules: [
                            {
                                required: true,
                                message: `${title} is required.`,
                            },
                        ],
                        initialValue: record[dataIndex]
                    })(props.inputType === 'number' ?
                        <Input step=".01" type='number' value={record[dataIndex]} ref={node => (input = node)} onPressEnter={(e) => save(e, form)} onBlur={(e) => save(e, form)} onChange={(e) => { save(e, form);}} />
                        :
                        <Input value={record[dataIndex]} ref={node => (input = node)} onPressEnter={(e) => save(e, form)} onBlur={(e) => save(e, form)} />)}
                </Form.Item>
                <a onClick={() => remove(record.email)}><Typography style={styles.removeBtn}>-</Typography></a>
            </div>
        ) : props.editable && !record[dataIndex] ?
            <div className="editable-cell-value-wrap" style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                        rules: [
                            {
                                required: true,
                                message: `${title} is required.`,
                            },
                        ],
                        initialValue: ''
                    })(props.inputType === 'number' ?
                        <Input type='number' value={record[dataIndex]} ref={node => (input = node)} onPressEnter={(e) => save(e, form)} onBlur={(e) => save(e, form)} onChange={(e) => save(e, form)} />
                        :
                        <Input value={record[dataIndex]} ref={node => (input = node)} onPressEnter={(e) => save(e, form)} onBlur={(e) => save(e, form)} />)}
                </Form.Item>
                <a onClick={() => remove(record.email)}><Typography style={styles.removeBtn}>-</Typography></a>
            </div>
            : (
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
    };

    const {
        editable,
        dataIndex,
        title,
        record,
        index,
        handleSave,
        children,
        ...restProps
    } = props;
    return (
        <td {...restProps}>
            {editable ? (
                <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>
            ) : (
                children
            )}
        </td>
    );
}

const TransferMoneyBox = (props) => {
    const { segments, data, isLoading, onRemoveRow, onTransferSuccess, toggleLoading } = props;
    const [dloadBtnStyle, setDloadBtnStyle] = React.useState({ color: '#1DD28B', backgroundColor: 'transparent' });
    const [loading, setLoading] = React.useState(false);
    const [searchVal, setSearchVal] = React.useState(null);
    const [uploadModalShow, setUploadModalShow] = React.useState(false);
    const [tablePreviewShow, setTablePreviewShow] = React.useState(false);
    const transferMoneyData = useSelector(state => state.multiwallet.transferMoneyData);
    const transferMoneyBoxData = useSelector(state => state.multiwallet.transMoneyBoxData);
    const transferMoneyBoxTotalAmount = useSelector(state => state.multiwallet.totalTransBoxAmount);
    const enableTransferBtn = useSelector(state => state.multiwallet.enableTransferBtn);
    const [transferSuccessShow, setTransferSuccessShow] = React.useState(false);
    const [otpModalShow, setOtpModalShow] = React.useState(false);
    const [otpData, setOtpData] = React.useState(null);
    const [resetTimer, setResetTimer] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [minPage, setMinPage] = React.useState(0);
    const [maxPage, setMaxPage] = React.useState(pageSize);
    const [successTranxData, setSuccessTranxData] = React.useState(null);
    const [isEmailOtp, setIsEmailOtp] = React.useState(false);
    const [tablePreviewData, setTablePreviewData] = React.useState(null);
    const dispatch = useDispatch();
    const testMode = (history.location.pathname.indexOf('/test') > -1);

    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'full_name',
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'contact',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            editable: true,
            width: '30%',
        },
    ]

    const components = {
        body: {
            row: EditableFormRow,
            cell: EditableCell,
        },
    };
    const columns = tableColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record, index) => ({
                record,
                inputType: 'number',
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: (row) => onTransBoxChangeAmount(row, index),
                handleRemove: (email) => onRemoveRow(email),
            }),
        };
    });

    const hasEmptyAmount = (data) => {
        const isEmpty = data.every(item => item.amount && item.amount && item.amount >= 1);
        if (isEmpty) {
            return false;
        }
        return true;
    }

    const getTotalAmount = (data) => {
        let total = 0;
        data && data.map((row, i) => {
            total = total + parseFloat(row.amount ? row.amount : 0);
        })
        return total;
    }

    const onTransBoxChangeAmount = (row, key) => {
        const newData = transferMoneyBoxData && transferMoneyBoxData;
        const index = newData && newData.findIndex(item => row.email === item.email);
        const item = newData && newData[index];
        newData && newData.splice(index, 1, {
            ...item,
            ...row,
        });
        dispatch(multiwalletActions.setTransferMoneyBoxData(newData));
    }

    const addToTransferMoneyBox = (newData) => {
        const dataSource = newData;
        let filteredData = transferMoneyBoxData;
        if (transferMoneyBoxData && transferMoneyBoxData.length) {
            transferMoneyBoxData.map((row, i) => {
                dataSource.findIndex(item => {
                    if (row.email === item.email) {
                        filteredData = filteredData.filter((item) => row.email !== item.email)
                    }
                });
            })
            dispatch(multiwalletActions.setTransferMoneyBoxData([...filteredData, ...dataSource]));
            dispatch(multiwalletActions.setTransferBtn(!hasEmptyAmount([...filteredData, ...dataSource])));
            dispatch(multiwalletActions.setTransferBoxTotalAmount(getTotalAmount([...filteredData, ...dataSource])));
        } else {
            dispatch(multiwalletActions.setTransferMoneyBoxData(newData));
            dispatch(multiwalletActions.setTransferBtn(hasEmptyAmount((newData))));
            dispatch(multiwalletActions.setTransferBoxTotalAmount(getTotalAmount(newData)));
        }
        toggleLoading();
    };


    const handleSearch = (e) => {
        setSearchVal(e.target.value);
    }

    const changePage = (page, pageSize) => {
        setMaxPage(page * pageSize)
        setMinPage((page - 1) * pageSize)
        setCurrentPage(page)
    }

    const onDownloadHover = () => {
        setDloadBtnStyle({
            color: '#FFF',
            backgroundColor: '#1DD28B'
        });
    }

    const onDownloadLeave = () => {
        setDloadBtnStyle({
            color: '#1DD28B',
            backgroundColor: 'transparent'
        });
    }

    const renderData = (history) => {
        let filteredData = [];
        if (history && history !== null && transferMoneyBoxData && transferMoneyBoxData.length > 0) {
            filteredData = transferMoneyBoxData.filter(data =>
                data.full_name && data.full_name.toLowerCase().includes(history && history.toLowerCase()) ||
                data.amount && data.amount.toString().toLowerCase().includes(history.toLowerCase()) ||
                data.contact && data.contact.toLowerCase().includes(history.toLowerCase()) ||
                data.email && data.email.toLowerCase().includes(history.toLowerCase()));
            return filteredData ? filteredData.slice(minPage, maxPage) : null;
            // filteredData = data.filter(data => data && data.amount.includes(history.toLowerCase()));
            // return filteredData;
        } else {
            return transferMoneyBoxData ? transferMoneyBoxData.slice(minPage, maxPage) : null;
        }

    }

    const exportTemplate = () => {
        const csv = jsonToCSV(template);
        const blob = new Blob([csv]);
        const a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
        a.download = "subwallet_money_transfer_template.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const onTransfer = async (transfer_money_uid) => {
        setLoading(true);
        try {
            const res = await multiwalletServices.batchTransferMoney(testMode, renderData(searchVal), transfer_money_uid);
            if (res.status === 'success') {
                setSuccessTranxData(res);
                setTransferSuccessShow(true);
            } else {
                message.error(res.message);
            }
        } catch (error) {
            message.error("Please try again later.")
        }
        setLoading(false);
    }

    const fetchOTP = async () => {
        setLoading(true);
        try {
            const res = await multiwalletServices.getOTP(testMode);
            if (res.status === 'success') {
                setOtpData(res);
                setOtpModalShow(true);
            }
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    }

    const handleEmailOtp = async () => {
        setLoading(true);
        try {
            const response = await multiwalletServices.getOtpEmail('transfer_money');
            if (response.status === 'success') {
                setOtpData('');
                setIsEmailOtp(true);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        dispatch(multiwalletActions.setTransferMoneyBoxData([]));
    }, [])
    return (
        <div style={styles.root}>
            <div style={styles.headerDiv}>
                <Typography style={styles.title}>Transfer Money Box</Typography>
                <div>
                    <button
                        onClick={exportTemplate}
                        name='exportBtn'
                        className={`outline-btn--${segments} btn-height right-8`}
                    >
                    Download Template
                    </button>
                    <button  
                        className={`outline-btn--${segments} btn-height right-8`}
                        onClick={() => setUploadModalShow(true)} >
                            <Icon type='download' className="right-4" />
                            Import Batch list
                    </button>
                </div>
            </div>
            <div style={styles.body}>
                <div style={{ ...styles.tableHeader, justifyContent: !(data && data.length) ? 'flex-end' : 'space-between' }}>
                    <Typography hidden={!(data && data.length)} style={styles.title}>{data && data.length} sub-wallet added</Typography>
                    <Input
                        placeholder="Search"
                        style={{ width: '200px', height: '40px', borderRadius: '4px', padding: '0 8px 0 0px' }}
                        suffix={<Icon hidden type="search" style={{ fontSize: '18px' }} />}
                        onChange={handleSearch}
                        value={searchVal}
                        hidden
                    />
                </div>
                {/* <Table
                    rowClassName="table-row-light"
                    columns={columns}
                    dataSource={renderData(searchVal)}
                    loading={isLoading || loading}
                    pagination={false}
                /> */}
                <Table
                    components={components}
                    rowClassName="table-row-light"
                    dataSource={!isLoading && renderData(searchVal)}
                    loading={isLoading || loading}
                    columns={columns}
                    pagination={false}
                />
                <Pagination
                    size='small'
                    total={data && data.length}
                    defaultPageSize={10}
                    defaultCurrent={1}
                    current={currentPage}
                    onChange={(page, pageSize) => changePage(page, pageSize)}
                    style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
                />
            </div>
            <div style={styles.footer}>
                <div style={styles.balDiv}>
                    <Typography style={styles.balTitle}>Total Transfer Amount</Typography>
                    <Typography style={styles.bal}>₱{transferMoneyBoxTotalAmount.toLocaleString("en-US")}</Typography>
                </div>
                <button 
                    className={`btn--${segments} btn-height`}
                    disabled={!(renderData(searchVal) && renderData(searchVal).length) || !enableTransferBtn}
                    loading={loading}
                    onClick={testMode ? onTransfer : fetchOTP} >
                        Transfer Money
                </button>
            </div>
            <UploadCsvModal
                segments={segments}
                visible={uploadModalShow}
                closeModal={() => setUploadModalShow(false)}
                exportTemplate={exportTemplate}
                onConfirm={(data) => { setUploadModalShow(false); setTablePreviewShow(true); setTablePreviewData(data) }}
            />
            <TablePreviewModal
                segments={segments}
                visible={tablePreviewShow}
                data={transferMoneyData}
                closeModal={() => setTablePreviewShow(false)}
                addTableData={(data) => addToTransferMoneyBox(data)}
                tableData={tablePreviewData}
            />
            <SuccessTransferModal 
                segments={segments}
                visible={transferSuccessShow} onClose={() => setTransferSuccessShow(false) / onTransferSuccess()} data={transferMoneyBoxData} successData={successTranxData} amount={transferMoneyBoxTotalAmount} />
            <OtpModal visible={otpModalShow} resetTimer={resetTimer} setResetTimer={() => setResetTimer(false)}
                closeModal={() => setOtpModalShow(false) / setIsEmailOtp(false)} otpData={otpData} onSuccess={(transfer_money_uid) => onTransfer(transfer_money_uid)}
                onResend={fetchOTP} onEmailOtp={handleEmailOtp} isEmailOtp={isEmailOtp} />
        </div>
    )
}

const styles = {
    root: {
        margin: '24px 0',
        backgroundColor: 'transparent',
        padding: '0px 16px',
        backgroundColor: '#FFF',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },
    title: {
        color: '#2B2D32',
        fontWeight: 'bold',
        fontSize: 18
    },
    headerDiv: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 0'
    },
    body: {
    },
    transferBtn: {
        height: 40,
        fontSize: 16,
        fontWeight: '600',
        borderRadius: 4,
        backgroundColor: '#1DD28B',
        color: '#FFF',
        textAlign: 'right'
    },
    tableHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '24px 0'
    },
    dload: {
        fontSize: 16,
        fontWeight: '600',
        height: 40,
        // borderRadius: 10,
        borderColor: '#1DD28B',
        marginRight: 8,
    },
    bal: {
        color: '#2B2D32',
        fontWeight: 'bold',
        fontSize: 28
    },
    balTitle: {
        color: '#2B2D32',
        fontWeight: '500',
        fontSize: 16
    },
    balDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column'
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 0'
    },
    removeBtn: {
        backgroundColor: '#E24C4C',
        borderRadius: '50%',
        color: '#FFF',
        width: 16,
        height: 16,
        lineHeight: '13px',
        textAlign: 'center',
        margin: '0 8px 0 16px',
        fontWeight: 'bold'
    },
    amountDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

const TransferMoneyBoxEditable = Form.create()(TransferMoneyBox);

export default TransferMoneyBoxEditable;