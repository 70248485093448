import React, { useState, useEffect } from "react";
import { Button, Modal, message, Icon, Checkbox, Divider } from "antd";
import { TextField, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./css/fee-config.css";
import MerchantFeeConfigViewModel from "./fee-config-viewmodel";
import { validateNumberWithDecimal } from "../../../../../utils/validators/validateNumberWithDecimal";

const FeeConfigModal = (props) => {
  const [isTouched, setIsTouched] = useState(false);
  const [openMerchant, setOpenMerchant] = useState(false);
  const [openSettlementMerchant, setOpenSettlementMerchant] = useState(false);
  const [openIsaParent, setOpenIsaParent] = useState(false);
  const [settleToOwn, setSettleToOwn] = useState(true);

  // cashout terminal id, debit terminal id
  const [dynamicFields, setDynamicFields] = useState([
    {
      cash_out: "",
      debit: "",
    },
  ]);

  const [formFieldData, setFormFieldData] = useState({
    merchantName: "",
    merchantID: "",
    emailAddress: "",
    settlementMerchantName: "",
    settlementMerchantID: "",
    settlementEmailAddress: "",
    isaParent: "",
    isaParentEmailAddress: "",
    cashWithdrawalMerchantFee: "",
    cashWithdrawalUbxFee: "",
    cashWithdrawalBankFee: "",
    cashWithdrawalDeviceSupplierFee: "",
    posDebitUbxFee: "",
    posDebitBankFee: "",
    posDebitDeviceSupplierFee: "",
    isaMerchantFee: "",
    isaParentFee: "",
  });

  const {
    addMerchantFeeConfig,
    editMerchantFeeConfig,
    fetchMerchants,
    fetchIsaParent,
    merchantsList,
    settlementMerchantsList,
    isaParentList,
    fetchMerchantDetail,
    merchantDetail,
    success,
    messageDisplay,
    isLoading,
    isLoadingIsaParent,
    isLoadingMerchant,
    isLoadingSettlementMerchant,
  } = MerchantFeeConfigViewModel();

  async function submitFeeConfig() {
    let cashOutIds = [];
    let debitIds = [];

    dynamicFields.map((field, index) => {
      if (field.cash_out != "") {
        cashOutIds.push(field.cash_out);
      }

      if (field.debit != "") {
        debitIds.push(field.debit);
      }
    });

    if (
      formFieldData.merchantID != "" &&
      formFieldData.settlementMerchantID != "" &&
      formFieldData.cashWithdrawalMerchantFee != "" &&
      formFieldData.cashWithdrawalUbxFee != "" &&
      formFieldData.cashWithdrawalBankFee != "" &&
      formFieldData.cashWithdrawalDeviceSupplierFee != "" &&
      formFieldData.posDebitUbxFee != "" &&
      formFieldData.posDebitBankFee != "" &&
      formFieldData.posDebitDeviceSupplierFee != "" &&
      formFieldData.isaMerchantFee != "" &&
      formFieldData.isaParentFee != "" &&
      cashOutIds.length > 0 &&
      debitIds.length > 0
    ) {
      let params = {
        merchant_id: formFieldData.merchantID,
        settle_merchant_id: formFieldData.settlementMerchantID,
        cashout_terminal_ids: cashOutIds,
        debit_terminal_ids: debitIds,
        isa_parent_name: formFieldData.isaParent,
        isa_parent_email: formFieldData.isaParentEmailAddress,
        withdraw_merchant_fee: formFieldData.cashWithdrawalMerchantFee,
        withdraw_ubx_fee: formFieldData.cashWithdrawalUbxFee,
        withdraw_bank_fee: formFieldData.cashWithdrawalBankFee,
        withdraw_supplier_fee: formFieldData.cashWithdrawalDeviceSupplierFee,
        debit_ubx_fee: formFieldData.posDebitUbxFee,
        debit_bank_fee: formFieldData.posDebitBankFee,
        debit_supplier_fee: formFieldData.posDebitDeviceSupplierFee,
        isa_merchant_fee: formFieldData.isaMerchantFee,
        isa_parent_fee: formFieldData.isaParentFee,
      };

      if (props.editData) {
        await editMerchantFeeConfig(params, props.editData.id);
      } else {
        await addMerchantFeeConfig(params);
      }
    } else {
      setIsTouched(true);
    }
  }

  function clearForm() {
    resetDynamicField();

    setFormFieldData({
      merchantName: "",
      merchantID: "",
      emailAddress: "",
      settlementMerchantName: "",
      settlementMerchantID: "",
      settlementEmailAddress: "",
      isaParent: "",
      isaParentEmailAddress: "",
      cashWithdrawalMerchantFee: "",
      cashWithdrawalUbxFee: "",
      cashWithdrawalBankFee: "",
      cashWithdrawalDeviceSupplierFee: "",
      posDebitUbxFee: "",
      posDebitBankFee: "",
      posDebitDeviceSupplierFee: "",
      isaMerchantFee: "",
      isaParentFee: "",
    });
    setIsTouched(false);
    setSettleToOwn(true);
  }

  function updateFields(fields) {
    setFormFieldData({ ...formFieldData, ...fields });
  }

  useEffect(() => {
    // edit merchant fee config
    if (props.editData) {
      setFormFieldData({
        merchantName: props.editData.merchant_name,
        merchantID: props.editData.merchant_id,
        emailAddress: props.editData.merchant_email,
        settlementMerchantName: props.editData.settle_merchant_name,
        settlementMerchantID: props.editData.settle_merchant,
        settlementEmailAddress: props.editData.settle_merchant_email,
        isaParent: props.editData.isa_parent_name,
        isaParentEmailAddress: props.editData.isa_parent_email,
        cashWithdrawalMerchantFee: props.editData.withdraw_merchant_fee,
        cashWithdrawalUbxFee: props.editData.withdraw_ubx_fee,
        cashWithdrawalBankFee: props.editData.withdraw_bank_fee,
        cashWithdrawalDeviceSupplierFee: props.editData.withdraw_supplier_fee,
        posDebitUbxFee: props.editData.debit_ubx_fee,
        posDebitBankFee: props.editData.debit_bank_fee,
        posDebitDeviceSupplierFee: props.editData.debit_supplier_fee,
        isaMerchantFee: props.editData.isa_merchant_fee,
        isaParentFee: props.editData.isa_parent_fee,
      });

      setSettleToOwn(
        props.editData.merchant_id == props.editData.settle_merchant
      );

      let cashOutTerminalIds = props.editData.cashout_terminal_ids;
      let debitTerminalIds = props.editData.debit_terminal_ids;

      let maxIds =
        cashOutTerminalIds.length > debitTerminalIds.length
          ? cashOutTerminalIds.length
          : debitTerminalIds.length;

      resetDynamicField();

      for (var x = 0; x < maxIds; x++) {
        if (x == 0) {
          setDynamicFields([
            {
              cash_out: cashOutTerminalIds[x] ? cashOutTerminalIds[x] : "",
              debit: debitTerminalIds[x] ? debitTerminalIds[x] : "",
            },
          ]);
        } else {
          addDynamicField({
            cash_out: cashOutTerminalIds[x],
            debit: debitTerminalIds[x],
          });
        }
      }
    }
  }, [props.editData]);

  useEffect(() => {
    // when user selected merchant name from the dropdown
    if (merchantDetail) {
      setFormFieldData({
        merchantName: merchantDetail.merchant_name,
        merchantID: merchantDetail.merchant_id,
        emailAddress: merchantDetail.merchant_email,
        settlementMerchantName: settleToOwn
          ? merchantDetail.merchant_name
          : formFieldData.settlementMerchantName,
        settlementMerchantID: settleToOwn
          ? merchantDetail.merchant_id
          : formFieldData.settlementMerchantID,
        settlementEmailAddress: settleToOwn
          ? merchantDetail.merchant_email
          : formFieldData.settlementEmailAddress,
        isaParent: "",
        isaParentEmailAddress: "",
        cashWithdrawalMerchantFee: merchantDetail.withdraw_merchant_fee,
        cashWithdrawalUbxFee: merchantDetail.withdraw_ubx_fee,
        cashWithdrawalBankFee: merchantDetail.withdraw_bank_fee,
        cashWithdrawalDeviceSupplierFee: merchantDetail.withdraw_supplier_fee,
        posDebitUbxFee: merchantDetail.debit_ubx_fee,
        posDebitBankFee: merchantDetail.debit_bank_fee,
        posDebitDeviceSupplierFee: merchantDetail.debit_supplier_fee,
        isaMerchantFee: merchantDetail.isa_merchant_fee,
        isaParentFee: merchantDetail.isa_parent_fee,
      });
    }
  }, [merchantDetail]);

  useEffect(() => {
    displayMessage();
  }, [messageDisplay, success]);

  useEffect(() => {
    if (formFieldData.merchantName == "" && openMerchant) {
      fetchMerchantOptions();
      clearForm();
      return;
    }

    if (merchantsList && openMerchant) {
      const exists = merchantsList.some((merchant) =>
        merchant.name
          .toLowerCase()
          .includes(formFieldData.merchantName.toLowerCase())
      );

      if (!exists) {
        fetchMerchantOptions();
      }
    }
  }, [formFieldData.merchantName]);

  useEffect(() => {
    if (formFieldData.settlementMerchantName == "" && openSettlementMerchant) {
      fetchSettlementMerchantOptions();
      setFormFieldData({
        ...formFieldData,
        settlementMerchantName: "",
        settlementMerchantID: "",
        settlementEmailAddress: "",
      });
      return;
    }

    if (settlementMerchantsList && openSettlementMerchant) {
      const exists = settlementMerchantsList.some((merchant) =>
        merchant.name
          .toLowerCase()
          .includes(formFieldData.settlementMerchantName.toLowerCase())
      );

      if (!exists) {
        fetchSettlementMerchantOptions();
      }
    }
  }, [formFieldData.settlementMerchantName]);

  useEffect(() => {
    if (formFieldData.isaParent == "") {
      fetchISAParentOptions();
      return;
    }

    if (isaParentList && openIsaParent) {
      const exists = isaParentList.some((merchant) =>
        merchant.name
          .toLowerCase()
          .includes(formFieldData.isaParent.toLowerCase())
      );

      if (!exists) {
        fetchISAParentOptions();
      }
    }
  }, [formFieldData.isaParent]);

  async function fetchMerchantOptions() {
    await fetchMerchants(formFieldData.merchantName, "merchant");
  }

  async function fetchSettlementMerchantOptions() {
    await fetchMerchants(
      formFieldData.settlementMerchantName,
      "settlement_merchant"
    );
  }

  async function fetchISAParentOptions() {
    await fetchIsaParent(formFieldData.isaParent, formFieldData.merchantID);
  }

  function displayMessage() {
    setTimeout(() => {
      if (messageDisplay != "" && success == true) {
        message.destroy();
        message.success(messageDisplay);
        clearForm();

        if (props.editData) {
          props.closeModal();
        } else {
          props.submitModal();
        }
      } else if (messageDisplay != "" && success == false) {
        message.destroy();
        message.error(messageDisplay);
      }
    }, 1000);
  }

  function displayTextField(label, onChange, value, disabled) {
    return (
      <Grid item={true}>
        <TextField
          id="outlined-basic"
          label={label}
          disabled={disabled}
          margin="normal"
          variant="outlined"
          fullWidth
          className={isTouched && value == "" ? `error-field` : ``}
          onChange={onChange}
          value={value}
        />
        {isTouched && value == "" && label != "ISA Parent Email Address" && (
          <p className="error-field-message">This is a required field</p>
        )}
      </Grid>
    );
  }

  const addDynamicField = (values) => {
    setDynamicFields((prevFields) => [
      ...prevFields,
      {
        cash_out: values && values.cash_out ? values.cash_out : "",
        debit: values && values.debit ? values.debit : "",
      },
    ]);
  };

  const removeDynamicField = (index) => {
    setDynamicFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields.splice(index, 1);
      return updatedFields;
    });
  };

  const resetDynamicField = () => {
    setDynamicFields([
      {
        cash_out: "",
        debit: "",
      },
    ]);
  };

  return (
    <Modal
      title={props.editData ? "Edit Merchant Configs" : "Add Merchant Configs"}
      centered
      visible={props.openModal}
      onCancel={() => {
        clearForm();
        props.closeModal();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            clearForm();
            props.closeModal();
          }}
        >
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => submitFeeConfig()}>
          {props.editData ? "Update" : "Add"}
        </Button>,
      ]}
    >
      <Grid container>
        <div className="field-row-container">
          <Grid item={true} className="full-width-grid-item">
            {props.editData && (
              <TextField
                id="outlined-basic"
                label={"Merchant Name"}
                disabled={true}
                margin="normal"
                variant="outlined"
                fullWidth
                className={
                  isTouched && formFieldData.merchantName == ""
                    ? `error-field`
                    : ``
                }
                onChange={(e) => updateFields({ merchantName: e.target.value })}
                value={formFieldData.merchantName}
              />
            )}

            {!props.editData && (
              <Autocomplete
                style={{ width: "100%", marginBottom: "8px" }}
                open={openMerchant}
                onOpen={() => {
                  setOpenMerchant(true);
                }}
                onClose={() => {
                  setOpenMerchant(false);
                }}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={merchantsList}
                loading={isLoadingMerchant}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Merchant Name"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingMerchant ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    onChange={(e) =>
                      updateFields({ merchantName: e.target.value })
                    }
                    value={formFieldData.merchantName}
                  />
                )}
                onChange={async (option, value) => {
                  if (value && value.value) {
                    await fetchMerchantDetail(value.value);
                  } else {
                    clearForm();
                  }
                }}
                inputValue={formFieldData.merchantName}
              />
            )}

            {isTouched && formFieldData.merchantName == "" && (
              <p className="error-field-message" style={{ marginTop: "2px" }}>
                This is a required field
              </p>
            )}
          </Grid>
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Merchant ID",
            (e) => updateFields({ merchantID: e.target.value }),
            formFieldData.merchantID,
            true
          )}
          {displayTextField(
            "Merchant Email Address",
            (e) => updateFields({ emailAddress: e.target.value }),
            formFieldData.emailAddress,
            true
          )}
        </div>

        <Divider />

        <div className="field-row-container" style={{ marginBottom: "16px" }}>
          <Checkbox
            checked={settleToOwn}
            onChange={(e) => {
              setFormFieldData({
                ...formFieldData,
                settlementMerchantName: !settleToOwn
                  ? formFieldData.merchantName
                  : "",
                settlementMerchantID: !settleToOwn
                  ? formFieldData.merchantID
                  : "",
                settlementEmailAddress: !settleToOwn
                  ? formFieldData.emailAddress
                  : "",
              });
              setSettleToOwn(!settleToOwn);
            }}
            value="own"
          >
            Same as merchant email address
          </Checkbox>
        </div>

        <div className="field-row-container">
          <Grid item={true} className="full-width-grid-item">
            <Autocomplete
              style={{ width: "100%", marginBottom: "8px" }}
              open={openSettlementMerchant}
              onOpen={() => {
                setOpenSettlementMerchant(true);
              }}
              onClose={() => {
                setOpenSettlementMerchant(false);
              }}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={settlementMerchantsList}
              loading={isLoadingSettlementMerchant}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Settlement Merchant Name"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingSettlementMerchant ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={(e) =>
                    updateFields({ settlementMerchantName: e.target.value })
                  }
                  value={formFieldData.settlementMerchantName}
                />
              )}
              onChange={async (option, value) => {
                setFormFieldData({
                  ...formFieldData,
                  settlementMerchantName:
                    value && value.value ? value.name : "",
                  settlementMerchantID: value && value.value ? value.value : "",
                  settlementEmailAddress:
                    value && value.value ? value.other : "",
                });
              }}
              inputValue={formFieldData.settlementMerchantName}
              value={formFieldData.settlementMerchantName}
              disabled={formFieldData.merchantID == "" || settleToOwn}
            />

            {isTouched && formFieldData.settlementMerchantName == "" && (
              <p className="error-field-message" style={{ marginTop: "2px" }}>
                This is a required field
              </p>
            )}
          </Grid>
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Settlement Merchant ID",
            (e) => updateFields({ settlementMerchantID: e.target.value }),
            formFieldData.settlementMerchantID,
            true
          )}
          {displayTextField(
            "Settlement Merchant Email Address",
            (e) => updateFields({ settlementEmailAddress: e.target.value }),
            formFieldData.settlementEmailAddress,
            true
          )}
        </div>

        <Divider style={{ marginBottom: "16px" }} />

        {dynamicFields.map((field, index) => (
          <div className="field-row-container" style={{ alignItems: "center" }}>
            {displayTextField(
              "CashOut Terminal ID",
              (e) => {
                const updatedFields = [...dynamicFields];
                updatedFields[index].cash_out = e.target.value;
                setDynamicFields(updatedFields);
              },
              field.cash_out,
              formFieldData.merchantID == ""
            )}
            {displayTextField(
              "Debit Terminal ID",
              (e) => {
                const updatedFields = [...dynamicFields];
                updatedFields[index].debit = e.target.value;
                setDynamicFields(updatedFields);
              },
              field.debit,
              formFieldData.merchantID == ""
            )}

            {index + 1 == dynamicFields.length && (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  addDynamicField();
                }}
                style={{ borderRadius: "12px" }}
              >
                <Icon type="plus" />
              </Button>
            )}

            <Button
              size="small"
              type="primary"
              onClick={() => {
                removeDynamicField(index);
              }}
              style={{ borderRadius: "12px" }}
              disabled={dynamicFields.length == 1}
            >
              <Icon type="minus" />
            </Button>
          </div>
        ))}

        <div className="field-row-container">
          <Grid item={true} className="full-width-grid-item">
            <Autocomplete
              style={{
                width: "100%",
                marginBottom: "8px",
                marginTop: "16px",
              }}
              open={openIsaParent}
              onOpen={() => {
                setOpenIsaParent(true);
              }}
              onClose={() => {
                setOpenIsaParent(false);
              }}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={isaParentList}
              loading={isLoadingIsaParent}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="ISA Parent"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingIsaParent ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={(e) => updateFields({ isaParent: e.target.value })}
                  value={formFieldData.isaParent}
                />
              )}
              onChange={async (option, value) => {
                if (value && value.value) {
                  setFormFieldData({
                    ...formFieldData,
                    isaParent: value.name,
                    isaParentEmailAddress: value.other,
                  });
                } else {
                  setFormFieldData({
                    ...formFieldData,
                    isaParent: "",
                    isaParentEmailAddress: "",
                  });
                }
              }}
              inputValue={formFieldData.isaParent}
              value={formFieldData.isaParent}
              disabled={formFieldData.merchantID == ""}
            />
          </Grid>
        </div>

        <div className="field-row-container">
          {displayTextField(
            "ISA Parent Email Address",
            (e) => updateFields({ isaParentEmailAddress: e.target.value }),
            formFieldData.isaParentEmailAddress,
            true
          )}
          {displayTextField(
            "Cash Withdrawal Merchant Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ cashWithdrawalMerchantFee: e.target.value });
              }
            },
            formFieldData.cashWithdrawalMerchantFee,
            formFieldData.merchantID == ""
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Cash Withdrawal UBX Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ cashWithdrawalUbxFee: e.target.value });
              }
            },
            formFieldData.cashWithdrawalUbxFee,
            formFieldData.merchantID == ""
          )}
          {displayTextField(
            "Cash Withdrawal Bank Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ cashWithdrawalBankFee: e.target.value });
              }
            },
            formFieldData.cashWithdrawalBankFee,
            formFieldData.merchantID == ""
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Cash Withdrawal Device Supplier Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({
                  cashWithdrawalDeviceSupplierFee: e.target.value,
                });
              }
            },
            formFieldData.cashWithdrawalDeviceSupplierFee,
            formFieldData.merchantID == ""
          )}
          {displayTextField(
            "POS Debit UBX Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ posDebitUbxFee: e.target.value });
              }
            },
            formFieldData.posDebitUbxFee,
            formFieldData.merchantID == ""
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "POS Debit Bank Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ posDebitBankFee: e.target.value });
              }
            },
            formFieldData.posDebitBankFee,
            formFieldData.merchantID == ""
          )}
          {displayTextField(
            "POS Debit Device Supplier Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ posDebitDeviceSupplierFee: e.target.value });
              }
            },
            formFieldData.posDebitDeviceSupplierFee,
            formFieldData.merchantID == ""
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "ISA Merchant Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ isaMerchantFee: e.target.value });
              }
            },
            formFieldData.isaMerchantFee,
            formFieldData.merchantID == ""
          )}
          {displayTextField(
            "ISA Parent Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ isaParentFee: e.target.value });
              }
            },
            formFieldData.isaParentFee,
            formFieldData.merchantID == ""
          )}
        </div>
      </Grid>
    </Modal>
  );
};

export default FeeConfigModal;
