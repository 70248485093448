import React from 'react';

const QRBusinessName = props => {
    const {
        data, businessName, profile, personalMessage
    } = props;

    return (
        <div>
            <div className="flex-center top-24 padding-bot-24 bottom-line">
                <div className="right-8">
                    <img src={data && data.profile_picture ? data.profile_picture : profile}
                        className={data && 'qr-profile-pic'}
                        alt="avatar" />
                </div>
                <div className="wc-header qr-business-name">
                    {businessName}
                </div>
            </div>

            <div className="top-16 modal-sub-text">
                {personalMessage}
            </div>
        </div>
    
    )
}

export default QRBusinessName;