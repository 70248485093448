import React from 'react';
import { Button, Icon, Modal, Table, Typography } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { segmentStroke } from '../../../../constants/constants';

const rowBG = {
  render(text, record) {
    return {
      props: {
        style: { background: record.findings !== null ? '#f9dbdb' : 'transparent' },
      },
      children: <div>{text}</div>,
    };
  },
}


const TablePreviewModal = (props) => {
  const { segments, visible, onClose, data, onConfirm, isLoading } = props;
  const [selected, setSelected] = React.useState('Success');
  const [rowCount, setRowCount] = React.useState(0);
  const [tableData, setTableData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      render: (text, record) => (
        text ? text : '--'
      )
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      render: (text, record) => (
        text ? text : '--'
      )
    },
    {
      title: `Email`,
      dataIndex: `email`,
      render: (text, record)=> (
        text ? text : '--'
      )
    },
    {
      title: `Mobile Number`,
      dataIndex: `contact`,
      render: (text, record) => (
        text ? text : '--'
      )
    },
    
    selected === 'Success' ?
      {} :
      {
        title: `Remarks`,
        dataIndex: 'remarks',
        hidden: (text) => !text.remarks ? true : false,
        render: (text, record) => (
          text ? text : '--'
        )
      },
  ]

  const ButtonTab = (props) => {
    const status = ['Success', 'Error/s'];
    const { rootStyle } = props;

    const handleClick = (event) => {
      setSelected(event.target.value);
    }

    return (
      <div style={rootStyle}>
        <ButtonGroup style={{ padding: '20px 0' }}>
          {
            status.map((btn, i) => {
              return (
                <Button
                  key={btn}
                  value={btn}
                  onClick={handleClick}
                  style={{
                    ...styles.btnGroup,
                    color: btn === selected ? '#FFF' : '#2B2D33',
                    backgroundColor: btn === selected ? segmentStroke[0] : '#FFF',
                    borderRadius: i === 0 ? '4px 0 0 4px' : i === 1 ? '0 4px 4px 0' : 0
                  }}
                >
                  {btn + ` (${btn === 'Success' ? returnSuccessData().length : errorData().length})`}
                </Button>
              )
            })
          }
        </ButtonGroup>
      </div>
    )
  }


  // const flatData = data && Object.keys(data).map((key, i) => {
  //   return ({
  //     first_name: data[i]['data']['First Name'],
  //     last_name: data[i]['data']['Last Name'],
  //     email: data[i]['data']['Email'],
  //     contact: data[i]['data']['Mobile Number'],
  //   })
  // });

  const errorData = () => {
    let arr = new Array;
    data && data.map((key, i) => {
      if (key.remarks !== '') {
        arr.push(key);
      }
    });
    return arr;
  }

  const returnSuccessData = () => {
    let arr = new Array;
    data && data.map((key, i) => {
      if (key.remarks === '') {
        arr.push(key);
      }
    });
    return arr;
  }

  // const validateRows = () => {
  //   setLoading(true);
  //   let arr = new Array;
  //   const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //   flatData && flatData.map((key, i) => {
  //     if (Object.values(key).some(x => (x === null || x === '' || x ===  undefined))) {
  //       key['remarks'] = 'Field/s is missing or empty';
  //       arr.push(key);
  //     }
  //     else if (!key.contact.match(/^\d{10}$/g)) {
  //       key['remarks'] = 'Invalid contact number';
  //       arr.push(key);
  //     }
  //     else if (!key.email.match(validRegex)) {
  //       key['remarks'] = 'Invalid email address';
  //       arr.push(key);
  //     } else {
  //       key['remarks'] = '';
  //       arr.push(key);
  //     }
  //     setLoading(false);
  //   });
  //   return arr;
  // }

  const closeModal = () => {
    onClose();
    setSelected('Success');
    setTableData(null);
  }

  const allocateData = () => {
    // setTableData(validateRows());
  }

  React.useEffect(() => {
    // allocateData();
  }, [selected, data])

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={closeModal}
      destroyOnClose
      width={964}
      centered
      bodyStyle={styles.root}
      loading={loading || isLoading}>
      <Typography style={styles.title}>Uploaded sub-wallet list</Typography>
      <ButtonTab />
      <div style={styles.note}>
        <Icon type='exclamation-circle' style={{ color: '#F4B24A', paddingRight: 8, marginBottom: '-2px' }} />
        <Typography>Note: Entry with error won’t be added to your sub-wallet list.</Typography>
      </div>
      <Table
        loading={loading || isLoading}
        rowClassName="table-row-light"
        columns={columns}
        pagination={{ pageSize: 10, size: 'small' }}
        style={{ width: '100%' }}
        dataSource={selected === 'Success' ? returnSuccessData() : errorData()}
      />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }} className="top-16">
        <button disabled={!returnSuccessData().length} className={`btn--${segments} btn-height`} loading={loading || isLoading} onClick={() => { onConfirm(returnSuccessData()); closeModal(); }} >Confirm</button>
      </div>
    </Modal>
  )
}


const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 964
  },
  title: {
    fontSize: 24,
    fontWeight: '300',
    color: '#2B2D33',
    padding: '0 0 0 0'
  },
  btnGroup: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '0 32px'
  },
  confirmBtn: {
    height: 40,
    fontSize: 16,
    fontWeight: '600',
    borderRadius: 4,
    backgroundColor: '#F5922F',
    color: '#FFF',
  },
  note: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
    border: '1px solid #F4B24A',
    width: '100%',
    backgroundColor: 'rgba(244, 193, 10, 0.16)',
    padding: 8,
    marginBottom: 12
  }
}

export default TablePreviewModal;