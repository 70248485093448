import React from 'react';
import moment from 'moment';
import { Table, Pagination, Dropdown, Icon, Menu, Typography } from 'antd';

const MultiwalletTable = (props) => {
    const { data, isLoading, onChangePage, pageSize, selected } = props;
    const [loading, setLoading] = React.useState(false);
    const isAdded = selected === 'Receive Money';

    const returnKycLevel = (level) => {
        switch (level) {
            case 0:
                return 'Basic';
            case 1:
                return 'Advanced';
            case 2:
                return 'Pro';
            case 3:
                return 'Business';
            default:
                return 'Basic';
        }
    }

    const returnStatus = (status) => {
        if (status === 'SU') {
            return 'Success'
        } else {
            return 'Failed'
        }
    }

    const columns = [
        {
            title: 'Transaction ID',
            dataIndex: 'transfer_id',
        },
        {
            title: 'Sender',
            dataIndex: 'sender',
            render: (record) =>
                record.email && record.name?
                <div>
                    <Typography style={styles.receiverName}>{record.name}</Typography>
                    <Typography style={styles.receiverEmail}>{record.email}</Typography>
                </div>
                :
                <Typography>N/A</Typography>
        },
        {
            title: 'Receiver',
            dataIndex: 'receiver',
            render: (record) =>
                record.email && record.name?
                <div>
                    <Typography style={styles.receiverName}>{record.name}</Typography>
                    <Typography style={styles.receiverEmail}>{record.email}</Typography>
                </div>
                :
                <Typography>N/A</Typography>
        },
        {
            title: 'Date',
            dataIndex: 'timestamp',
            render: (record) => moment(record).format('MMM D, YYYY h:mm A')
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (record) => <Typography style={{ ...styles.amount, color: '#C0492C' }}>{("-₱") + record.toLocaleString("en-US", {minimumFractionDigits: 2})}</Typography>
        },
        {
            title: 'Remaining Balance',
            dataIndex: 'balance',
            render: (record) => <Typography style={{ ...styles.amount, color: '#2B2D32' }}>{"₱" + record.toLocaleString("en-US", {minimumFractionDigits: 2})}</Typography>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (record) => <div style={record === 'SU' ? styles.positive : styles.negative} >{returnStatus(record)}</div>
        },
        // {
        //     title: 'Action',
        //     render: () => (
        //         <Dropdown overlay={() => menu()} placement="bottomCenter" trigger={['click']}>
        //             <Icon type="ellipsis" />
        //         </Dropdown>
        //     )
        // },
    ]

    const menu = (id, file, code, output_file) => (
        <Menu>
            <Menu.Item key="0">
                <a >User History</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a >KYC Information</a>
            </Menu.Item>
            <Menu.Item key="2">
                <a >Transfer Money</a>
            </Menu.Item>
        </Menu>
    )

    return (
        <div>
            <Table
                rowClassName="table-row-light"
                columns={columns}
                dataSource={data && data['results'] ? data['results'] : []}
                pagination={{ pageSize: 20 }}
                loading={isLoading || loading}
                pagination={false}
            />
            <Pagination
                size='small'
                total={data && data['total']}
                defaultPageSize={pageSize}
                defaultCurrent={1}
                current={data && data['page']}
                onChange={(page, pageSize) => onChangePage(page, pageSize)}
                style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
            />
            {/* <ActionModal visible={showActionModal} closeModal={() => setShowActionModal(false)} onClick={(status) => handleAction(status)} type={actionType} /> */}
        </div>
    )
}

const styles = {
    positive: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: 'rgb(232, 250, 243)',
        border: '1px solid #1DD28B',
        borderLeft: '5px solid #1DD28B',
    },
    negative: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: '#f9dbdb',
        border: '1px solid #E24C4C',
        borderLeft: '5px solid #E24C4C',
    },
    receiverName: {
        color: '#2B2D32',
        fontSize: 14,
        fontWeight: '600',
        textAlign: 'left',
    },
    receiverEmail: {
        color: 'rgba(43, 45, 50, 0.64)',
        fontSize: 12,
        textAlign: 'left',
    },
    amount: {
        fontSize: 14,
        fontWeight: 'bold'
    }
}

export default MultiwalletTable;