import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MetaTag from "../../../../../../components/meta_tag/meta_tag";
import { Typography, Card, Button, Icon } from "antd";
import { AdminsList } from "./admins-list";
import { SearchInput, FilterBar, GroupButton } from "../../../components";
import { useAdmins } from "../api/get-admins";

export function Admins() {
  const history = useHistory();
  const {
    data,
    isLoading,
    error,
    railTypes,
    currentPage,
    searchQuery,
    selectedRailType,
    handleRailTypeChange,
    handleSearchQueryChange,
    handleCurrentPageChange,
    handleClearFilters,
  } = useAdmins();

  const [tempSearchQuery, setTempSearchQuery] = useState("");

  const handleApplySearch = () => {
    handleSearchQueryChange(tempSearchQuery);
  };

  const clearFilters = () => {
    handleClearFilters();
    setTempSearchQuery("");
  };

  return (
    <div
      style={{
        margin: "0 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
      }}
    >
      <MetaTag title="CashBux Users" />
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          CashBux Merchants
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GroupButton
            options={railTypes}
            selectedOption={selectedRailType}
            onSelect={handleRailTypeChange}
          />

          <Button
            size="large"
            type="primary"
            onClick={() => history.push("/cashbux/admins/create")}
          >
            <Icon type="plus" />
            Add User
          </Button>
        </div>
        <Card>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <FilterBar
              initialValue={railTypes[0]}
              selectedOption={selectedRailType}
              searchQuery={searchQuery}
              clearFilters={clearFilters}
            />
            <SearchInput
              tempSearchQuery={tempSearchQuery}
              setTempSearchQuery={setTempSearchQuery}
              handleApplySearch={handleApplySearch}
              placeholder="Search by Name, Email."
            />
            <AdminsList
              data={data}
              isLoading={isLoading}
              error={error}
              currentPage={currentPage}
              setCurrentPage={handleCurrentPageChange}
            />
          </div>
        </Card>
      </div>
    </div>
  );
}
