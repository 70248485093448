import React from 'react';
import { Button, Icon, Modal, Table, Typography } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { useSelector } from 'react-redux';
import { randomize, segmentStroke } from '../../../constants/constants';

const rowBG = {
  render(text, record) {
    return {
      props: {
        style: { background: record.findings !== null ? '#f9dbdb' : 'transparent' },
      },
      children: <div>{text}</div>,
    };
  },
}


const TablePreviewModal = (props) => {
  const { visible, closeModal, data, addTableData, isPreviewModalTableLoading, tableData } = props;
  const [selected, setSelected] = React.useState('Success');
  const [rowCount, setRowCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      render: (text, record) => (
        text ? text : '--'
      )
    },
    {
      title: `Email`,
      dataIndex: `email`,
      render: (text, record) => (
        text ? text : '--'
      )
    },
    {
      title: `Mobile Number`,
      dataIndex: `contact`,
      render: (text, record) => (
        text ? text : '--'
      )
    },
    {
      title: `Amount`,
      dataIndex: `amount`,
      render: (text, record) => (
        text ? <span>₱{parseFloat(text).toLocaleString("en-US", {minimumFractionDigits: 2})}</span> : '--'
      )
    },
    selected === 'Success' ?
      {} :
      {
        title: `Remarks`,
        dataIndex: 'remarks',
        render: (text, record) => (
          text ? text : '--'
        )
      },
  ]

  const ButtonTab = (props) => {
    const status = ['Success', 'Error/s'];
    const { rootStyle } = props;

    const handleClick = (event) => {
      setSelected(event.target.value);
    }

    return (
      <div style={rootStyle}>
        <ButtonGroup style={{ padding: '20px 0' }}>
          {
            status.map((btn, i) => {
              return (
                <Button
                  key={btn}
                  value={btn}
                  onClick={handleClick}
                  style={{
                    ...styles.btnGroup,
                    color: btn === selected ? '#FFF' : '#2B2D33',
                    backgroundColor: btn === selected ? segmentStroke[0] : '#FFF',
                    borderRadius: i === 0 ? '4px 0 0 4px' : i === 1 ? '0 4px 4px 0' : 0
                  }}
                >
                  {btn + ` (${btn === 'Success' ? returnSuccessData().length : errorData().length})`}
                </Button>
              )
            })
          }
        </ButtonGroup>
      </div>
    )
  }

  // const flatData = data && Object.keys(data).map((key,i) => {
  //   data[i]['key'] = randomize();
  //   return data[i];
  // });

  // const flatData = data && Object.keys(data).map((key, i) => {
  //   return ({
  //     full_name: data[i]['data']['Full Name'],
  //     email: data[i]['data']['Email'],
  //     contact: data[i]['data']['Mobile Number'],
  //     amount: parseFloat(data[i]['data']['Amount'])
  //   })
  // });

  const errorData = () => {
    let arr = new Array;
    tableData && tableData.map((key, i) => {
      if (key.remarks !== '') {
        arr.push(key);
      }
    });
    return arr;
  }

  const returnSuccessData = () => {
    let arr = new Array;
    tableData && tableData.map((key, i) => {
      if (key.remarks === '') {
        arr.push(key);
      }
    });
    return arr;
  }

  const onClose = () => {
    closeModal();
    setSelected('Success');
    // setTableData(null);
  }

  const allocateData = () => {
    // setTableData(validateRows());
  }

  React.useEffect(() => {
    // allocateData();
  }, [selected, data])

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onClose}
      destroyOnClose
      width={964}
      centered
      bodyStyle={styles.root}
      loading={loading}>
      <Typography style={styles.title}>Uploaded sub-wallet list</Typography>
      <ButtonTab />
      <div style={styles.note}>
        <Icon type='exclamation-circle' style={{ color: '#F4B24A', paddingRight: 8, marginBottom: '-2px' }} />
        <Typography>Note: Entry with error/s won’t be added to your transfer money box.</Typography>
      </div>
      <Table
        loading={loading}
        rowClassName="table-row-light"
        columns={columns}
        pagination={{ pageSize: 10, size: 'small' }}
        style={{ width: '100%' }}
        dataSource={selected === 'Success' ? returnSuccessData() : errorData()}
      />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }} className="top-16">
        <button disabled={!returnSuccessData().length} 
          className={`btn--${props.segments} btn-height`}
          onClick={() => addTableData(returnSuccessData()) / onClose()} >
           Confirm
        </button>
      </div>
    </Modal>
  )
}


const styles = {
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 964
  },
  title: {
    fontSize: 24,
    fontWeight: '300',
    color: '#2B2D33',
    padding: '0 0 0 0'
  },
  btnGroup: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    height: 40,
    padding: '0 32px'
  },
  confirmBtn: {
    height: 40,
    fontSize: 16,
    fontWeight: '600',
    borderRadius: 4,
    backgroundColor: '#F5922F',
    color: '#FFF',
  },
  note: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
    border: '1px solid #F4B24A',
    width: '100%',
    backgroundColor: 'rgba(244, 193, 10, 0.16)',
    padding: 8,
    marginBottom: 12
  }
}

export default TablePreviewModal;