import React from 'react'
import * as Icon from '../../static/icons/payment_method_icons/index'
import DA5 from '../../static/icons/da5.svg'
import BTI from '../../static/icons/bti.png'

const supportInstruction = 
    <div>
        If you do not receive successful payment notification, please contact our support team at <a href="mailto:support@bux.ph" style={{color: '#2B2D33', fontWeight: 'bold'}}>support@bux.ph</a>.
    </div>

const upaysupportInstruction = 
    <div>
        If you do not receive successful payment notification by noon next day, please contact our support team at <a href="mailto:support@bux.ph" style={{color: '#2B2D33', fontWeight: 'bold'}}>support@bux.ph</a>.
    </div>

const dragonpayInstruction = [
    <div>Write <strong>Dragonpay</strong> as your biller name.</div>,
    <div>
        Provide <strong>reference number</strong> and make sure it is correctly entered in the account number field. 
        A wrong account number can cause your transaction to remain unvalidated.
    </div>,
    <div>Provide your <strong>full name</strong> and make sure your <strong>total amount</strong> is correct and the same on this receipt. </div>,
    <div>Please provide a valid <strong>contact number</strong> where we can reach you in case there are problems with the validation. </div>,
    'Prepare a valid ID.',
    supportInstruction
]

const dpDisclaimer = 
        <div style={{color: '#2B2D32'}}>
            <div> By using Dragonpay, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} target="_blank" href="https://www.dragonpay.ph/terms-and-conditions">Terms and Conditions</a></div>
            <div> Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:help@dragonpay.ph">help@dragonpay.ph</a> or via <b>+632-8655-6820.</b></div>
        </div> 


const ecpayInstruction = [
    <div>Write <strong>Bux</strong> as your biller name.</div>,
    <div>
        Provide <strong>reference number</strong> and make sure it is correctly entered in the account number field. 
        A wrong account number can cause your transaction to remain unvalidated.
    </div>,
    <div>Provide your <strong>full name</strong> and make sure your <strong>total amount</strong> is correct and the same on this receipt. </div>,
    <div>Please provide a valid <strong>contact number</strong> where we can reach you in case there are problems with the validation. </div>,
    'Prepare a valid ID.',
    supportInstruction
]

const bayadCenterInstruction = [
    <div>Write <strong>UBXPH</strong> as your biller name.</div>,
    <div>
        Provide <strong>reference number</strong> and make sure it is correctly entered in the account number field. 
        A wrong account number can cause your transaction to remain unvalidated.
    </div>,
    <div>Provide your <strong>full name</strong> and make sure your <strong>total amount</strong> is correct and the same on this receipt. </div>,
    <div>Please provide a valid <strong>contact number</strong> where we can reach you in case there are problems with the validation. </div>,
    'Prepare a valid ID.',
    supportInstruction
]

const bankInstruction = [
    'You will receive a One-Time Password (OTP) to your registered mobile number for verification of your account.',
    'Payment link validity is only 1 hour.',
    supportInstruction
]

const walletInstruction = [
    'You will receive a One-Time Password (OTP) to your registered mobile number for verification of your account.',
    'We will send a confirmation email to you once processed.',
    supportInstruction,
]

const mayaInstruction = [
    'We will send a confirmation email to you once processed.',
    supportInstruction,
]

const upayInstruction = [
    'We will send a confirmation email to you once processed.',
    upaysupportInstruction,
]

const instapayAllBankInstruction = [
    'We will send a confirmation email to you once processed.',
    supportInstruction,
]

const metrobankInstruction = [
    <div>Go to <b>Pay Bills</b>, select or type <b>Dragonpay Corporation</b> from the biller list.</div>,
    <div>For <b>Subscriber / Account No.</b>, enter reference no.</div>,
    'We will send a confirmation email to you once processed',
    supportInstruction

]

export const channels = {
    
    '711_direct':
    {
        map: 'https://www.7-eleven.com.ph/store-locator/',
        name: '7-Eleven',
        icon: Icon.SevenEleven,
        instruction: 
            [
                'Go to nearest 7-Eleven branch.',
                'Present the barcode or reference number.',
                'Pay the specified amount of ',
                'The Cashier will process your payment in real-time.',
                'Successful payment notification will be sent to you via email.',
                'Your merchant will contact you regarding your transaction.',
                supportInstruction,
            ],
        disclaimer: 
            <div style={{color: '#2B2D32'}}>
                <div>By using 7 Connect, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} target="_blank" href="https://www.cliqq.net/terms/">Terms and Conditions</a></div>
            </div>
    },
    'BAYD':
    {
        map: null,
        name: 'Bayad Center',
        icon: Icon.BayadCenter,
        instruction: dragonpayInstruction,
        disclaimer: dpDisclaimer
    },
    'LBC':
    {
        map: null,
        name: 'LBC',
        icon: Icon.Lbc,
        instruction: dragonpayInstruction,
        disclaimer: dpDisclaimer
    },
    'MLH':
    {
        map: null,
        name: 'MLhuillier',
        icon: Icon.ML,
        instruction: dragonpayInstruction,
        disclaimer: dpDisclaimer
    },
    'SMR':
    {
        map: null,
        name: 'SM Dept / Supermarket / Savemore Counter',
        icon: Icon.Sm,
        instruction: dragonpayInstruction,
        disclaimer: dpDisclaimer
    },
    'CEBL':
    {
        map: null,
        name: 'Cebuana Lhuillier',
        icon: Icon.Cebuana,
        instruction: dragonpayInstruction,
        disclaimer: dpDisclaimer
    },
    'RDS':
    {
        map: null,
        name: 'Robinsons Dept Store',
        icon: Icon.Robinson,
        instruction: dragonpayInstruction,
        disclaimer: dpDisclaimer
    },
    'PLWN':
    {
        map: null,
        name: 'Palawan Pawnshop',
        icon: Icon.Palawan,
        instruction: dragonpayInstruction,
        disclaimer: dpDisclaimer
    },
    'RDP':
    {
        map: null,
        name: 'RD Pawnshop',
        icon: Icon.RD,
        instruction: dragonpayInstruction,
        disclaimer: dpDisclaimer
    },
    'ECPY':
    {
        map: null,
        name: 'ECPay',
        icon: Icon.Ecpay,
        instruction: dragonpayInstruction,
        disclaimer: dpDisclaimer
    },
    'ecpay':
    {
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'ECPay',
        icon: Icon.Ecpay,
        instruction: ecpayInstruction
    },
    'ecpay_rdp':
    {
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'RD Pawnshop',
        icon: Icon.RD,
        instruction: ecpayInstruction
    },
    'payngo':
    {
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'Pay&Go',
        icon: BTI,
        instruction: [
            <div>
                Go to <b>{`Pay&Go`}</b> Kiosk and select <b>{`Bills Payments > Service Collection > Bux`}</b>.
            </div>,
            <div>
               and <b>mobile number</b> (A <b>PHP 10.00</b> surcharge fee will be applied on top of the amount).  
            </div>,
            <div>
                Insert cash and press <b>Done</b> once all bills are inserted. 
                Please note that the kiosk does not dispense change. Any excess amount will go to <b>MySukli</b>.
            </div>,
            'Get the receipt aand wait for an SMS confirmation.',
            supportInstruction,
        ],
        disclaimer: 
            <div style={{color: '#2B2D32'}}>
                <div>By using Pay&Go you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} target="_blank" href="https://bux.ph/bti_terms/">Terms and Conditions</a></div>
                <div>Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:custcare@btipayments.ph">custcare@btipayments.ph</a></div>
            </div>
    },
    'da5':
    {
        map: null,
        name: 'DA5',
        icon: DA5,
        instruction: ecpayInstruction,
        disclaimer: 
            <div style={{color: '#2B2D32'}}>
                <div>By using DA5 you agree to our <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} target="_blank" href="https://bux.ph/da5_terms/">Terms and Conditions</a></div>
                <div>Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:BSD@da5.com.ph">BSD@da5.com.ph</a></div>
            </div>
    },
    'posible':
    {
        map: null,
        name: 'Posible',
        icon: Icon.Posible,
        instruction: [
            <div>Go to nearest <b>Posible branches</b></div>,
            <div>
                (If branch is unable to find BUx biller name, Please advise branch to look <b>Bux</b> under <b>Bills Pay - Others</b>).
            </div>,
            <div>
                Pay the specified <b>Total amount</b> same on this receipt (A <b>PHP 12.00</b> surcharge fee will be applied on top of the amount).
            </div>,
            'The Cashier will process your payment in real-time',
            supportInstruction
        ]
    },
    'RLNT':
    {
        map: null,
        name: 'RuralNet',
        icon: Icon.Rural,
        instruction: dragonpayInstruction
    },
    'CVM':
    {
        map: null,
        name: 'CVM Pawnshop',
        icon: Icon.CVM,
        instruction: dragonpayInstruction,
        disclaimer: dpDisclaimer
    },
    'USSC':
    {
        map: null,
        name: 'USSC',
        icon: Icon.USSC,
        instruction: dragonpayInstruction,
        disclaimer: dpDisclaimer
    },
    'PRHB':
    {
        map: null,
        name: 'Pera Hub',
        icon: Icon.PRHB,
        instruction: dragonpayInstruction,
        disclaimer: dpDisclaimer
        
    },
    'ussc':{
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'USSC',
        icon: Icon.USSC,
        instruction: bayadCenterInstruction
    },
    'rustan':{
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'Rustan Supercenters, Inc',
        icon: Icon.Rustan,
        instruction: ecpayInstruction
    },
    'shopwise':{
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'Shopwise',
        icon: Icon.Shopwise,
        instruction: ecpayInstruction

    },
    'wellcome':{
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'Wellcome',
        icon: Icon.Wellcome,
        instruction: ecpayInstruction
    },
    'tmbntng':{
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'Tambunting',
        icon: Icon.Tambunting,
        instruction: bayadCenterInstruction
    },
    'cvm':{
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'CVM Pawnshop',
        icon: Icon.CVM,
        instruction: ecpayInstruction
    },
    'ecpay_rdp':
    {
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'RD Pawnshop',
        icon: Icon.RD,
        instruction: ecpayInstruction
    },
    'ecpay_rlnt':
    {
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'RuralNet',
        icon: Icon.Rural,
        instruction: ecpayInstruction
    },
    'ecpay_711':
    {
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: '7-Eleven',
        icon: Icon.SevenEleven,
        instruction: [
            <div>Go to nearest <b>7-Eleven</b> store and use <b>Cliqq kiosk</b>.</div>,
            <div>Click <b>Bills Payment</b> and choose <b>eCommerce</b></div>,
            <div>From <b>eCommerce</b> page choose </div>,
            'The Cashier will process your payment in real-time.',
            <div>Input your Mobile <b>Number</b>.</div>,
            'Review your bills payment details and print the payment slip and present it to the cashier for payment.',
            supportInstruction,
        ],
        disclaimer: 
            <div style={{color: '#2B2D32'}}>
                <div>By using 7 Connect, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} target="_blank" href="https://www.cliqq.net/terms/">Terms and Conditions</a></div>
            </div>
    },
    'MBTC':
    {
        map: null,
        name: 'Metrobank',
        icon: Icon.Metrobank,
        instruction: metrobankInstruction,
        disclaimer: dpDisclaimer
    },
    'BDO':
    {
        map: null,
        name: 'BDO',
        icon: Icon.Bdo,
        instruction: bankInstruction,
        disclaimer: dpDisclaimer
    },
    'BPIA':
    { 
        map: null,
        name: 'BPI',
        icon: Icon.Bpi,
        instruction: bankInstruction,
        disclaimer: dpDisclaimer
    },
    'PNB':
    {
        map: null,
        name: 'PNB',
        icon: Icon.Pnb
    },
    'PNB':
    {
        map: null,
        name: 'PNB',
        icon: Icon.Pnb
    },
    'CBC':
    {
        map: null,
        name: 'Chinabank Online',
        icon: Icon.Cbc
    },
    'LBPA':
    {
        map: null,
        name: 'Landbank ATM Online',
        icon: Icon.Landbank,
    },
    'MAYB':
    {
        map: null,
        name: 'Maybank Online Banking',
        icon: Icon.Maybank
    },
    'RCBC':
    {
        map: null,
        name: 'RCBC Online Banking',
        icon: Icon.Rcbc,
        instruction: bankInstruction,
        disclaimer: dpDisclaimer
    },
    'RSB':
    {
        map: null,
        name: 'Robinsons Online Banking',
        icon: Icon.Rbank,
    },
    'UBPB':
    {
        map: null,
        name: 'UnionBank Internet Banking',
        icon: Icon.UB,
        instruction: bankInstruction,
        disclaimer: dpDisclaimer
    },
    'UCPB':
    {
        map: null,
        name: 'UCPB Connect',
        icon: Icon.Ucpb,
    },
    'gcash':
    {
        map: null,
        name: 'GCash',
        icon: Icon.Gcash,
        instruction: walletInstruction,
        disclaimer: 
            <div style={{marginLeft: 3}}>
                <div>By using GCash, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://www.gcash.com/terms-and-conditions/" target="_blank">Terms and Conditions</a></div>
                <div>Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:help@gcash.com">help@gcash.com</a></div>
            </div>

    },
    'grabpay':
    {
        map: null,
        name: 'GrabPay',
        icon: Icon.Grabpay,
        instruction: walletInstruction,
        disclaimer: 
            <div style={{marginLeft: 3}}>
                <div>By using GrabPay, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://www.grab.com/ph/terms-policies/payment-rewards/" target="_blank">Terms and Conditions</a></div>
                <div>Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:support@grab.com">support@grab.com</a></div>
            </div>
    },
    'maya':
    {
        map: null,
        name: 'Maya',
        icon: Icon.Maya,
        instruction: mayaInstruction,
        disclaimer: 
            <div style={{marginLeft: 3}}>
                <div>By using Maya, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://www.maya.ph/terms-and-conditions" target="_blank">Terms and Conditions</a></div>
                <div>Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:support@maya.ph">support@maya.ph</a></div>
            </div>
    },
    'visamc':
    {
        map: null,
        name: 'Xendit',
        icon: Icon.Xendit,
        instruction: walletInstruction,
        disclaimer: 
            <div style={{marginLeft: 3}}>
                <div>By using Xendit, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://www.xendit.co/en/terms-and-conditions/" target="_blank">Terms and Conditions</a></div>
            </div>

    },

    'ubpcs': {
        map: null,
        name: 'Credit/Debit Card',
        icon: Icon.VISAMastercardLogo,
        instruction: walletInstruction,
        disclaimer: 
        <div style={{marginLeft: 3}}>
            <div>By using UnionBank you agree to our <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://bux.ph/terms" target="_blank">Terms and Conditions</a></div>
            <div>Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:support@bux.ph">support@bux.ph</a></div>
        </div>
    },

    'billease':
    {
        map: null,
        name: 'BillEase',
        icon: Icon.Billease,
        instruction: [
            'Choose your installment term and your cashout.',
            'Login or create a BillEase account, make sure to complete your loan registration process.',
            `Once your done, wait at least one day for your loan approval.
            You will receive a confirmation email from BillEase once your application is approved.`,
            supportInstruction
        ],
        disclaimer:
            <div style={{marginLeft: 3}}>
                <div>By using BillEase, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://billease.ph/terms/" target="_blank">Terms and Conditions</a></div>
                <div>Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:info@billease.ph">info@billease.ph</a></div>

                <br/>
                {/* <span style={{marginLeft: 3}}>
                    Disclaimer: By using BillEase, you agree to its <a style={{textDecoration: 'underline'}} href="https://billease.ph/privacy/" target="_blank">Terms and Conditions</a>.
                </span> */}
            </div>
    },

    'billease_sn':
    {
        map: null,
        name: 'BillEase',
        icon: Icon.Billease,
        instruction: [
            'Choose your installment term and your cashout.',
            'Login or create a BillEase account, make sure to complete your loan registration process.',
            `Once your done, wait at least one day for your loan approval.
            You will receive a confirmation email from BillEase once your application is approved.`,
            supportInstruction
        ],
        disclaimer:
            <div style={{marginLeft: 3}}>
                <div>By using BillEase, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://billease.ph/terms/" target="_blank">Terms and Conditions</a></div>
                <div>Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:info@billease.ph">info@billease.ph</a></div>

                <br/>
                {/* <span style={{marginLeft: 3}}>
                    Disclaimer: By using BillEase, you agree to its <a style={{textDecoration: 'underline'}} href="https://billease.ph/privacy/" target="_blank">Terms and Conditions</a>.
                </span> */}
            </div>
    },


    'instapay':
    {
        map: null,
        name: 'Instapay',
        icon: Icon.MainInstapay,
        instruction: upayInstruction,
        disclaimer: 
            <div style={{marginLeft: 3}}>
                <div>By using Instapay, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://www.instapayph.com/" target="_blank">Terms and Conditions</a></div>
            </div>
    },
    'instapay_allbank':
    {
        map: null,
        name: 'Instapay',
        icon: Icon.MainInstapay,
        instruction: instapayAllBankInstruction,
        disclaimer: 
            <div style={{marginLeft: 3}}>
                <div>By using Instapay, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://www.instapayph.com/" target="_blank">Terms and Conditions</a></div>
            </div>
    },
    'bdo_instapay':
    {
        map: null,
        name: 'Instapay',
        icon: Icon.MainInstapay,
        instruction: upayInstruction,
        disclaimer: 
            <div style={{marginLeft: 3}}>
                <div>By using Instapay, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://www.instapayph.com/" target="_blank">Terms and Conditions</a></div>
            </div>
    },
    'sb_instapay':
    {
        map: null,
        name: 'Instapay',
        icon: Icon.MainInstapay,
        instruction: upayInstruction,
        disclaimer: 
            <div style={{marginLeft: 3}}>
                <div>By using Instapay, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://www.instapayph.com/" target="_blank">Terms and Conditions</a></div>
            </div>
    },
    'ps_instapay':
    {
        map: null,
        name: 'Instapay',
        icon: Icon.MainInstapay,
        instruction: upayInstruction,
        disclaimer: 
            <div style={{marginLeft: 3}}>
                <div>By using Instapay, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://www.instapayph.com/" target="_blank">Terms and Conditions</a></div>
            </div>
    },
    'paygate':
    {
        map: null,
        name: 'PCHC PayGate',
        icon: Icon.MainPchc,
        instruction: upayInstruction,
        disclaimer: 
            <div style={{marginLeft: 3}}>
                <div>By using PCHC Paygate, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://www.pchc.com.ph/post/pesonet-rules-regulations" target="_blank">Terms and Conditions</a></div>
            </div>
    },
    'i2i':
    {
        map: null,
        name: 'i2i',
        icon: Icon.i2i,
        instruction: [
            <div>
                Go to any i2i Partner Rural Banks with Pay Bills service. Refer to <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://www.i2i.ph/biller/counters" target="_blank">https://www.i2i.ph/biller/counters</a> for the list of Rural Banks.
            </div>,
            <div>
                Inform Biller Name to be Paid - <b>BUX.</b>
            </div>,
            'Provide mandatory Payor Information - Full Name, Mobile Number.',
            <div>
                Provide Payor Date of Birth if processed with Bills Protect insurance (optional). To know more about Bills Protect refer to <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://www.i2i.ph/billsprotect" target="_blank"> https://www.i2i.ph/billsprotect </a>. 
            </div>,
            'Make sure to provide correct reference number to the counter. A wrong reference number can cause your transaction to remain unvalidated.',
            'Amount to be paid will be automatically generated based on the reference number.',
            'Additional transaction fees and/or service charge applies depending on the i2i Partner Rural Bank.',
            supportInstruction
        ],
        disclaimer: 
            <div style={{marginLeft: 3}}>
                <div>
                    By using i2i, you agree to its <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://s3-ap-southeast-1.amazonaws.com/cdn.i2i.ph/docs/i2i-terms-conditions-04-2021.pdf" target="_blank">Terms and Conditions</a>
                </div>
                <div> 
                    Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:support@bux.ph">support@bux.ph</a>.
                </div>

            </div>
    },


    //New Channels Bayad Center
    'bayad_center':
    {
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'Bayad Center',
        icon: Icon.BayadCenter,
        instruction: bayadCenterInstruction,
    },
    'bc_ussc':
    {
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'USSC',
        icon: Icon.USSC,
        instruction: bayadCenterInstruction,
    },
    'bc_rlnt':
    {
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'RuralNet',
        icon: Icon.Rural,
        instruction: bayadCenterInstruction,
    },
    'villarica':
    {
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'Villarica',
        icon: Icon.Villarica,
        instruction: bayadCenterInstruction,
    },
    'petnet':
    {
        map: `${process.env.REACT_APP_BASE_URL}/map`,
        name: 'Petnet, Inc',
        icon: Icon.Petnet,
        instruction: bayadCenterInstruction,
    },


    'manual_add':
    {
        map: null,
        name: 'Manual bank transfer',
        icon: Icon.Grabpay,
        instruction: walletInstruction,
        disclaimer: 
            <div style={{marginLeft: 3}}>
                <div>By using BUx you agree to our <a style={{color: '#F5922F', textDecoration: 'underline', fontWeight: 'bold'}} href="https://bux.ph/terms" target="_blank">Terms and Conditions</a></div>
                <div>Support: <a style={{color: '#000000', textDecoration: 'underline', fontWeight: 'bold'}} href="mailto:support@bux.ph">support@bux.ph</a></div>
            </div>
    },
}

export const disclaimer = {
    'gcash': {
        tnc: 
        <span>
            I agree to GCash <a href="https://www.gcash.com/terms-and-conditions/" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600'}}>Terms and Conditions</a>.
        </span>,
        name: 'GCash',
        support: 'help@gcash.ph'
    },
     
    'grabpay': {
        tnc:
        <span>
            I agree to GrabPay <a href="https://www.grab.com/ph/terms-policies/payment-rewards/" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600'}}>Terms and Conditions</a>.
        </span>,
        name: 'GrabPay',
        support: 'support@grab.com'
    },

    'maya': {
        tnc:
        <span>
            I agree to Maya <a href="https://www.maya.ph/terms-and-conditions" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600'}}>Terms and Conditions</a>.
        </span>,
        name: 'Maya',
        support: 'support@maya.ph'
    },

    'billease': {
        tnc:
        <span>
            I agree to Billease <a href="https://billease.ph/terms/" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600'}}>Terms and Conditions</a>.
        </span>,
        name: 'BillEase',
        support: 'info@billease.ph',
    },

    'instapay': {
        tnc:
        <span>
            I agree to InstaPay <a href="https://www.instapayph.com/" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600' }}>Terms and Conditions</a>.
        </span>,
        name: 'UPay',
        support: 'customer.service@unionbankph.com'
    },

    'ps_instapay': {
        tnc:
        <span>
            I agree to InstaPay <a href="https://www.instapayph.com/" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600' }}>Terms and Conditions</a>.
        </span>,
        name: 'UPay',
        support: 'customer.service@unionbankph.com'
    },

    'bdo_instapay': {
        tnc:
        <span>
            I agree to InstaPay <a href="https://www.instapayph.com/" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600' }}>Terms and Conditions</a>.
        </span>,
        name: 'UPay',
        support: 'customer.service@unionbankph.com'
    },

    'sb_instapay': {
        tnc:
        <span>
            I agree to InstaPay <a href="https://www.instapayph.com/" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600' }}>Terms and Conditions</a>.
        </span>,
        name: 'UPay',
        support: 'customer.service@unionbankph.com'
    },

    "paygate": {
        tnc:
        <span>
            I agree to PCHC Paygate <a href="https://www.pchc.com.ph/post/pesonet-rules-regulations" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600' }}>Terms and Conditions</a>.
        </span>,
        name: 'UPay',
        support: 'customer.service@unionbankph.com'
    },

    "visamc": {
        tnc: 
        <span>
        I agree to Xendit <a href="https://www.xendit.co/en/terms-and-conditions/" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600'}}>Terms and Conditions</a>.
        </span>,
        name: 'Xendit',
        support: 'help@xendit.co'
    },


    "ubpcs": {
        tnc:
        <span>
            I agree to BUx <a href="/terms_and_privacy" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600'}}>Terms and Conditions</a>.
        </span>,
        name: 'BUx',
        support: 'support@bux.ph',
    },

    "BPIA": {
        tnc:
        <span>
            I agree to Dragonpay <a href="https://www.dragonpay.ph/terms-and-conditions" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600'}}>Terms and Conditions</a>.
        </span>,        
        name: 'Dragonpay',
        support: 'help@dragonpay.ph'
    },

    "UBPB":{
        tnc:
            <span>
                I agree to Dragonpay <a href="https://www.dragonpay.ph/terms-and-conditions" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600'}}>Terms and Conditions</a>.
            </span>,        
        name: 'Dragonpay',
        support: 'help@dragonpay.ph'
    },
    "RCBC":{
        tnc:
        <span>
            I agree to Dragonpay <a href="https://www.dragonpay.ph/terms-and-conditions" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600'}}>Terms and Conditions</a>.
        </span>,        
        name: 'Dragonpay',
        support: 'help@dragonpay.ph'
    },
    "BDO":{
        tnc:
        <span>
            I agree to Dragonpay <a href="https://www.dragonpay.ph/terms-and-conditions" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600'}}>Terms and Conditions</a>.
        </span>,        
        name: 'Dragonpay',
        support: 'help@dragonpay.ph'
    },
    "MBTC":{
        tnc:
        <span>
            I agree to Dragonpay <a href="https://www.dragonpay.ph/terms-and-conditions" target="_blank" style={{ color: '#000000', textDecoration: 'underline', fontWeight: '600'}}>Terms and Conditions</a>.
        </span>,        
        name: 'Dragonpay',
        support: 'help@dragonpay.ph'
    },
    
}
