import { useEffect, useState } from "react";
import { axios } from "../../../../../../lib/axios";

export function getAdmins(currentPage = 1, searchQuery, selectedRailType) {
  const encodedSearchQuery = encodeURIComponent(searchQuery);
  return axios.get(
    `api/cashbux/admins/?page=${currentPage}&search=${encodedSearchQuery}&rail_type=${selectedRailType}`
  );
}

export function useAdmins() {
  const railTypes = ["All", "UnionBank", "AllBank"];

  const railTypeMap = {
    All: "All",
    UnionBank: "UBP",
    AllBank: "ALB",
  };

  const [selectedRailType, setSelectedRailType] = useState(railTypes[0]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await getAdmins(
        currentPage,
        searchQuery,
        railTypeMap[selectedRailType]
      );
      setData(response);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCurrentPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchQueryChange = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleRailTypeChange = (railType) => {
    setSelectedRailType(railType);
    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    setSelectedRailType("All");
    setSearchQuery("");
  };

  useEffect(() => {
    fetchData();
  }, [searchQuery, currentPage, selectedRailType]);

  return {
    data,
    isLoading,
    error,
    railTypes,
    railTypeMap,
    selectedRailType,
    handleRailTypeChange,
    searchQuery,
    handleSearchQueryChange,
    currentPage,
    handleCurrentPageChange,
    handleClearFilters,
  };
}
