import React from 'react';
import moment from 'moment';
import { Table, Pagination, Dropdown, Icon, Menu, Typography } from 'antd';

const MultiwalletTable = (props) => {
    const { data, isLoading, onChangePage, pageSize } = props;
    const [loading, setLoading] = React.useState(false);

    const returnStatus = (status) => {
        if (status === 'SU') {
            return 'Success'
        } else if (status === 'PE') {
            return 'Pending'
        } else {
            return 'Failed'
        }
    }

    const columns = [
        {
            title: 'Transaction ID',
            dataIndex: 'transfer_id',
        },
        {
            title: 'Receiver',
            dataIndex: 'receiver',
            render: (record) =>
                <div>
                    <Typography style={styles.receiverName}>{record.name}</Typography>
                    <Typography style={styles.receiverEmail}>{record.email}</Typography>
                </div>
        },
        {
            title: 'Date',
            dataIndex: 'timestamp',
            render: (record) => moment(record).format('MMM D, YYYY h:mm A')
        },
        {
                title: 'Amount',
                dataIndex: 'amount',
            render: (text, record) => <Typography style={{...styles.amount, color: record.is_sender? '#C0492C' : '#62CE90'}}>{record.is_sender?"-₱" : "+₱"}{text.toLocaleString("en-US", {minimumFractionDigits: 2})}</Typography>
        },
        {
            title: 'Remaining Balance',
            dataIndex: 'balance',
            render: (record) => <Typography style={{...styles.amount, color: '#2B2D32'}}>{"₱" + record.toLocaleString("en-US", {minimumFractionDigits: 2})}</Typography>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (record) => <div style={record === 'SU' ? styles.positive : record === 'PE' ? styles.pending : styles.negative} >{returnStatus(record)}</div>
        },
    ]


    const changePage = (page, pageSize) => {
        onChangePage(page);
    }

    return (
        <div>
            <Table
                rowClassName="table-row-light"
                columns={columns}
                dataSource={data && data['results'] ? data['results'] : []}
                pagination={{ pageSize: 20 }}
                loading={isLoading || loading}
                pagination={false}
            />
            <Pagination
                size='small'
                total={data && data['total']}
                defaultPageSize={pageSize}
                defaultCurrent={1}
                current={data && data['page']}
                onChange={(page, pageSize) => changePage(page, pageSize)}
                style={{ padding: '12px 0 8px 0', textAlign: 'right' }}
            />
        </div>
    )
}

const styles = {
    positive: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: 'rgb(232, 250, 243)',
        border: '1px solid #1DD28B',
        borderLeft: '5px solid #1DD28B',
    },
    negative: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: '#f9dbdb',
        border: '1px solid #E24C4C',
        borderLeft: '5px solid #E24C4C',
    },
    pending: {
        borderRadius: 4,
        padding: '4px 8px',
        backgroundColor: 'rgba(245, 146, 47, 0.1)',
        border: '1px solid rgb(245, 146, 47)',
        borderLeft: '5px solid #F59200',
    },
    receiverName: {
        color: '#2B2D32',
        fontSize: 14,
        fontWeight: '600',
        textAlign: 'left',
    },
    receiverEmail: {
        color: 'rgba(43, 45, 50, 0.64)',
        fontSize: 12,
        textAlign: 'left',
    },
    amount: {
        fontSize: 14,
        fontWeight: 'bold'
    }
}

export default MultiwalletTable;
