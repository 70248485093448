import React from 'react';
import { SearchPlus } from '../../static/new_segment/icons';
import { Typography, Divider, Card, Switch, Icon, message, Spin, Modal, Input } from 'antd';
import { history } from '../../store/history';
import accountServices from '../../services/account_settings';
import QRCode from 'qrcode.react';
import QrDetailsSection from './qr_details_section';
import { isMobile } from 'react-device-detect';
import SetupTradeName from '../../components/account_settings/bux_qr/setup_trade_name';

const FB_APP_ID = process.env.REACT_APP_FB_ID
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL
const REACT_APP_API_URL = process.env.REACT_APP_API_URL
const REACT_APP_ROOT_URL = process.env.REACT_APP_ROOT_URL;

const isWidthLowest = window.screen.width < 500;

const yourConfig = {
    headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
    }
}

const MyCheckout = (props) => {
    // subwallet checkout 
    const [tradeName, setTradeName] = React.useState("")
    let tName = localStorage.getItem('tradeName');
    let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
    const master_trade_name = window.location.hostname.replace(remove_part, "");
    let sub_remove_part = window.location.protocol + '//';
    const subQRCheckoutUrlLink = `/mycheckout/${tradeName}`;
    const subQrCheckoutUrl = subQRCheckoutUrlLink.replace(sub_remove_part, "")

    // old 
    // const qrLink = testMode ? REACT_APP_BASE_URL + '/' + tradeName + '/test' : REACT_APP_BASE_URL + '/' + tradeName

    const segments = localStorage.getItem('segments');
    const [checkout, setCheckout] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const checkoutLink = `https://bux.ph/sandraenterprisecc`;
    let profilePicUpload = React.useRef();
    const userType = localStorage.getItem('userType');
    const [coverUrl, setCoverUrl] = React.useState(null);
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const qrLink = userType === "SW" ? subQrCheckoutUrl : testMode ? (REACT_APP_BASE_URL + '/' + tradeName + '/test') : (REACT_APP_BASE_URL + '/' + tradeName)
    const notFound = REACT_APP_BASE_URL + '/404/'
    const isChild = localStorage.getItem("is_child") == "true" || localStorage.getItem("is_child") == true;
    const [qrOn, setQrOn] = React.useState(false);
    const [qrDetails, setQrDetails] = React.useState([]);
    const [QRshowModal, setQRshowModal] = React.useState(false);
    const [editQrLink, setEditQrLink] = React.useState(false);
    const protocol = window.location.protocol;

    const copyQrLink = userType === "SW" ? (protocol + '//' + master_trade_name + '.' + REACT_APP_BASE_URL.replace(sub_remove_part, "") + subQrCheckoutUrl) : qrLink;

    let buxQrLink;
    let sbTname = localStorage.getItem('SbTradeName')
    let noTradeName = testMode ? (sbTname === "null" || sbTname === null || sbTname === "") : (tName === "null" || tName === null || tName === "")
    let trade_name = testMode ? sbTname : tName;
    const [qrTradeName, setQrTradeName] = React.useState(trade_name);
    const subCheckout = localStorage.getItem('sub_checkout_enabled') === 'true';
    const [checkoutEnabled, setCheckoutEnabled] = React.useState(JSON.parse(localStorage.getItem('defined_wallet_settings')).checkout_enabled)


    const copyLink = (e) => {
        var range = document.createRange();
        range.selectNode(document.getElementById("qrLink"));
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        message.destroy()
        message.info("Checkout link copied")
    }

    async function uploadProfilePic(dataUri) {
        // var blob = dataURItoBlob(dataUri);

        // const formData = new FormData();

        // formData.append('banner', blob, 'id_image.png')
        // setLoading(true);

        // let res = await multiwalletServices.updateMultiwalletBanner(testMode, formData);

        // if (res.status === 'success') {
        //     setBannerUrl(res.url);
        //     message.success('Banner is updated')
        // } else {
        //     message.error(res.message);
        // }
        // setLoading(false);
    }

    const handleUploadImage = (info, type) => {
        // read the files
        var reader = new FileReader();
        reader.readAsArrayBuffer(info);

        reader.onload = function (event) {
            // blob stuff
            var blob = new Blob([event.target.result]); // create blob...
            window.URL = window.URL || window.webkitURL;
            var blobURL = window.URL.createObjectURL(blob); // and get it's URL

            // if (type == "profilePicID") {
            //       setProfilePic(info)
            //       setProfilePicURL(blobURL)
            // }
            // helper Image object
            var image = new Image();
            image.src = blobURL;
            image.onload = function () {
                // have to wait till it's loaded
                var resized = resizeMe(image); // resized image url
                uploadProfilePic(resized)
            }
        };
    }


    function resizeMe(img) {
        const max_width = 2000;
        const max_height = 2000;

        var canvas = document.createElement('canvas');

        var width = img.width;
        var height = img.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > max_width) {
                //height *= max_width / width;
                height = Math.round(height *= max_width / width);
                width = max_width;
            }
        } else {
            if (height > max_height) {
                //width *= max_height / height;
                width = Math.round(width *= max_height / height);
                height = max_height;
            }
        }

        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        return canvas.toDataURL("image/png", 0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)

        // you can get BLOB too by using canvas.toBlob(blob => {});

    }

    const toggleQrOn = async (value) => {
        setQrOn(qrDetails && !qrDetails.qr_enabled);
        setCheckoutEnabled(!qrDetails.qr_enabled)
        const params = {
            qr_enabled: qrDetails && !qrDetails.qr_enabled,
            any_amount: qrDetails && qrDetails.any_amount,
            allow_enter_amount: qrDetails && qrDetails.allow_enter_amount,
            show_mobile: qrDetails && qrDetails.show_mobile,
            show_email: qrDetails && qrDetails.show_email,
            customize_amount: qrDetails && qrDetails.customize_amount ? qrDetails.customize_amount : [],
            customize_amount_label: qrDetails && qrDetails.customize_amount_label ? qrDetails.customize_amount_label : [],
            personal_message: qrDetails && qrDetails.personal_message,
            notification_url: qrDetails && qrDetails.notification_url,
            field_1: qrDetails && qrDetails.field_1,
            show_field_1: qrDetails && qrDetails.show_field_1,
            field_2: qrDetails && qrDetails.field_2,
            show_field_2: qrDetails && qrDetails.show_field_2,
            field_1_required: qrDetails && qrDetails.field_1_required,
            field_2_required: qrDetails && qrDetails.field_2_required,
            fb: qrDetails && qrDetails.fb,
            ig: qrDetails && qrDetails.ig,
            web: qrDetails && qrDetails.web
        };
        updateQrSettings(params)
        //updateWalletSettings(params)
        //setCheckoutEnabled(response.data.value)
        console.log('UPDATED PARAMS', params);
    }

    const updateQrSettings = async (params) => {
        try {
            const res = await accountServices.updateQrDetails(testMode, params);
            console.log('UPDATED PARAMS', res);
            if (res.status === 'failed') {
                message.error(res.message, 3);
            } else {
                getQrDetails();
            }
        } catch (error) {

        }
    }

    const onTradenameUpdate = async () => {
        if (qrTradeName >= 24) {
            message.error("Max of 24 Character Limit")
            return
        }

        else if (!qrTradeName) {
            message.error("Please enter your trade name")
            return
        }

        let noSpace = qrTradeName.replace(/\s/g, '')
        let params = {
            new_qr_trade_name: noSpace,
        }

        try {
            const res = await accountServices.changeTradeName(testMode, params)
            if (res.status === "failed") {
                message.error("Error, try again.");
            }

            else if (res.status === "success") {
                localStorage.setItem("tradeName", params.new_qr_trade_name)
                setTradeName(params.new_qr_trade_name)
                message.success('Trade name modified', 3)
                setEditQrLink(false);
                getQrDetails()
            }
        } catch (error) {
            console.log(error);
            message.error("Error, try again.");
        }
    }

    const onTradenameCancel = () => {
        setQrTradeName(tradeName);
        setEditQrLink(false);
    }

    const getQrDetails = async () => {
        setLoading(true);
        try {
            let res;
            if (testMode && isChild) {
                res = await accountServices.getQRDetails(testMode, trade_name, master_trade_name);
            } else {
                res = await accountServices.getQRDetails(testMode, trade_name, master_trade_name);
            }
            if (res.status === 'failed') {
                setQrOn(false);
                if (testMode) {
                    localStorage.setItem("SbTradeName", "");
                }
                else {
                    localStorage.setItem("tradeName", "");
                }
            } else {
                setTradeName(res.trade_name)

                if (testMode) {
                    localStorage.setItem("SbTradeName", res.trade_name);
                }
                else {
                    localStorage.setItem("tradeName", res.trade_name);
                }
                setQrDetails(res);
                setQrOn(res.qr_enabled);
                setCheckoutEnabled(res.qr_enabled)

                var defined_wallet_settings = JSON.parse(localStorage.getItem('defined_wallet_settings'));
                defined_wallet_settings.checkout_enabled = res.qr_enabled
                localStorage.setItem("defined_wallet_settings", JSON.stringify(defined_wallet_settings));
            }
            console.log('MY QRDETAILS', res);
        } catch (error) {

        }
        setLoading(false);
    }

    const pageRedirect = () => {
        if (userType === 'SW' && !subCheckout || props.hidden) {
            window.location.hash = '#transaction_fees';
        }
    }

    React.useEffect(() => {
        pageRedirect();
        getQrDetails();
    }, [])

    return (
        <Card
            style={styles.root}
            bodyStyle={styles.cardBody}>
            <Typography style={styles.title}>My Checkout</Typography>
            <Divider />
            <div hidden={!noTradeName}>
                <SetupTradeName myConfig={yourConfig} REACT_APP_API_URL={REACT_APP_API_URL} />
            </div>
            <div hidden={noTradeName}>
                <div hidden={!qrOn}>
                    <div style={styles.headDiv} >
                        <div style={{ position: 'relative' }}>
                             {/* <img alt='No Image' style={styles.qrCode} src={'https://www.pngall.com/wp-content/uploads/2/QR-Code-PNG-Free-Image.png'} />  */}
                            <QRCode style={styles.qrCode} value={qrOn ? copyQrLink : notFound} size={120} level={'L'} />
                            <a className={`btn--${segments}`} type="file" type={'file-image'} style={styles.searchIcon} onClick={() => qrOn && setQRshowModal(true)}>
                                <img style={styles.uploadBtn} src={SearchPlus} alt="No Image" />
                            </a>
                        </div>
                        <div style={styles.nameDiv} >
                            <Typography style={styles.merchantName}>{tradeName}</Typography>
                            <button className={`outline-btn--${segments}`} style={styles.btnOutined}
                                onClick={() => window.open(qrOn ? qrLink : notFound, '_blank')}
                            // href={qrLink}
                            // target="_blank"
                            >
                                View My Checkout page
                            </button>
                        </div>
                    </div>
                    <Divider />
                </div>
                <div style={styles.sectionDiv}>
                    <div style={styles.switchDiv}>
                        <Typography style={styles.sectionTitle}>Your Checkout page is {qrOn ? 'ON' : 'OFF'}</Typography>
                        <Switch size='small' checked={qrOn} onClick={() => toggleQrOn()} className={qrOn && `switch-btn--${segments}`} />
                        {/* <label class="switch">
                        <input type="checkbox" className={`switch-btn--${segments}`} />
                        <span class ="slider round"></span>
                    </label> */}
                    </div>
                    <Typography style={styles.desc}>Anyone can see and send money to your BUx account.</Typography>
                </div>
                <div hidden={!checkoutEnabled || (qrDetails && !qrDetails.qr_enabled)}>
                    <Divider />
                    <div style={{ ...styles.sectionDiv }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: isWidthLowest ? 'flex-start' : 'center', display: editQrLink ? 'block' : 'flex', flexDirection: isWidthLowest && 'column' }}>
                            <div style={{ ...styles.flex, alignItems: 'flex-start' }}>
                                <Typography style={styles.sectionTitle}>QR checkout link</Typography>
                                <label hidden={editQrLink} className={`link-text--${segments}`} style={{ ...styles.link }} id='qrLink' value={copyQrLink}>{copyQrLink}</label>
                                <Input hidden={!editQrLink} placeholder='Insert tradename' size='large' style={{ width: '100%', margin: '8px 0 12px 0' }} value={qrTradeName}
                                    onChange={(e) => setQrTradeName(e.target.value)} />
                            </div>
                            <div style={styles.qrBtnDiv} hidden={editQrLink}>
                                <button className={`outline-btn--${segments}`} style={{ ...styles.copyBtn, marginRight: 4 }} onClick={() => setEditQrLink(true)}>
                                    <Icon style={{ paddingRight: 4 }} theme='filled' type={'edit'} />{'Edit'}
                                </button>
                                <button className={`outline-btn--${segments}`} style={styles.copyBtn} onClick={copyLink}>
                                    <Icon style={{ paddingRight: 4 }} type={'copy'} theme="filled" />Copy
                                </button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} hidden={!editQrLink}>
                            <button className={`outline-btn--${segments}`} style={{ ...styles.editBtn, margin: '0 4px' }} onClick={onTradenameCancel}>Cancel</button>
                            <button className={`btn--${segments}`} style={{ ...styles.editBtn, width: 76 }}
                                onClick={onTradenameUpdate}>Update</button>
                        </div>
                    </div>
                    <Divider hidden />
                    <div style={styles.sectionDiv} hidden>
                        <div style={styles.switchDiv}>
                            <Typography style={styles.sectionTitle}>Use cover photo</Typography>
                            <Switch checked={checkout} onClick={() => setCheckout(!checkout)} />
                        </div>
                        <Typography style={styles.desc}>Use uploaded cover photo on your business profile.</Typography>
                        <div>
                            <Typography style={styles.label} hidden={testMode}>Signup display logo</Typography>
                            <div style={styles.coverPhotoDiv}>
                                {loading ?
                                    <Spin size='small' />
                                    :
                                    <div>
                                        <input type="file"
                                            ref={profilePicUpload}
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleUploadImage(e.target.files[0], "profilePicID")}
                                            accept=".jpg,.png,.jpeg"
                                        >
                                        </input>
                                        <a style={styles.flex} type="file" onClick={() => profilePicUpload.current.click()}>
                                            <img alt='No Image' src={coverUrl} hidden={!coverUrl} style={{ height: 32, width: 64 }} />
                                            <span style={{ padding: '0 16px', color: '#54575F', fontSize: 16 }}>{coverUrl ? 'banner.png' : 'Upload banner'}</span>
                                        </a>
                                    </div>}
                                <a className={`link-text--${segments}`}
                                    style={styles.replaceBtn}
                                    // hidden={false || !coverUrl || loading} disabled={loading}
                                    onClick={() => profilePicUpload.current.click()}>{!coverUrl ? 'Replace logo' : 'Replace logo'}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <QrDetailsSection data={qrDetails} updateQrSettings={updateQrSettings} segments={segments} />
            <Modal
                centered
                width={400}
                height={400}
                visible={QRshowModal}
                onCancel={() => setQRshowModal(false)}
                footer={null}
                bodyStyle={styles.QRModalBody}
            >
                <QRCode style={styles.qrCodeModal} value={qrOn ? copyQrLink : notFound} size={400} level={'L'} />
                <button style={styles.qrCodeModalDone} onClick={() => setQRshowModal(false)} className={`btn--${segments}`}>Done</button>
            </Modal>
        </Card>
    )
}

const styles = {
    root: {
        padding: 0,
        width: '100%'
    },
    cardBody: {
        padding: '0 0 24px 0',
        width: isMobile && '100%'
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#2B2D32',
        padding: '32px 32px 0 32px'
    },
    btnOutined: {
        cursor: 'pointer',
        height: isWidthLowest ? 'auto' : 34,
        padding: '0 12px',
        margin: 0,
    },
    merchantName: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: 'bold',
        padding: '12px 0',
        maxWidth: isWidthLowest ? '146px' : '264px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    headDiv: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 32px'
    },
    nameDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '0 8px'
    },
    uploadBtn: {
        // position: 'absolute',
        // transform: 'translate(-50%, -50%)',
        // top: '70%',
        // left: '108%'
    },
    qrCode: {
        width: 120,
        height: 120,
        margin: '0 32px'
    },
    searchIcon: {
        display: 'block',
        width: '40px',
        height: '40px',
        borderRadius: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '92%',
        left: '78%'
    },
    sectionDiv: {
        padding: '0 32px 0px 32px'
    },
    switchDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 0 8px 0'
    },
    desc: {
        fontSize: 14,
        color: '#2B2D32',
        opacity: '0.8',
        lineHeight: '14px',
    },
    sectionTitle: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: '500'
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textDecoration: 'none',
    },
    link: {
        fontSize: 16,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0',
        maxWidth: 302,
        textDecoration: 'none',
        fontWeight: '500',
        cursor: 'pointer'
    },
    copyBtn: {
        cursor: 'pointer',
        height: 34,
        padding: '0 12px',
        margin: 0,
    },
    label: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: 12,
    },
    coverPhotoDiv: {
        border: '1px solid #B7B9C0',
        margin: '8px 0px',
        padding: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderRadius: 4,
    },
    replaceBtn: {
        fontSize: 16,
        fontWeight: '500',
    },
    qrCodeModal: {
        width: '100%',
        padding: '28px'
    },
    qrCodeModalDone: {
        fontSize: 16,
        cursor: 'pointer',
        textAlign: 'right'
    },
    QRModalBody: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        flexDirection: 'column'
    },
    updateMsgBtn: {
        padding: '12px 0 0 0',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    editBtn: {
        cursor: 'pointer',
        height: 34,
        padding: '0 12px',
        // width: 70,
        display: 'flex',
        alignItems: 'center'
    },
    qrBtnDiv: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        paddingTop: isWidthLowest && 12,
    }
}

export default MyCheckout;