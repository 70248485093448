import React from 'react';
import { isMobile } from 'react-device-detect';
import { Icon, Typography, Button, Input } from 'antd';
import * as Style from '../style';

// promo modal
import CheckoutPromoModal from '../../../AdminComponents/promocode/checkout_modal';
import PromoLogo from '../../../../static/img/checkout_promo_logo.svg';

const QRSummary = props => {
    const [checkoutPromoModal, setCheckoutPromoModal] = React.useState(false);
    const instapay_channels = ["bdo_instapay", "ps_instapay", "sb_instapay"];

    const {
        headerDetails, paymentType, paymentChannel, sur,
        numberWithCommax, amount, data, fees,
        promoAmount, userRole, onPromoCodeChange, onApplyPromo,
        promoCodeStatus, promoCode, testMode, promoType, promoMaxAmount,
        promoMinAmount
    } = props;

    const total = (div1, div2) => {
        return (
            <div className="twoCol top-12">
                {div1}
                {div2}
            </div>
        )
    }

    const validateAmount = (val) => {
        if ( amount > 0 && val < 50 ) {
            return 50;
        } 
        return val;
    }

    const computeDiscount = () => {
        let pmethod = paymentChannel[0];
        // set Instapay to the enabled channel
        if(instapay_channels.includes(pmethod)){
            pmethod = data.instapay_payment_channel;
        }
        const generatedAmount = data && !data.shoulder_enabled && fees ? (amount + (fees[pmethod] ? fees[pmethod] : 0)) : (amount);
        const totalDeductionAmount = promoType === 'Fixed'? promoAmount : ((promoAmount * generatedAmount)/ 100);

        const discountedAmount = promoType === 'Fixed' ? (generatedAmount - promoAmount) : ( generatedAmount - (promoAmount * generatedAmount)/ 100);
        // if ( discountedAmount < 50 ) {
        //   return (discountedAmount - 50 + promoAmount);
        // } 
        // return promoType === 'Fixed'? promoAmount : ((promoAmount * generatedAmount)/ 100);

        if ( discountedAmount < promoMinAmount && amount > 0 ) {
            if((discountedAmount - promoMinAmount + totalDeductionAmount) > promoMaxAmount) {
                return promoMaxAmount;
            }
          return (discountedAmount - promoMinAmount + totalDeductionAmount);
        } 


        return totalDeductionAmount > promoMaxAmount ? promoMaxAmount : totalDeductionAmount;
    }

    const styles = {
        promoFont: {
            color: '#2B2D32',
            fontSize: 16
        },
        applyPromoBtn: {
            width: isMobile ? '100%' : '24%',
            height: '40px',
            color: '#fff',
            fontWeight: '600',
            backgroundColor: '#F5922F',
            border: '1px solid #F5922F',
            borderRadius: '4px',
            margin: isMobile ? '12px 0' : '0 0 0 8px',
        },
        inputDiv: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column' : 'row'
        },
        exclamation: {
            color: '#77B2F2',
            border: '1px solid #77B2F2',
            borderRadius: 25,
            backgroundColor: '#FFF',
            fontSize: 14,
            height: 'fit-content',
            margin: '0px 12px 0 0'
        },
    }

    const PromoCodeInput = (role) => {
        return (
            <div hidden={role === "SW" || userRole.includes('CO') || data && data.segment.includes('SME', 'BIZ', 'NIL') || testMode} className="padding-bot-20 qr-dashed-line top-20" style={{ borderBottom: 0, paddingBottom: '2px' }}>
                <Typography style={{ ...styles.promoFont, fontSize: 14, opacity: '.8', paddingBottom: 4 }} >Enter Promo Code</Typography>
                <div style={styles.inputDiv}>
                    <Input
                        placeholder='Enter Promo Code'
                        size='large'
                        // addonAfter={<Icons type='close' hidden={!promoCode} onClick={() => setPromoCode('')} />}
                        onChange={onPromoCodeChange}
                        value={promoCode}
                        allowClear
                        style={{ ...styles.promoFont, borderRadius: 4, border: promoCodeStatus && promoCodeStatus.status === 'valid' ? '2px solid #59C378' : promoCodeStatus && promoCodeStatus.status === 'invalid' ? '2px solid #C0492C' : null }}
                    />
                    <Button style={styles.applyPromoBtn} onClick={onApplyPromo}>Apply</Button>
                </div>
                <Typography style={{
                    ...styles.promoFont, fontSize: 14, opacity: promoCodeStatus && promoCodeStatus.status === 'valid' && '.8',
                    color: promoCodeStatus && promoCodeStatus.status !== 'valid' && '#C0492C'
                }} hidden={!promoCode}
                >{promoCodeStatus && promoCodeStatus.message}</Typography>
                <div hidden={promoCodeStatus && promoCodeStatus.status !== 'valid'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 16 }}>
                    <Typography style={{ ...styles.promoFont }}>Promo Code</Typography>
                    <Typography style={{ ...styles.promoFont, color: '#C0492C', fontWeight: '500' }}>
                    -₱{
                        data && !data.shoulder_enabled && fees ?
                        numberWithCommax(parseFloat(computeDiscount()).toFixed(2).toString())
                        : numberWithCommax(parseFloat(computeDiscount()).toFixed(2).toString())
                    }</Typography>
                </div>
                <div hidden={promoCodeStatus && promoCodeStatus.status !== 'valid'}
                    style={{display: 'flex', alignItems: 'center', padding: '8px 0 0 0'}}>
                    <Icon type="exclamation-circle" theme="filled" style={styles.exclamation} />
                    <Typography style={{fontSize: 12, color: '#2B2D32'}}><b>{promoType === 'Fixed'? '₱' + parseFloat(promoAmount).toFixed(2) : promoAmount + '%' }</b> Off with min amount of <b>₱ { (promoMinAmount && promoMinAmount.toFixed(2)) || 50.00}</b></Typography>
                </div>
            </div>
        )
    }


    let pmethod = paymentChannel[0];
    // set Instapay to the enabled channel
    if(instapay_channels.includes(pmethod)){
        pmethod = data.instapay_payment_channel;
    }
    return(
        <div className="top-20" align="left">
            {headerDetails(`Summary`)}
            <div className={`top-20 ${isMobile ? '' : 'twoCol'}`}>
                <div className="medium-font-weight normal-text-size liveColor">
                    Payment Method
                </div>
                <div className={`medium-font-weight normal-text-size liveColor ${isMobile ? 'top-16' : ''}`}>
                    {paymentType[1]} <span hidden={!paymentChannel[1]}>-</span> {paymentChannel[1]}
                </div>
            </div>
            {
                ['BPIA', 'RCBC', 'UBPB', 'MBTC'].includes(paymentChannel[0]) &&
                <div className="qr-surcharge-div top-20" >
                    <Icon type="info-circle"
                        theme="filled" style={{color: "#f5922f"}} /> Separate ₱ <span className="right-4">{sur}</span> 
                            {paymentChannel[0] == "BPIA" ? "BPI" : 
                                paymentChannel[0] == "UBPB" ? "UBP" : 
                                    paymentChannel[0] == "MBTC" ? "Metrobank" : paymentChannel[0]} surcharge may apply
                </div>
            }

            <div className="padding-bot-20 qr-dashed-line">
            </div>

            <div className="top-20 padding-bot-20 qr-dashed-line">
                {total(
                    <div className="medium-font-weight normal-text-size liveColor">
                        Sub Total:
                    </div>,
                    <div className="medium-font-weight normal-text-size liveColor">
                        ₱ {numberWithCommax(parseFloat(amount).toFixed(2).toString())}
                    </div>
                )}
                <div hidden={data && data.shoulder_enabled}>
                    {total(
                        <div className="medium-font-weight normal-text-size liveColor">
                            Transaction Fee:
                        </div>,
                    <div className="medium-font-weight normal-text-size liveColor">
                            ₱ {fees ? `${fees[pmethod] ? fees[pmethod] : 0}` : '0.00'}
                        </div>
                    )}
                </div>
            </div>
            
            {PromoCodeInput(userRole)}
            <Button onClick={() => setCheckoutPromoModal(true)}
                hidden={userRole === "SW" || userRole.includes('CO') || data && data.segment.includes('SME', 'BIZ', 'NIL') || testMode}
                style={{height: '40px', border: '1px solid #3A73CC', width: '100%', marginTop: '12px', color: '#3A73CC', fontWeight: '700', fontSize: '16px'}}>
                <img alt='promo' src={PromoLogo} style={{position: 'absolute', left: '8px', top: '8px'}} />
                <label>See available voucher</label>
            </Button>

            <div>
                {total(
                        <div className="wc-header">
                            Total:
                        </div>,
                        <div className="wc-header">
                            ₱ {
                                data && !data.shoulder_enabled && fees ?
                                numberWithCommax(validateAmount(parseFloat(parseFloat(amount - computeDiscount()) + parseFloat(fees[pmethod] ? fees[pmethod] : 0))).toFixed(2).toString())
                                    : numberWithCommax(validateAmount(parseFloat(amount - computeDiscount())).toFixed(2).toString())
                            }
                        </div>
                    )}
            </div>

            <div style={Style.loanText} hidden={paymentChannel[0] !== 'billease'} align="left">
                <a href="https://billease.ph" target="blank" style={Style.loanLinkText}>BillEase Loan Calculator.</a>
            </div>
            <CheckoutPromoModal visible={checkoutPromoModal} onClose={() => setCheckoutPromoModal(false)} 
                        amount={amount} trade_name={(data && data.trade_name) || ''}  
                        applyVoucher={(code) => {onApplyPromo(code); setCheckoutPromoModal(false);} } />
        </div>
    )
}

export default QRSummary;
