import React from 'react';
import { NATURE_BUSINESS, backToTop } from '../../components/constants/constants';
import { ConvertAccountPhoto } from '../../static/new_segment/background_images_svg';
import myProfileServices from '../../services/my_profile';
import { Typography, Row, Col, Input, Button, Divider, Icon, Spin, Alert, Modal, Select, message } from 'antd';
import { Handle401 } from '../../components/handle401/handle401';
import { isMobile } from 'react-device-detect';
import ChangeMobileModal from './change_mobile_modal';
import ConvertConfirmationModal from './convert_account_modal';
import { history } from '../../store/history';
import { wrapper } from 'google-maps-react/dist/GoogleApiComponent';
import axios from 'axios'
import { getLatestBalance } from '../../components/get_balance/get_balance';

const isWidthLowest = window.screen.width < 400;

const InfoCard = (props) => {
    const { Option } = Select;
    const { segments, personalData, businessData, dashboardData, getDashboardNow, loading } = props;
    const userType = localStorage.getItem('userType');
    const [buxSegment, setBuxSegment] = React.useState(undefined);
    const [requestedSegment, setRequestedSegment] = React.useState(undefined);
    const [requiredTranx, setRequiredTranx] = React.useState('');
    const [isLessRequiredTranx, setIsLessRequiredTranx] = React.useState(false);
    const [editInfo1, setEditInfo1] = React.useState(false);
    const [editInfo2, setEditInfo2] = React.useState(false);
    const [editInfo3, setEditInfo3] = React.useState(false);
    const [editInfo4, setEditInfo4] = React.useState(false);
    const [updatePassShow, setUpdatePassShow] = React.useState(false);
    const [businessInfo, setBusinessInfo] = React.useState([]);
    const [showPassError, setShowPassError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [errorType, setErrortype] = React.useState('warning');
    const [passwordParams, setPasswordParams] = React.useState([]);
    const [untouchBusinessInfo, setUntouchBusinessInfo] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showBusinessInfoError2, setShowBusinessInfoError2] = React.useState(false);
    const [showBusinessInfoError4, setShowBusinessInfoError4] = React.useState(false);
    const [changeMobileShow, setChangeMobileShow] = React.useState(false);
    const [showConvertConfirmModal, setShowConvertConfirmModal] = React.useState(false)
    const [levelConversionType, setLevelConversionType] = React.useState(undefined)
    const isChild = localStorage.getItem('is_child') === 'true';
    const level = localStorage.getItem('Level');
    const kybStep = localStorage.getItem('KYBStep');
    const step = localStorage.getItem('Step');
    const subuserRole = localStorage.getItem('subuser_role');
    const unApproveduser = ((userType === "ME" || userType == "SW") && level == "0" && step == 0) || (userType === 'CO' && kybStep < 7);
    const userId = localStorage.getItem('userId');



    const showUpgradeButtons = () => {
        console.log(segments)
        console.log(dashboardData.paid)
        if (segments === 'me_' || segments === 'gig') {
            return (dashboardData.paid < 20 || step < 10 || isChild || unApproveduser) ? false : true;
        } else {
            return (dashboardData.paid < 100 || kybStep < 12 || isChild || unApproveduser) ? false : true;
        }

    }

    const accountLimits = [
        {
            type: 'BUxME',
            cash_in_limit: '₱100,000.00',
            cash_out_limit: '₱100,000.00',
            required_tranx: isChild ? 'N/A' : unApproveduser ? 'Not verified ' : !isLessRequiredTranx ? 'At least 20 paid transactions to upgrade account' : 'Upgrade to BUxGIG'
        },
        {
            type: 'BUxGIG',
            cash_in_limit: '₱500,000.00',
            cash_out_limit: '₱500,000.00',
            required_tranx: isChild ? 'N/A' : unApproveduser ? 'Not verified ' :
                !isLessRequiredTranx ? 'At least 20 paid transactions to upgrade account' :
                    'Upgrade to BUxSME or BUxBIZ'
        },
        {
            type: 'BUxSME',
            cash_in_limit: 'no limits',
            cash_out_limit: 'no limits',
            required_tranx: isChild ? 'N/A' : unApproveduser ? 'Not verified ' : !isLessRequiredTranx ? 'At least 100 paid transactions to upgrade account' : 'Upgrade to BUxBIZ'
        },
        {
            type: 'BUxBIZ',
            cash_in_limit: 'no limits',
            cash_out_limit: 'no limits',
            required_tranx: isChild ? 'N/A' : unApproveduser ? 'Not verified ' : 'Approved KYB'
        },
        {
            type: 'BUxME',
            cash_in_limit: '₱100,000.00',
            cash_out_limit: '₱100,000.00',
            required_tranx: isChild ? 'N/A' : unApproveduser ? 'Not verified ' : 'Approved KYC'
        },
    ]

    const setType = (type) => {
        switch (type) {
            case 'ME':
            case 'me_':
                return 0
            case 'gig':
                return 1
            case 'sme':
                return 2
            case 'biz':
                return 3
            default:
                return 0
        }
    }

    const selectSegmentsMenu = ['me_', 'gig', 'sme', 'biz']


    const closeConfirmationModal = () => {
        setShowConvertConfirmModal(false);
    }

    const UpgradeButton = () => {
        return (
            <button
                hidden={(segments === 'biz' || segments === 'gig' || isChild || unApproveduser || !isLessRequiredTranx)}
                className={`outline-btn--${segments}`}
                style={styles.editBtn}
                onClick={() => onClickUpgradeAccount(0)}>
                <Icon style={{ paddingRight: 4 }} type="arrow-up" theme='outlined' />
                {segments === 'me_' ? 'Upgrade to BUxGIG' :
                    segments === 'sme' ? 'Upgrade To BUxBIZ' : ''}
            </button>

        )
    }

    const onCompleteProfile = () => {
        const url = kybStep == 0 ? '/upgrade_enterprise' : '/complete_profile'
        history.push(url);
    }

    const onLearnMore = () => {
        const url = '/account_levels'
        history.push(url);
    }

    const setNaIfBlank = (val) => (val === 'n/a' || val === null || val === '') ? '-' : val;

    const arrangeBusinessData = () => {
        if (userType === 'CO' || (userType == 'SW' && subuserRole == 'CO')) {
            setBusinessInfo({
                businessType: businessData && businessData.business_type == 0 ? 'Sole Proprietor' : 'Corporate',
                natureOfBusiness: businessData && setNaIfBlank(NATURE_BUSINESS[businessData.nature_of_business]),
                businessName: businessData && setNaIfBlank(businessData.business_name),
                officePhone: businessData && setNaIfBlank(businessData.phone),
                officeFax: businessData && setNaIfBlank(businessData.fax),
                email: businessData && setNaIfBlank(businessData.email),
                facebook: setNaIfBlank(businessData.facebook),
                instagram: setNaIfBlank(businessData.instagram),
                website: setNaIfBlank(businessData.website)
            })
        } else {
            setBusinessInfo({
                businessType: personalData && personalData.business && personalData.business.business_type == 0 ? 'Sole Proprietor' : 'Corporate',
                natureOfBusiness: personalData && personalData.business && setNaIfBlank(personalData.business.industry),
                businessName: personalData && personalData.business && setNaIfBlank(personalData.business.name),
                officePhone: personalData && setNaIfBlank(personalData.contact),
                officeFax: personalData && setNaIfBlank(personalData.fax),
                email: personalData && setNaIfBlank(personalData.email),
                facebook: personalData && personalData.business && setNaIfBlank(personalData.business.facebook),
                instagram: personalData && personalData.business && setNaIfBlank(personalData.business.ig),
                website: personalData && personalData.business && setNaIfBlank(personalData.business.web),
                employees: personalData && personalData.business && personalData.business.employees,
                position: personalData && personalData.business && personalData.business.position,
                contact: personalData && personalData.business && personalData.contact,
                streetAddress: businessData && businessData.present_address && businessData.present_address.street,
                cityAddress: businessData && businessData.present_address && businessData.present_address.city,
                provinceAddress: businessData && businessData.present_address && businessData.present_address.province,
                postalCode: businessData && businessData.present_address && businessData.present_address.postalCode,
            })
        }
    }

    const arrangeDashboardData = () => {
        setIsLessRequiredTranx(showUpgradeButtons());
    }

    const corpFullAddress = businessData.address_unit || businessData.address_street ? (businessData.address_unit + " " + businessData.address_street + " " + businessData.address_subdivision + " " + businessData.address_barangay
        + " " + businessData.address_city + " " + businessData.address_province + " " + businessData.address_zip_code) : "- -"

    const indiFullAddress = `${businessData && businessData.present_address ? businessData.present_address.street + ', ' + businessData.present_address.barangay + ', ' + businessData.present_address.city + ', ' + businessData.present_address.province + ' ' + businessData.present_address.postalCode : 'n/a'}`


    const onUpdatePassword = () => {
        if (passwordHasBlanks()) {
            return false;
        }
        if (passwordParams.confirmPassword === passwordParams.newPassword) {
            if (passwordParams.newPassword.length < 8) {
                setShowPassError(true)
                setErrorMsg("Password must contain atleast 8 characters")
            }
            else if (passwordParams.newPassword.search(/^[a-zA-Z]+$/) != -1) {
                setShowPassError(true)
                setErrorMsg("Password must contain atleast 1 number")

            }
            else if (passwordParams.newPassword.search(/^[a-z0-9]+$/) != -1) {
                setShowPassError(true)
                setErrorMsg("Password must contain atleast 1 capital letter")

            }
            else if (passwordParams.newPassword.search(/^[A-Z0-9]+$/) != -1) {
                setShowPassError(true)
                setErrorMsg("Password must contain atleast 1 small letter")

            }
            else {
                let params = {
                    old_password: passwordParams.oldPassword,
                    new_password: passwordParams.newPassword,
                }

                changePassword(params)
            }
        }
        else {
            setShowPassError(true)
            setErrorMsg("Password does not match")
        }

    }

    const passwordHasBlanks = () => {
        const isEmpty = Object.values(passwordParams).some(x => (x === null || x === ''));
        if (isEmpty === true || Object.keys(passwordParams).length < 3) {
            return true;
        }
        return false;
    }

    const changePassword = async (params) => {
        try {
            const res = await myProfileServices.updatePassword(params)

            if (res.status === "success") {
                setErrorMsg(res.message)
                setErrortype(res.status)
                Handle401()
            }
            else {
                setShowPassError(true)
                setErrortype(res.status)
                setErrorMsg(res.message)
            }
        } catch (error) {
            setShowPassError(true);
            setErrortype('error');
            setErrorMsg('ERROR: password not saved');
  
        }
    }

    const onClickUpgradeAccount = (type) => {
        setLevelConversionType(type)
        setShowConvertConfirmModal(true);
    }



    const changeMobileNumber = async () => {
        try {
            const res = await myProfileServices.updateMobileNumber();
            if (res.status === 'success') {
                setChangeMobileShow(true);
            }
        } catch (error) {
            message.error('Error, try again.')
        }
    }

    const saveBusinessInfo = async (editBtn) => {
        let params;
        // if (userType === 'CO') {
        //     params = {
        //         trade_name: businessInfo.businessName,
        //     }
        // } else {
            params = {
                business_name: businessInfo.businessName,
                industry: businessInfo.natureOfBusiness,
                facebook: businessInfo && businessInfo.facebook === '-' ? '' : businessInfo.facebook,
                instagram: businessInfo && businessInfo.instagram === '-' ? '' : businessInfo.instagram,
                website: businessInfo && businessInfo.website === '-' ? '' : businessInfo.website,
                employees: businessInfo && businessInfo.employees === '-' ? '' : businessInfo.employees,
                business_type: personalData && personalData.business.business_type === '-' ? '' : personalData.business.business_type,
                position: businessInfo && businessInfo.position === '-' ? '' : businessInfo.position,
                contact: businessInfo && businessInfo.contact === '-' ? '' : businessInfo.contact,
                street_address: businessInfo && businessInfo.streetAddress === '-' ? '' : businessInfo.streetAddress,
                city_address: businessInfo && businessInfo.cityAddress === '-' ? '' : businessInfo.cityAddress,
                province_address: businessInfo && businessInfo.provinceAddress === '-' ? '' : businessInfo.provinceAddress,
                postal_code: businessInfo && businessInfo.postalCode === '-' ? '' : businessInfo.postalCode,
            // }
        }
        if (JSON.stringify(untouchBusinessInfo) === JSON.stringify(businessInfo)) {
            editBtn === 'businessInfo' ? setEditInfo2(false) : setEditInfo4(false);
            return false;
        }
        setIsLoading(true);
        try {
            const res = (userType === 'CO' || (userType == 'SW' && subuserRole == 'CO')) ? await myProfileServices.updateKybBusinessName(params) : await myProfileServices.updateKycInfo(params);
            if (userType === 'CO' || (userType == 'SW' && subuserRole == 'CO')) {
                if (res.errors.length) {
                    setErrortype('error');
                    setErrorMsg("Please contact BUx support to change your business name");
                    editBtn === 'businessInfo' ? setShowBusinessInfoError2(true) : setShowBusinessInfoError4(true);
                } else {
                    // localStorage.setItem('username', tempTradeName);
                    // message.success("Settings saved. Updating your profile", 3);
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 2500);
                    editBtn === 'businessInfo' ? setShowBusinessInfoError2(true) : setShowBusinessInfoError4(true);
                    setErrortype('success');
                    setErrorMsg("Setting saved");
                    setEditInfo2(false);
                    setTimeout(() => {
                        editBtn === 'businessInfo' ? setShowBusinessInfoError2(false) : setShowBusinessInfoError4(false);
                    }, 3000);
                }
            } else {
                if (res.status === 'success') {
                    editBtn === 'businessInfo' ? setShowBusinessInfoError2(true) : setShowBusinessInfoError4(true);
                    setErrortype('success');
                    setErrorMsg(res.message);
                    setEditInfo2(false);
                    setTimeout(() => {
                        editBtn === 'businessInfo' ? setShowBusinessInfoError2(false) : setShowBusinessInfoError4(false);
                    }, 3000);
                } else {
                    setErrortype('error');
                    setErrorMsg(res.message);
                    editBtn === 'businessInfo' ? setShowBusinessInfoError2(true) : setShowBusinessInfoError4(true);
                }
            }
        } catch (error) {
            setErrortype('error');
            setErrorMsg("ERROR: Settings not saved");
            editBtn === 'businessInfo' ? setShowBusinessInfoError2(true) : setShowBusinessInfoError4(true);
        }
    }

    const onBusinessInfoChange = (event) => {
        setBusinessInfo({ ...businessInfo, [event.target.name]: event.target.value })
    }

    const passwordChange = (event) => {
        setPasswordParams({ ...passwordParams, [event.target.name]: event.target.value })
    }

    const UpdatePassword = (hide) => (
        <div hidden={!hide} style={styles.updatePassDiv}>
            <div hidden={!showPassError} style={{ width: '100%' }} >
                <Alert message={errorMsg} type={errorType} style={{ marginBottom: '20px' }} showIcon />
            </div>
            <Input.Password
                style={{ ...styles.fieldValue, margin: '6px 0' }}
                size='large'
                placeholder='Old password'
                name='oldPassword'
                onChange={passwordChange}
                value={passwordParams.oldPassword || ''}
            />
            <Input.Password
                style={{ ...styles.fieldValue, margin: '6px 0' }}
                size='large'
                name='newPassword'
                placeholder='New password'
                onChange={passwordChange}
                value={passwordParams.newPassword || ''}
            />
            <Input.Password
                style={{ ...styles.fieldValue, margin: '6px 0' }}
                size='large'
                name='confirmPassword'
                placeholder='Confirm password'
                onChange={passwordChange}
                value={passwordParams.confirmPassword || ''}
            />
            <div style={styles.updatePassBtn}>
                <button className={`outline-btn--${segments}`} style={{ ...styles.editBtn, margin: '0 4px' }} onClick={() => setUpdatePassShow(false)}>Cancel</button>
                <button className={`btn--${segments}`} style={{ ...styles.editBtn, width: 76 }} onClick={onUpdatePassword}>Update</button>
            </div>
        </div>
    )

    const closeEditableForm = (form) => {
        setUntouchBusinessInfo(businessInfo);
        setPasswordParams([]);
        setShowPassError(false);
        setShowBusinessInfoError2(false);
        setShowBusinessInfoError4(false);
        if (form === 2) {
            setEditInfo2(true);
            setEditInfo4(false);
        } else if (form === 4) {
            setEditInfo4(true);
            setEditInfo2(false);
        }
    }

    const onSetBuxSegment = (e) => {
        setBuxSegment(accountLimits[Object.keys(accountLimits)[setType(e)]].type);
        setRequestedSegment(selectSegmentsMenu[setType(e)])
    }



    React.useEffect(() => {
        arrangeBusinessData();
        arrangeDashboardData();
        //setBuxSegment(accountLimits[Object.keys(accountLimits)[setType(segments)]].type);
        setRequiredTranx(dashboardData.paid)
    }, [loading])

    return (
        <div>
            <div hidden={userType === 'SW'} style={styles.section}>
                <div style={styles.titleDiv}>
                    <Typography style={styles.title}>Account level and limits</Typography>
                    {UpgradeButton()}
                </div>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Account type</Col>
                    <Col span={16} hidden={loading} style={styles.fieldValue}>{accountLimits[setType(segments)].type}</Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Cash in limits</Col>
                    <Col span={16} hidden={loading} style={styles.fieldValue}>{accountLimits[setType(segments)].cash_in_limit}</Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Cash out limits</Col>
                    <Col span={16} hidden={loading} style={styles.fieldValue}>{accountLimits[setType(segments)].cash_out_limit}</Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row} hidden={segments === 'gig' || segments === 'biz'}>
                    <Col span={8} style={styles.field}>Upgrade account</Col>
                    <Col span={16} hidden={loading} style={{ ...styles.fieldValue, fontWeight: '700' }}>
                        <span>{accountLimits[setType(segments)].required_tranx}
                            <a hidden={!unApproveduser} className={`link-text--${segments}`} type='link' onClick={onCompleteProfile} > complete profile</a>
                        </span>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                
                <div style={styles.convertBorder} className="flex padding-16">
                    <div hidden={isMobile} className={"right-20"} style={{alignSelf: 'center'}}>
                        <img src={ConvertAccountPhoto} alt="convert" />
                    </div>
                    <div hidden={loading} style={{alignSelf: 'center', width: '100%'}}>
                        <div className="wc-header">
                            Want to change your account level?
                        </div>
                        <div className="top-12 flex" style={{width: '100%'}}>
                            <div className="right-8" style={{width: isMobile ? '60%' : '70%'}}>
                                <Select
                                    size="medium"
                                    style={{ width: '100%' }}
                                    onChange={(e) => onSetBuxSegment(e)}
                                    value={buxSegment}
                                    placeholder="Choose"
                                    disabled={isChild || unApproveduser}>{
                                        selectSegmentsMenu.map((x) => {
                                            if (x !== segments) {
                                                return <Option key={x}>{accountLimits[Object.keys(accountLimits)[setType(x)]].type}</Option>
                                            }
                                        })
                                    }
                                </Select>
                            </div>
                            <div>
                                <button
                                    className={`btn--${segments}`}
                                    disabled={!buxSegment || isChild || unApproveduser}
                                    onClick={() => onClickUpgradeAccount(1)}>
                                    Send Request
                                </button>
                            </div>
                        </div>
                        <div className="top-12">
                            <a href className={`link-text--${segments}`} type='link' onClick={() => onLearnMore()}>
                                Learn more on account levels
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Divider hidden={userType === 'SW'} />
            <div style={styles.section}>
                <div style={styles.titleDiv}>
                    <Typography style={styles.title}>Personal Information</Typography>
                    <button hidden className={`outline-btn--${segments}`} style={styles.editBtn} onClick={() => setEditInfo1(!editInfo1)}>
                        <Icon style={{ paddingRight: 4 }} type={editInfo1 ? 'save' : 'edit'} theme='filled' />{editInfo1 ? 'save' : 'Edit'}
                    </button>
                </div>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>UID - User ID</Col>
                    <Col span={16} style={styles.fieldValue} hidden={loading}>{personalData && personalData.bux_id}</Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Email address</Col>
                    <Col span={16} hidden={loading}>
                        <Input
                            hidden={!editInfo1}
                            value={personalData && personalData.email}
                            contentEditable={editInfo1}
                            bordered={editInfo1}
                            style={styles.fieldValue}
                            size='large'
                        />
                        <Typography hidden={editInfo1} style={styles.fieldValue}>
                            {personalData && personalData.email}
                        </Typography>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Mobile number</Col>
                    <Col span={16} hidden={loading}>
                        <Input
                            hidden={!editInfo1}
                            value={personalData && '+63' + personalData.contact}
                            contentEditable={editInfo1}
                            bordered={editInfo1}
                            style={styles.fieldValue}
                            size='large'
                        />
                        <div style={styles.changeMobileDiv}>
                            <Typography hidden={editInfo1} style={{ ...styles.fieldValue, paddingRight: 8 }}>
                                {personalData && '+63' + personalData.contact}
                            </Typography>
                            <a className={`link-text--${segments}`} type='link' onClick={changeMobileNumber} ><b>Change</b></a>
                        </div>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Password</Col>
                    <Col span={16} hidden={loading}>
                        <a className={`link-text--${segments}`} type='link' onClick={() => (setUpdatePassShow(!updatePassShow) / setPasswordParams([]) / setShowPassError(false))} ><b>Update Password</b></a>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                    {UpdatePassword(updatePassShow)}
                </Row>
            </div>
            <Divider />
            <div style={styles.section}>
                <div style={styles.titleDiv}>
                    <Typography style={styles.title}>Business Information</Typography>
                    <button className={`outline-btn--${segments}`} style={styles.editBtn} onClick={() => editInfo2 ? saveBusinessInfo('businessInfo') : (setEditInfo2(!editInfo2) / closeEditableForm(2))}>
                        <Icon style={{ paddingRight: 4 }} theme='filled' type={editInfo2 ? 'save' : 'edit'} hidden={editInfo2} />{editInfo2 ? 'save' : 'Edit'}
                    </button>
                </div>
                <div hidden={!showBusinessInfoError2} style={{ width: '100%' }} >
                    <Alert message={errorMsg} type={errorType} style={{ marginBottom: '20px' }} showIcon />
                </div>
                <Row style={styles.row} hidden={(['me_', 'gig']).includes(segments)}>
                    <Col span={8} style={styles.field}>Business type</Col>
                    <Col span={16} hidden={loading}>
                        <Input
                            hidden
                            value={businessInfo.businessType}
                            contentEditable={editInfo2}
                            bordered={editInfo2}
                            style={styles.fieldValue}
                            size='large'
                        />
                        <Typography style={styles.fieldValue}>
                            {businessInfo.businessType}
                        </Typography>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Nature of business</Col>
                    <Col span={16} hidden={loading}>
                        <Input
                            hidden={!editInfo2 && userType === 'ME' || userType === 'CO' || userType === 'SW'}
                            value={businessInfo.natureOfBusiness}
                            contentEditable={editInfo2}
                            bordered={editInfo2}
                            style={styles.fieldValue}
                            size='large'
                            name='natureOfBusiness'
                            onChange={onBusinessInfoChange}
                        />
                        <Typography hidden={editInfo2 && userType === 'ME'} style={styles.fieldValue}>
                            {businessInfo.natureOfBusiness}
                        </Typography>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Business name</Col>
                    <Col span={16} hidden={loading}>
                        <Input
                            hidden={!editInfo2}
                            value={businessInfo.businessName}
                            contentEditable={editInfo2}
                            bordered={editInfo2}
                            style={styles.fieldValue}
                            size='large'
                            name='businessName'
                            onChange={onBusinessInfoChange}
                        />
                        <Typography hidden={editInfo2} style={styles.fieldValue}>
                            {businessInfo.businessName}
                        </Typography>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Address</Col>
                    <Col span={16} hidden={loading}>
                        <Input
                            hidden
                            value={userType === 'CO' ? corpFullAddress : indiFullAddress}
                            contentEditable={editInfo2}
                            bordered={editInfo2}
                            style={styles.fieldValue}
                            size='large'
                        />
                        <Typography style={styles.fieldValue}>
                            {userType === 'CO' ? corpFullAddress : indiFullAddress}
                        </Typography>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
            </div>
            <Divider hidden={(['gig', 'me_']).includes(segments)} />
            <div style={styles.section} hidden={(['gig', 'me_']).includes(segments)}>
                <div style={styles.titleDiv}>
                    <Typography style={styles.title}>Business Declaration</Typography>
                    <button hidden className={`outline-btn--${segments}`} style={styles.editBtn} onClick={() => setEditInfo3(!editInfo3)}>
                        <Icon style={{ paddingRight: 4 }} theme='filled' type={editInfo3 ? 'save' : 'edit'} hidden={editInfo3} />{editInfo3 ? 'save' : 'Edit'}
                    </button>
                </div>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Office phone no.</Col>
                    <Col span={16} hidden={loading}>
                        <Input
                            hidden={!editInfo3}
                            value={businessInfo.officePhone}
                            contentEditable={editInfo3}
                            bordered={editInfo3}
                            style={styles.fieldValue}
                            size='large'
                        />
                        <Typography hidden={editInfo3} style={styles.fieldValue}>
                            {businessInfo.officePhone}
                        </Typography>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row} hidden={userType !== 'CO'}>
                    <Col span={8} style={styles.field}>Fax no.</Col>
                    <Col span={16} hidden={loading}>
                        <Input
                            hidden={!editInfo3}
                            value={businessInfo.officeFax}
                            contentEditable={editInfo3}
                            bordered={editInfo3}
                            style={styles.fieldValue}
                            size='large'
                        />
                        <Typography hidden={editInfo3} style={styles.fieldValue}>
                            {businessInfo.officeFax}
                        </Typography>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Email address</Col>
                    <Col span={16} hidden={loading}>
                        <Input
                            hidden={!editInfo3}
                            value={businessInfo.email}
                            contentEditable={editInfo3}
                            bordered={editInfo3}
                            style={styles.fieldValue}
                            size='large'
                        />
                        <Typography hidden={editInfo3} style={styles.fieldValue}>
                            {businessInfo.email}
                        </Typography>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
            </div>
            <Divider />
            <div style={{ ...styles.section, padding: isMobile ? '0px 16px 54px 16px' : '0 32px' }}>
                <div style={styles.titleDiv}>
                    <Typography style={styles.title}>Website and social media account</Typography>
                    <button hidden={userType === 'CO'} className={`outline-btn--${segments}`} style={styles.editBtn} onClick={() => editInfo4 ? saveBusinessInfo('websites') : (setEditInfo2(!editInfo4) / closeEditableForm(4))}>
                        <Icon style={{ paddingRight: 4 }} theme='filled' type={editInfo4 ? 'save' : 'edit'} hidden={editInfo4} />{editInfo4 ? 'save' : 'Edit'}
                    </button>
                </div>
                <div hidden={!showBusinessInfoError4} style={{ width: '100%' }} >
                    <Alert message={errorMsg} type={errorType} style={{ marginBottom: '20px' }} showIcon />
                </div>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Facebook</Col>
                    <Col span={16} hidden={loading}>
                        <Input
                            hidden={!editInfo4}
                            value={businessInfo.facebook}
                            contentEditable={editInfo4}
                            bordered={editInfo4}
                            style={styles.fieldValue}
                            size='large'
                            name='facebook'
                            onChange={onBusinessInfoChange}
                        />
                        <Typography hidden={editInfo4} style={styles.fieldValue}>
                            {businessInfo.facebook}
                        </Typography>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Instagram</Col>
                    <Col span={16} hidden={loading}>
                        <Input
                            hidden={!editInfo4}
                            value={businessInfo.instagram}
                            contentEditable={editInfo4}
                            bordered={editInfo4}
                            style={styles.fieldValue}
                            size='large'
                            name='instagram'
                            onChange={onBusinessInfoChange}
                        />
                        <Typography hidden={editInfo4} style={styles.fieldValue}>
                            {businessInfo.instagram}
                        </Typography>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <Row style={styles.row}>
                    <Col span={8} style={styles.field}>Website</Col>
                    <Col span={16} hidden={loading}>
                        <Input
                            hidden={!editInfo4}
                            value={businessInfo.website}
                            contentEditable={editInfo4}
                            bordered={editInfo4}
                            style={styles.fieldValue}
                            size='large'
                            name='website'
                            onChange={onBusinessInfoChange}
                        />
                        <Typography hidden={editInfo4} style={styles.fieldValue}>
                            {businessInfo.website}
                        </Typography>
                    </Col>
                    <Spin size='small' hidden={!loading} />
                </Row>
                <br />
                <br />
            </div>
            <ChangeMobileModal visible={changeMobileShow} onClose={() => setChangeMobileShow(false)} segments={segments} />
            <ConvertConfirmationModal
                segments={segments}
                visible={showConvertConfirmModal}
                onClose={() => closeConfirmationModal()}
                type={levelConversionType}
                requestedSegment={levelConversionType == 0 ? selectSegmentsMenu[setType(segments)+1] : requestedSegment} />
        </div >
    )
}

const styles = {
    root: {

    },
    section: {
        padding: isMobile ? '0 16px' : '0px 32px'
    },
    title: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: 'bold'
    },
    titleDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: isWidthLowest ? 'flex-start' : 'center',
        paddingBottom: 12,
        flexDirection: isWidthLowest ? 'column' : 'row',
        paddingTop: isWidthLowest,
    },
    row: {
        padding: '8px 0'
    },
    field: {
        color: '#2B2D32',
        fontSize: 16,
        fontWeight: '300'
    },
    imageContainer: {
        width: 'auto',
        padding: '0px 20px 0px 20px',
        alignSelf: 'center',
    },
    fieldValue: {
        color: '#2B2D32',
        fontWeight: '500',
        fontSize: 16,
    },
    editBtn: {
        cursor: 'pointer',
        height: 34,
        padding: '0 12px',
        margin: isWidthLowest ? '12px 0 0 0' : 0,
    },
    updatePassDiv: {
        width: '64%',
        padding: '12px 0',
        display: 'flex',
        flexDirection: 'column'
    },
    updatePassBtn: {
        padding: '12px 0',
        textAlign: 'right'
    },
    changeMobileDiv: {
        display: 'flex',
        alignItems: isWidthLowest ? 'flex-start' : 'center',
        flexDirection: isWidthLowest && 'column'
    },
    convertInfoDiv: {
        display: 'flex',
        flexDirection: 'column',
    },
    convertInfo: {
        flex: '1 1 35px',
    },
    convertBorder: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '30px',
        borderRadius: '5px',
        border: '1px solid #D4D5D8',
        height: '150px',
    }
}

export default InfoCard;