import React, {useEffect, useState, useRef} from 'react';
import {Button, Card, Checkbox, Divider, Icon, Input, List, message, Modal, Pagination, Table, Typography} from 'antd';
import feeStyle from './style';
import accountSettingsServices from '../../services/account_settings';
import { history } from '../../store/history';
import { isMobile } from 'react-device-detect';

const EditPermission = (props) => {
    const {
        isPermissionsModalVisible,
        setIsPermissionsModalVisible,
    } = props;
    const fee = feeStyle();
    const userType = localStorage.getItem('userType');
    const pUid = userType === 'CO' || userType === "ME" ? '' : props && props.props && props.props.match.params.id;
    const parentID = (userType === 'CO' || userType === "ME" || userType == "SW") ? localStorage.getItem('userId') : pUid;
    const CORE_SETTINGS = [
        'dashboard_access',
        'integration_access',
        'payment_request_access',
        'payout_access',
        'reconciliation_access',
        'settings_access',
    ]

    const permissionsSnapshot = useRef([]);
    const [aliasPermissions, setAliasPermissions] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const segments = localStorage.getItem('segments');
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const [minPage, setMinPage] = useState(0);
    const [maxPage, setMaxPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    async function loadAliasPermissions() {
        try {
            let response = await accountSettingsServices.getAliasPermissions(parentID);
            const permissionsData = response.data;

            permissionsSnapshot.current = permissionsData.map(alias => {return {...alias}});
            setAliasPermissions(permissionsData);
        } catch (err) {
            message.error(err.message);
        }

        setIsTableLoading(false);
    }


    async function savePermission(alias_id, setting_name, isChecked) {
        let params = {
            alias_id,
            'name': setting_name,
            'value': isChecked ? "True" : "False",
            'type': 'bool'
        }

        try {
            let response = await accountSettingsServices.saveAliasPermissions(parentID, params);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }

        } catch (err) {
            message.error(err.message);
        }
    }


    function checkPermissionsBeforeSaving() {
        for (const alias of aliasPermissions) {
            const snapshotCounterpart = permissionsSnapshot.current.find(snapshot => snapshot.alias_id === alias.alias_id);

            for (const setting_name of Object.keys(alias)) {
                if (snapshotCounterpart[setting_name] !== alias[setting_name]) {
                    savePermission(alias.alias_id, setting_name, alias[setting_name]);
                }
            }
        }
    }


    function handleCheckChange(alias_id, setting_name, isChecked) {
        
        let newPermissions = aliasPermissions.map(alias => {return {...alias}});
        let affected_alias = newPermissions.find(alias => (alias.alias_id === alias_id));
        affected_alias[setting_name] = isChecked;

        let wereCorePermissionsEmptied = ! CORE_SETTINGS.map(setting_name => affected_alias[setting_name])
                                                        .some(setting_val => !!setting_val);
        if (wereCorePermissionsEmptied) {
            message.error("At least one box must be checked for the fields not marked optional.");
            return;
        }

        setAliasPermissions(newPermissions);
    }


    const permissionsColumns = [
        {
            title: (<Typography style={{padding: '34px 100px', height: 90}} >Name</Typography>),
            key: 'name',
            width: '20vw',
            fixed: 'left',
            render: (text, alias_user) => {
                return (
                    <div>
                        <div><b>{alias_user.full_name}</b></div>
                        <div style={{color: "#505050"}}>{alias_user.email}</div>
                    </div>
                )
            }
        },
        {
            title: "Dashboard",
            key: 'dashboard_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.dashboard_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'dashboard_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Integration",
            key: 'integration_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.integration_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'integration_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Payment Request",
            key: 'payment_request_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.payment_request_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'payment_request_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Payout",
            key: 'payout_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.payout_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'payout_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Reconciliation",
            key: 'reconciliation_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.reconciliation_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'reconciliation_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Settings",
            key: 'settings_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.settings_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'settings_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: (
                <span style={{fontSize: 10}}>
                    Test Mode <i>(optional)</i>
                </span>
                ),
            key: 'test_mode_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.test_mode_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'test_mode_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: (
                <span style={{fontSize: 10}}>
                    Settlement Reports <i>(optional)</i>
                </span>
                ),
            key: 'settlement_reports_visibility',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.settlement_reports_visibility}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'settlement_reports_visibility', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: (
                <span style={{fontSize: 10}}>
                    Alter QR Settings <i>(optional)</i>
                </span>
                ),
            key: 'qr_settings_alteration',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.qr_settings_alteration}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'qr_settings_alteration', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: (
                <span style={{fontSize: 10}}>
                    Create Payment Links <i>(optional)</i>
                </span>
                ),
            key: 'payment_link_creation',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.payment_link_creation}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'payment_link_creation', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Multi-wallet",
            key: 'multiwallet_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.multiwallet_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'multiwallet_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
    ];

    const TransactionCards = (item) => {
        const data = item && item.data
        const total = data && data.length;
        const [showContent, setShowContent] = React.useState(false);

        const handleMobileChange = (data, title, value) => {
            handleCheckChange(data, title, value);
        }

        console.log('LIST_DATA', showContent)
        return (
            <div>
                <a style={{...styles.flexBetween, padding: '16px 16px 0 16px'}} onClick={() => setShowContent(!showContent)}>
                    <div>
                        <Typography style={styles.fullName}>{data && data.full_name}</Typography>
                        <Typography style={styles.email}>{data && data.email}</Typography>
                    </div>
                    <Icon type={showContent? 'up' :'down'} className={`text-${segments}`} />
                </a>
                <Divider style={{margin: '16px auto 0 auto'}} />
                <div hidden={!showContent} style={{padding: '0 16px'}}>
                    <div style={styles.flexBetween}>
                        <Typography style={{...styles.fullName, padding: '6px 0'}}>Dashboard</Typography>
                        <Checkbox
                            checked={data.dashboard_access}
                            onChange={(e) => {handleMobileChange(data.alias_id, 'dashboard_access', e.target.checked); setShowContent(true)}}
                            disabled={data.has_merchant_bias}
                            />
                    </div>
                    <div style={styles.flexBetween}>
                        <Typography style={{...styles.fullName, padding: '6px 0'}}>Integration</Typography>
                        <Checkbox
                            checked={data.integration_access}
                            onChange={(e) => handleMobileChange(data.alias_id, 'integration_access', e.target.checked)}
                            disabled={data.has_merchant_bias}
                        />
                    </div>
                    <div style={styles.flexBetween}>
                        <Typography style={{...styles.fullName, padding: '6px 0'}}>Payment Request</Typography>
                        <Checkbox
                            checked={data.payment_request_access}
                            onChange={(e) => handleMobileChange(data.alias_id, 'payment_request_access', e.target.checked)}
                            disabled={data.has_merchant_bias}
                        />
                    </div>
                    <div style={styles.flexBetween}>
                        <Typography style={{...styles.fullName, padding: '6px 0'}}>Payout</Typography>
                        <Checkbox
                            checked={data.payout_access}
                            onChange={(e) => handleMobileChange(data.alias_id, 'payout_access', e.target.checked)}
                            disabled={data.has_merchant_bias}
                        />
                    </div> 
                    <div style={styles.flexBetween}>
                        <Typography style={{...styles.fullName, padding: '6px 0'}}>Reconciliation</Typography>
                        <Checkbox
                            checked={data.reconciliation_access}
                            onChange={(e) => handleMobileChange(data.alias_id, 'reconciliation_access', e.target.checked)}
                            disabled={data.has_merchant_bias}
                        />
                    </div>
                    <div style={styles.flexBetween}>
                        <Typography style={{...styles.fullName, padding: '6px 0'}}>Settings</Typography>
                        <Checkbox
                            checked={data.settings_access}
                            onChange={(e) => handleMobileChange(data.alias_id, 'settings_access', e.target.checked)}
                            disabled={data.has_merchant_bias}
                        />
                    </div>
                    <div style={styles.flexBetween}>
                        <Typography style={{...styles.fullName, padding: '6px 0'}}>Test Mode <i>(optional)</i></Typography>
                        <Checkbox
                            checked={data.test_mode_access}
                            onChange={(e) => handleMobileChange(data.alias_id, 'test_mode_access', e.target.checked)}
                            disabled={data.has_merchant_bias}
                        />
                    </div>
                    <div style={styles.flexBetween}>
                        <Typography style={{...styles.fullName, padding: '6px 0'}}>Settlement Reports <i>(optional)</i></Typography>
                        <Checkbox
                            checked={data.settlement_reports_visibility}
                            onChange={(e) => handleMobileChange(data.alias_id, 'settlement_reports_visibility', e.target.checked)}
                            disabled={data.has_merchant_bias}
                        />
                    </div>
                    <div style={styles.flexBetween}>
                        <Typography style={{...styles.fullName, padding: '6px 0'}}>Alter QR Settings <i>(optional)</i></Typography>
                        <Checkbox
                            checked={data.qr_settings_alteration}
                            onChange={(e) => handleMobileChange(data.alias_id, 'qr_settings_alteration', e.target.checked)}
                            disabled={data.has_merchant_bias}
                        />
                    </div>
                    <div style={styles.flexBetween}>
                        <Typography style={{...styles.fullName, padding: '6px 0'}}>Create Payment Links <i>(optional)</i></Typography>
                        <Checkbox
                            checked={data.payment_link_creation}
                            onChange={(e) => handleMobileChange(data.alias_id, 'payment_link_creation', e.target.checked)}
                            disabled={data.has_merchant_bias}
                        />
                    </div>
                    <Divider style={{margin: '8px auto'}} />
                </div>
            </div>
        )
    }

    const MobileView = () => {
        return (
            <Card
                style={{padding: '0px'}}
                bodyStyle={{padding: 0 }}
            >
                <List
                // loading={!data}
                    dataSource={aliasPermissions && aliasPermissions.length > 0 ? renderData(searchVal) : []}
                    renderItem={item =>
                        <div>
                            <TransactionCards data={{ ...item }} />
                        </div>
                    }
                />
            </Card>
        )
    }



    const getUserAccessData = async () => {
        setLoading(true);
        try {
            const res = await accountSettingsServices.getUserAccessData(pUid === '' ? null : pUid);
            setUserData(res);

        } catch (error) {

        }
        setLoading(false);
    }

    const renderData = (search) => {
        let filteredData = [];

        if(search !== '' && search !== null) {
            filteredData =  aliasPermissions && aliasPermissions.slice(minPage, maxPage).filter((data) => data.email.toLowerCase().includes(search.toLowerCase()) ||
                data.full_name.toLowerCase().includes(search.toLowerCase()))
            return filteredData && filteredData;
        } 
        
        return aliasPermissions && aliasPermissions.slice(minPage, maxPage);
    }

    const changePage = (page, pageSize) => {
        setMaxPage(page * pageSize)
        setMinPage((page - 1) * pageSize)
        setCurrentPage(page)
      }

    console.log('ALIAS', aliasPermissions);
    console.log('ALIAS', renderData(searchVal));


    useEffect(() => {
        // if (! isPermissionsModalVisible) {
        //     return;
        // }

        loadAliasPermissions();
    }, []);


    return(
        <div>{
        isMobile?
        <div style={{marginBottom: '214px'}}>
            <div style={{...styles.headerText, backgroundColor: '#FFF', width: '100%', paddingLeft: '24px'}}>
                <a style={{ textDecoration: 'none', display: 'flex', alignItems: 'center'}} onClick={() => history.goBack()} className={`link-text--${segments}`}>
                    <Icon type="arrow-left" className={`text-${segments}`} style={{fontSize: '32px' }} />
                </a>
                Edit Permissions
            </div>
            <div style={{padding: '8px 16px', width: '100%'}}>
                <Input
                    placeholder="Search"
                    style={{...styles.inputSearch, width: '100%'}}
                    suffix={<Icon type="search" style={{ fontSize: '18px', marginRight: 6 }} />}
                    onChange={(e) => setSearchVal(e.currentTarget.value)}
                    value={searchVal}
                />
            </div>
            <MobileView />
            <div style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%', marginTop: '12px'}}>
                <button className={`outline-btn--${segments}`} style={{border: '1px solid #2B2D32', color: '#2B2D32', height: '45px'}}
                    onClick={() => changePage(currentPage, 10)}
                >
                    Previous
                </button>
                <Typography style={{color: '#2F3542', fontSize: '14px', fontWeight: '400'}}>
                    {currentPage} - {renderData(searchVal).slice(minPage, maxPage).length} out of {aliasPermissions && aliasPermissions.length}
                </Typography>
                <button className={`btn--${segments}`} style={{height: '45px'}} onClick={() => changePage(currentPage, 10)}>
                    Next
                </button>
            </div>
            <div style={{...styles.flexBetween, width: '100%', padding: '20px 12px', backgroundColor: '#FFF', position: 'fixed', bottom: '75px'}}>
                <button
                    className={`outline-btn--${segments}`}
                    onClick={() => {
                        history.goBack();
                    }}
                    style={{width: '45%', margin: '0 8px', height: '45px'}}
                >
                    Cancel
                </button>
                <button
                    className={`btn--${segments}`}
                    onClick={() => {
                        checkPermissionsBeforeSaving();
                        message.success("Settings saved.", 2);
                    }}
                    style={{width: '45%', borderRadius: '4px', height: '45px' }}
                >
                    Save
                </button>
            </div>
        </div>
        :
        <Card
            centered
            // width={'100%'}
            visible={isPermissionsModalVisible}
            onCancel={() => {
                setIsPermissionsModalVisible(false);
            }}
            style={{marginTop: '102px', maxWidth: isMobile? '100%' : '80vw', marginBottom: '52px' }}
            // footer={null}
        > 
       <div style={styles.flexBetween}>
            <div style={styles.headerText}>
                <a style={{ textDecoration: 'none'}} onClick={() => history.goBack()} className={`link-text--${segments}`}>
                    <Icon type="arrow-left" style={{fontSize: '32px', color: '#0A315E' }} />
                </a>
                Edit Permissions
            </div>
            <Input
                placeholder="Search"
                style={styles.inputSearch}
                suffix={<Icon type="search" style={{ fontSize: '18px', marginRight: 6 }} />}
                onChange={(e) => setSearchVal(e.target.value)}
                value={searchVal}
            />
       </div>
            <div>
                <div>
                    <Table
                        style={styles.table}
                        size="middle"
                        pagination={false}
                        dataSource={renderData(searchVal)}
                        columns={permissionsColumns}
                        rowKey={(alias) => alias.id}
                        loading={isTableLoading}
                        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                        scroll={{x: '1300'}}
                        width={532}
                    />
                    <Pagination
                        size='small'
                        total={(aliasPermissions && aliasPermissions.length) || 0}
                        defaultPageSize={10}
                        pageSize={10}
                        defaultCurrent={1}
                        current={currentPage || 1}
                        onChange={(page, pageSize) => changePage(page, pageSize)}
                        style={{ padding: '4px 0 24px 0', textAlign: 'right' }}
                    />
                </div>

                <div style={styles.buttonsDiv}>
                    <button
                        className={`outline-btn--${segments}`}
                        onClick={() => {
                            history.goBack();
                        }}
                        style={{width: '', margin: '0 8px'}}
                    >
                        Cancel
                    </button>
                    <button
                        className={`btn--${segments}`}
                        onClick={() => {
                            checkPermissionsBeforeSaving();
                            message.success("Settings saved.", 2);
                        }}
                        style={{width: '82px', borderRadius: '4px' }}
                    >
                        Save
                    </button>
                </div>
            </div>
    </Card>}
    </div>
    )
}

const styles = {
    buttonsDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    headerText: {
        fontSize: 'large',
        display: 'inline-flex',
        alignItems: 'center',
        gap: '10px',
        fontWeight: '700',
        color: '#2B2D32',
        padding: '16px 0'
    },
    footerText: {
        fontSize: 'medium',
        textAlign: 'right',
        fontStyle: 'italic',
        paddingRight: '5px'
    },
    table: {
        margin: '10px 0px 30px 0px',
        backgroundColor: '#CFD3D9'
    },
    inactive: {
        width: "60px",
        margin: '0px 6px',
        backgroundColor: 'rgb(245, 245, 245)',
        border: '1px solid rgb(192, 192, 192)',
        fontSize: "x-small",
        padding: "2px"
    },
    flexBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    inputSearch: {
        height: '40px',
        borderRadius: '4px',
        padding: '0 8px 0 0px',
        width: '24%'
    },
    fullName: {
        color: '#2B2D32',
        fontSize: '16px',
        fontWeight: '500'
    },
    email: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: '14px'
    }
}

export default EditPermission;