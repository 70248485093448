import { useEffect, useState } from "react";
import { axios } from "../../../../../../lib/axios";

export function getRemittances(currentPage, searchQuery, startDate, endDate, email, isExport) {

  return axios.get(
    `api/remittances/?page=${currentPage}&search=${searchQuery}&start=${startDate}&end=${endDate}&email=${email}&is_export=${isExport}`,
  );
}

export function useRemittances(currentPage, searchQuery, startDate, endDate) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getRemittances(
          currentPage,
          searchQuery,
          startDate,
          endDate,
        );
        setData(response);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, [currentPage, searchQuery, startDate, endDate]);

  return { data, isLoading, error };
}
