import { MOBILE } from '../../constants/constants'

export const METHOD = [
    {
        code: 'OTC',
        name: 'Over-the-counter'
    },
    {
        code: 'WB',
        name: 'Online Banking',
    },
    {
        code: 'EW',
        name: 'E-Wallet',
    },
    {
        code: 'CARD',
        name: 'Credit/Debit Card'
    },
    {
        code: 'BNPL',
        name: 'Buy Now Pay Later'
    },
	// {
	// 	code: 'SNPL',
	// 	name: 'Study Now Pay Later'
	// }
]

export const OTC = [
    {
        name: '7-Eleven',
		code: 'ecpay_711', // change from 711_direct to ecpay_711
	},
	{
        name: 'Bayad Center',
		code: 'bayad_center',
	},
	{
        name: 'Cebuana Lhuillier' ,
		code: 'CEBL'
	},
	{
        name: 'ECPay',
		code: 'ECPY'
	},
	// {
    //     name: 'LBC',
	// 	code: 'LBC'
	// },
	{
        name: 'MLhuillier',
		code:'MLH',
	},
	{
        name: 'SM / Supermarket / Savemore',
		code:'SMR'
	},
	{
        name: 'Robinsons Dept Store',
		code:'RDS'
	},
	{
        name: 'Palawan Pawnshop',
		code:'PLWN'
	},
	{
        name: 'RD Pawnshop',
		code:'RDP'
	},
	{
        name: 'RuralNet',
		code:'bc_rlnt'
	},
	{
        name: 'DA5',
		code:'da5'
	},
	{
        name: 'Pay&Go',
		code:'payngo'
	},
	{
        name: 'Posible',
		code:'posible'
	},
	{
        name: 'USSC',
		code: 'bc_ussc'
	},
	{
        name: 'Tambunting',
		code: 'tmbntng'
	},
	// {
	// 	name: 'i2i',
	// 	code: 'i2i'
	// },
	{
     name: 'Villarica',
		code: 'villarica'
	},
	{
		name: 'Petnet, Inc',
		code: 'petnet'
	},
	{
		name: 'i2i',
		code: 'i2i'
	}
]

export const WB = [
	{
        name: 'UnionBank Internet Banking',
		code: 'UBPB'
	},
	{
        name: 'BPI Online/Mobile',
		code: 'BPIA'
	},
	{
        name: 'RCBC Online Banking',
		code: 'RCBC'
	},
	{
        name: 'Metrobank Online Banking',
		code: 'MBTC'
	},
	{
        name: 'BDO via Instapay (1 business day)',
		code: 'bdo_instapay'
	},
	{
        name: 'PS Bank via Instapay (1 business day)',
		code: 'ps_instapay'
	},
	{
        name: 'Security Bank via Instapay (1 business day)',
		code: 'sb_instapay'
	},
	{
        name: 'Other Banks via Instapay (1 business day)',
		code: 'instapay'
	},
	{
		name: 'Other Banks via Instapay (1 business day)',
		code: 'instapay_allbank'
	},
	{
        name: 'Other Banks via PCHC Paygate (1-2 business days)',
		code: 'paygate'
	}
]

export const Web = [
	{
        name: 'UnionBank Internet Banking',
		code: 'UBPB'
	},
	{
        name: 'BPI Online/Mobile',
		code: 'BPIA'
	},
	{
        name: 'RCBC Online Banking',
		code: 'RCBC'
	},
	// {
    //     name: 'BDO Internet Banking',
	// 	code: 'BDO'
	// },
	{
        name: 'Metrobank Online Banking',
		code: 'MBTC'
	},
]

export const Ewallet = [
	{
        name: 'GCash',
        code: 'gcash',
    },
    {
        name: 'GrabPay',
        code: 'grabpay'
    },
]

export const EW = [
	{
		name: 'GCash',
		code: 'gcash',
	},
	{
		name: 'GrabPay',
		code: 'grabpay'
	},
	{
		name: 'Maya',
		code: MOBILE.Maya.code
	},
	{
		name: 'Other E-Wallets via Instapay (1 business day)',
		code: 'instapay'
	},
	{
		name: 'Other E-Wallets via Instapay (1 business day)',
		code: 'instapay_allbank'
	},
]

export const CARD = [
    {
        name: 'Visa / MasterCard',
        code: 'visamc'
    },
	{
		name: 'VISA/MASTERCARD',
		code: 'ubpcs'
	}
]

export const BNPL = [
    {
        name: 'BillEase',
        code: 'billease'
    }
]

// export const SNPL = [
// 	{
//         name: 'Billease',
//         code: 'billease_sn'
//     }
// ]
