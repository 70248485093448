import React, {
    useEffect,
    useState
} from 'react';
import axios from 'axios';
import { message, Spin } from 'antd';
import crypto from 'crypto';
import { history } from '../../../store/history'
import MetaTag from '../../meta_tag/meta_tag';
import * as Icons from '../../../static/icons/bux_qr/index'
import { OTC } from './channel_const'

//Components
import DisclaimerModal from './disclaimerModal'
import InstaPayModalDisclaimer from '../../checkout/NewCheckout/instapayDisclaimerModal';

//QR Components
import QRBusinessName from './qr_details/qr_business_name';
import QRAmount from './qr_details/qr_amount';
import QRCustomerDetails from './qr_details/qr_customer_details';
import TestModeBanner from '../../test_mode/components/testModeBanner';

//
import qrServices from './qr_services/qrServices';
import promotionServices from '../../AdminComponents/promotion/promotionServices';
import QRSummary from './qr_details/qr_summary';
import QRFooter from './qr_details/qr_footer';

import PageNotFound from '../../404/404'

let latestToken;
const testMode = (history.location.pathname.indexOf('/test') > -1);

const QrDetails = props => {

    //props
    const isSubDomain = process.env.REACT_APP_ROOT_URL != window.location.hostname;

    let trade_name = props.match.params.trade_name;
    let isSub = false;

    if (isSubDomain && trade_name) {
        isSub = true;
    }

    if (isSubDomain && !trade_name) {
        let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
        trade_name = window.location.hostname.replace(remove_part, "");
        // trade_name = props.match.params.trade_name;
    }
    let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
    const master_trade_name = isSubDomain && isSub ? window.location.hostname.replace(remove_part, "") : '';

    const REACT_APP_API_URL = process.env.REACT_APP_API_URL

    const channels = ["MBTC", "BPIA", "UBPB", "RCBC", "gcash", "grabpay", "visamc", "ubpcs", "billease", "billease_sn", "paygate", "bdo_instapay", "ps_instapay", "sb_instapay", "instapay"];
    const instapay_channels = ["bdo_instapay", "ps_instapay", "sb_instapay"];
    const no_disclaimer_channels = ['instapay', 'paygate', 'instapay_allbank', 'bdo_instapay', 'ps_instapay', 'sb_instapay'];

    const banks = [
        { 'surcharge': '10', 'ac': 'UBPB', 'name': 'UnionBank Internet Banking' },
        { 'surcharge': '15', 'ac': 'BPIA', 'name': 'Bank of the Philippine Islands' },
        { 'surcharge': '5', 'ac': 'RCBC', 'name': 'RCBC Online Banking' },
        { 'surcharge': '5', 'ac': 'MBTC', 'name': 'Metrobank Online Banking' },

    ]

    //state data
    const [data, setData] = React.useState();
    const [disabledChannels, setDisabledChannels] = useState([])
    const [fees, setFees] = useState()
    const [businessName, setBusinessName] = useState("");
    const [personalMsg, setPersonalMsg] = useState("");
    const [userRole, setUserRole] = React.useState("CO");
    const [amount, setAmount] = useState(parseFloat(0).toFixed(2))
    const [paymentType, setPaymentType] = useState(['OTC', 'Over-the-counter'])
    const [paymentChannel, setPaymentChannel] = useState(['', ''])
    const [sur, setSur] = React.useState()
    const [leaveNote, setLeaveNote] = useState('')

    const [custName, setCustName] = useState('')
    const [custEmail, setCustEmail] = useState('')
    const [custMobile, setCustMobile] = useState('')
    const [additional, setAdditional] = useState('')
    const [additional2, setAdditional2] = useState('')

    // require addistional
    const [requireAdd1, setRequireAdd1] = useState(false);
    const [requireAdd2, setRequireAdd2] = useState(false);
    const [hasClicked, setHasClicked] = useState(false);

    //
    const [otcCount, setOtcCount] = useState(0);

    //state boolean
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [instapayDisclaimerModal, setInstapayDisclaimerModal] = useState(false);
    const [pageNotFound, setPageNotFound] = React.useState(false);
    const [qrPage, setQRPage] = React.useState(false);
    const [loading, setLoading] = useState(false)
    const [feeLoading, setFeeLoading] = useState(true)

    // promo code
    const [promoCode, setPromoCode] = React.useState('');
    const [promoCodeStatus, setPromoCodeStatus] = React.useState([]);
    const [promoAmount, setPromoAmount] = React.useState(0);
    const [promoType, setPromoType] = React.useState('Fixed');
    const [promoMinAmount, setPromoMinAmount] = React.useState(50);
    const [promoMaxAmount, setPromoMaxAmount] = React.useState(20);

    const [formattedAmount, setFormattedAmount] = React.useState("")
    //functions 

    const errorMsg = (text) => {
        message.destroy()
        message.error(text, 3)
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        setLoading(false)

    }

    const headerDetails = (text) => {
        return (
            <div className="qr-header-details">
                {text}
            </div>
        )
    }

    const customPlaceholder = (text) => {
        return (
            <div className="qr-placeholder">
                {text}
            </div>
        )
    }

    const selectedAmount = (amount) => {
        setAmount(amount)
        getFees(amount)
    }

    const numberWithCommax = (text) => {
        return text.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    const settingAmount = (val) => {
        setAmount(val)
        validatePromoCode(promoCode, data && data.segment, '', val)
        // if (val.length <= 9 && val <= 999999.99) {
        //     let splitted_val = val.split(".")
        //     if (splitted_val.length == 1 || (splitted_val.length == 2 && splitted_val[1].length <= 2)) {
        //         setAmount(val)
        //     }
        // }
        // if (val.length <= 9 && val <= 999999.99) {
        //     setAmount(val)
        // }
    }

    const handlePaymentChange = (e) => {
        getFees()
        setPaymentType(e)
        setPaymentChannel(['', '', ''])
        validatePromoCode(promoCode, data && data.segment, '', amount)
    }

    const handleChannelChange = (e) => {
        console.log(e)
        setPaymentChannel(e)
        if (['BPIA', 'RCBC', 'UBPB', 'MBTC'].includes(e[0])) {
            setSur(banks[e[2]].surcharge)
        }
        validatePromoCode(promoCode, data && data.segment, e[0], amount)

    }

    const onPromoCodeChange = (e) => {
        if (e.target.value.length === 0) {
            setPromoCode('');
            setPromoCodeStatus([]);
            setPromoAmount(0);
        } else {
            setPromoCode((e.target.value).toUpperCase());
        }
    }

    const onApplyPromo = (code) => {
        let promo_code = promoCode;
        if (code && (typeof code !== 'object')) {
            setPromoCode((code).toUpperCase());
            promo_code = code;
        }
        validatePromoCode(promo_code, data && data.segment, paymentChannel[0], amount);
    }

    //async functions

    const checkoutQr = async (params) => {
        try {
            const response = params.channel === "instapay" ? await qrServices.checkoutQRV2(testMode, params) : await qrServices.checkoutQR(testMode, params)

            console.log(response.data)
            if (response.data.status == "success") {
                if (channels.includes(paymentChannel[0]) && !showDisclaimer) {
                    setLoading(false)
                }
                if (response.data.banking_url) {
                    window.location.href = response.data.banking_url;
                }
                else {
                    testMode ? window.location.href = `/test/payment/${response.data.uid}` :
                        window.location.href = `/payment/${response.data.uid}`

                }
                setLoading(false)
            }

            else {
                errorMsg(response.data.message)
                setLoading(false)
                setInstapayDisclaimerModal(false)

            }
        }
        catch (error) {
            message.destroy()
            message.error(error)
            console.log(error)
        }
    }

    const gettingParams = async () => {
        let params = {}
        let pmethod = paymentChannel[0];

        setLoading(true)
        setShowDisclaimer(false)
        setHasClicked(true);

        const maxAmount = process.env.REACT_APP_MAXIMUM_AMOUNT ? process.env.REACT_APP_MAXIMUM_AMOUNT : 30000
        const minAmount = process.env.REACT_APP_MINIMUM_AMOUNT ? process.env.REACT_APP_MINIMUM_AMOUNT : 50

        if (!amount) {
            errorMsg("Amount is required", 3)
        }

        else if ((data && data.user_role === "ME") && (parseFloat(amount) < minAmount || parseFloat(amount) > maxAmount)) {
            errorMsg('Amount must be ₱ 50 up to ₱ 30, 000')
        }

        else if (data && data.user_role == "CO" && parseFloat(amount) < minAmount) {
            errorMsg('Amount must be ₱ 50 up to ₱ 80, 000')

        }

        else if (!custEmail || !custEmail || !custMobile) {
            errorMsg("Please complete Customer Information", 3)
        }

        else if (requireAdd1 || requireAdd2) {
            errorMsg("Please complete Additional Details", 3)
        }

        else if (custMobile.length < 10) {
            errorMsg("Invalid Mobile Number", 3)
        }

        else if (!pmethod) {
            errorMsg("Please Select a Channel")
        }

        else if(pmethod == 'bank') {
            errorMsg("Please Select a Bank")
        }

        else if(emailError(custEmail)) {
            errorMsg("Invalid Email")
        }


        else {
            // set Instapay to the enabled channel
            if(instapay_channels.includes(pmethod)){
                pmethod = data.instapay_payment_channel;
            }

            params = {
                trade_name,
                amount: parseFloat(amount).toFixed(2),
                name: custName,
                phone: custMobile,
                email: custEmail,
                channel: pmethod,
                note: !leaveNote ? custMobile : leaveNote,
                additional_field_1: {
                    name: data.field_1,
                    value: additional,
                },
                additional_field_2: {
                    name: data.field_2,
                    value: additional2,
                },
                test_mode: testMode,
                promo_code: promoCode
            }

            checkoutQr(params)

        }

    }

    const emailError = (email) => {
        const emailFormat = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        if (!email || !email.length) { 
            return false;
        }
        if(emailFormat.test(email)){
            return(false);
        }
        else{
            return(true);
        }
    }

    function goToSocials(text) {
        if (text === 'fb') {
            window.open(`https://www.facebook.com/${data.fb}`, '_blank', 'noopener')
        }

        else if (text === 'ig') {
            window.open(`https://www.instagram.com/${data.ig}`, '_blank', 'noopener')
        }

        else if (text === 'web') {
            window.open(`${data.web}`, '_blank', 'noopener')
        }
    }

    const removeSpace = (value) => value == null ? null : value.trim();
    
    const validatePromoCode = async (code, segment, channel, amount) => {
        if (!code) {
            return false;
        }

        const payment_link_amount = fees && fees[paymentChannel[0]] ? parseFloat(fees[paymentChannel[0]]) + parseFloat(amount) : amount;

        try {
            const res = await promotionServices.validatePromoCode(code, segment, channel, payment_link_amount);
            if (res.status === 'success') {
                setPromoAmount(res.discount_amount);
                setPromoType(res.discount_type);
                setPromoCodeStatus({
                    status: 'valid',
                    message: res.message
                });
                setPromoMinAmount(res.min_amount);
                if (res && res.max_amount) {
                    setPromoMaxAmount(res.max_amount);
                }
            } else if (res.status === 'error') {
                setPromoAmount(0);
                setPromoType('');
                setPromoCodeStatus({
                    status: 'invalid',
                    message: res.message
                });
                setPromoMinAmount(50);
                setPromoMaxAmount(20);
            }
        } catch (error) {
            setPromoAmount(0);
            setPromoType('');
            setPromoCodeStatus({
                status: 'invalid',
                message: 'Error please try again'
            });
            setPromoMinAmount(50);
            setPromoMaxAmount(20);
        }
    }

    const getDetails = async () => {
        try {
            const response = await qrServices.getQrDetails(testMode, trade_name, master_trade_name)

            if (response.data.status === 'failed') {
                message.destroy()
                setPageNotFound(true)
            }

            else {
                console.log(response.data)
                if (response.data.qr_enabled) {
                    if (response.data.user_role == 'ME' && testMode) {
                        setPageNotFound(true)
                    }
                    else {
                        setQRPage(true)
                        setData(response.data)
                        setBusinessName(response.data.business_name)
                        setPersonalMsg(response.data.personal_message)
                        setUserRole(response.data.user_role);
                        setDisabledChannels(response.data.disabled_channels);
                    }

                }

                else {
                    setPageNotFound(true)
                }

            }
        }

        catch (error) {
            console.log(error)
        }
    }

    const getFees = async (toPaid) => {
        let forChecking = toPaid ? toPaid : amount
        // Ignore checking if amount is equals to initial state of 0.00
        // The change is made to prevent the error message appearing on fetching fees on page load
        if (forChecking < 50 && forChecking !== "0.00") {
            errorMsg("Amount must be at least ₱ 50.00", 3)
        }

        let generatedRequestToken = crypto.randomBytes(16).toString('hex');
        latestToken = generatedRequestToken
        let params = {
            trade_name,
            amount: toPaid ? toPaid : amount,
            token: generatedRequestToken,
        }

        try {

            let url = testMode ? REACT_APP_API_URL + `/api/sandbox/get_channel_fee/` : REACT_APP_API_URL + `/api/get_channel_fee/`;

            let response = await axios.post(url, params)
            console.log(response.data)

            if (generatedRequestToken == response.data.token) {
                setDisabledChannels(response.data.disabled_channels)
                setFees(response.data.fees)
                let count = 0;
                for (let i in OTC) {
                    if (response.data.disabled_channels.includes(OTC[i].code)) {
                        count++
                    }
                }

                if (count === 16 && paymentType[0] === 'OTC') {
                    setPaymentType(['WB', 'Web Banking'])
                }

                setOtcCount(count)
            }

            else {
                message.destroy()
                message.error(response.data.status)
            }
        }

        catch (error) {
            console.log("Failed to fetch Fees")
        }
        setFeeLoading(false);
    }

    useEffect(() => {
        if((!additional || additional === "") && data && data.field_1_required && data.show_field_1) {
            setRequireAdd1(true);
        } else {
            setRequireAdd1(false);
        }
        if((!additional2 || additional2 === "") && data && data.field_2_required && data.show_field_2) {
            setRequireAdd2(true);
        } else {
            setRequireAdd2(false);
        }
    },[additional, additional2, data])

    useEffect(() => {
        getDetails();
        // Fetch fees after component loads to get the updated list of disabled channels
        // The change is made to get updated disabled channels w/o waiting for change of payment method trigger
        getFees();
    }, [otcCount])

    return (
        <div>
            <div hidden={!pageNotFound}>
                <PageNotFound />
            </div>
            <div className="qr-main-screen" align="center" hidden={!qrPage}>
                {trade_name ?
                    <MetaTag title={trade_name + "'s Checkout"} />
                    :
                    <MetaTag title="Not Found" />
                }

                <DisclaimerModal hasDisclaimer={channels} showModal={showDisclaimer} hideModal={setShowDisclaimer} channel={paymentChannel[0]} proceed={gettingParams} />
                <InstaPayModalDisclaimer modalVisibility={instapayDisclaimerModal} setModalVisibility={setInstapayDisclaimerModal} channel={paymentChannel[0]} proceed={gettingParams} />

                <div className="qr-details-div padding-bot-32">
                    <div className="qr-header-div">
                        <img src={Icons.BUx} alt="BUx" />
                    </div>

                    {testMode && <TestModeBanner width={'100%'} type="QR" />}

                    <div className="qr-info">

                        <QRBusinessName data={data} businessName={businessName} profile={Icons.Profile} personalMessage={personalMsg} />
                        <QRAmount data={data} getFees={getFees} amount={amount} settingAmount={settingAmount}
                            customPlaceholder={customPlaceholder} selectedAmount={selectedAmount}
                            numberWithCommax={numberWithCommax} formattedAmount={formattedAmount} />
                        <QRCustomerDetails
                            data={data}
                            headerDetails={headerDetails} customPlaceholder={customPlaceholder}
                            custName={custName} setCustName={setCustName}
                            custEmail={custEmail} setCustEmail={setCustEmail}
                            custMobile={custMobile} setCustMobile={setCustMobile}
                            additional={additional} setAdditional={(text) => {setAdditional(text); setHasClicked(true)}}
                            additional2={additional2} setAdditional2={(text) => {setAdditional2(text); setHasClicked(true)}}
                            paymentType={paymentType} handlePaymentChange={handlePaymentChange}
                            testMode={testMode} disabledChannels={disabledChannels || []}
                            paymentChannel={paymentChannel} amount={amount}
                            handleChannelChange={handleChannelChange}
                            leaveNote={leaveNote} setLeaveNote={setLeaveNote} Icons={Icons}
                            requireAdd1={requireAdd1} requireAdd2={requireAdd2} hasClicked={hasClicked} />

                        <QRSummary headerDetails={headerDetails} paymentType={paymentType}
                            paymentChannel={paymentChannel} sur={sur}
                            amount={amount} numberWithCommax={numberWithCommax}
                            data={data} fees={fees} promoAmount={promoAmount}
                            userRole={userRole} onPromoCodeChange={onPromoCodeChange}
                            onApplyPromo={onApplyPromo} promoCodeStatus={promoCodeStatus}
                            promoCode={promoCode} testMode={testMode} promoType={promoType}
                            promoMinAmount={promoMinAmount} promoMaxAmount={promoMaxAmount} />


                        <button className="submit-btn-bux top-20"
                            onClick={() => {
                                if (no_disclaimer_channels.includes(paymentChannel[0])) {
                                    gettingParams()
                                } else if (channels.includes(paymentChannel[0])) {
                                    setShowDisclaimer(true)
                                }
                                else {
                                    gettingParams()
                                }
                            }}
                            disabled={loading}
                            style={{ opacity: loading ? 0.5 : '', cursor: loading ? 'not-allowed' : '' }}>
                            <div hidden={!loading}>
                                <Spin />
                            </div>
                            <div hidden={loading}>
                                Pay Now
                            </div>
                        </button>


                        <div className="nameStyle top-20">
                            By clicking you agree to our <a a href="/terms_and_privacy" target="_blank" style={{ color: '#f5922f', textDecoration: 'underline' }}>Terms & Conditions</a> and <a target="_blank" href="/terms_and_privacy" style={{ color: '#f5922f', textDecoration: 'underline' }}>Privacy Policy</a>,
                            entailing that BUx is not responsible for nor does it ensure the delivery,
                            performance or fulfillment of the goods or service that you are paying for.
                            BUx only ensures that your payment is processed seamlessly and safely.
                        </div>
                    </div>

                    <QRFooter data={data} trade_name={trade_name} />

                </div>
            </div>
        </div>
    )
}

export default QrDetails; 
