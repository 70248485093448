import axios from 'axios'
import React, {useEffect} from 'react'
import {message, Spin, Card} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import ProgressCard from './progress_card'
import {isMobile} from 'react-device-detect'
import BusinessInfo from './application_form/business_info'
import ContactInfo from './application_form/contact_info'
import BusinessDocument from './application_form/business_docu'
import SignatoryInformation from './application_form/signatory_info'
import MoaNda from './application_form/moa_nda'
import BankAccount from './application_form/bank_account'
import ReviewCard from './mainReview'
import kybStyle from './kybStyle'
import { history } from '../../../store/history'
// import {restartTimer} from '../../timer/logout_timer'
import {backToTop} from '../../constants/constants'
import {Handle401} from '../../handle401/handle401'
import '../../../App.scss';


const MainKyb = props => {

    let kybStep = localStorage.getItem("KYBStep")
    let user = localStorage.getItem("userType")
    let segments = localStorage.getItem("segments")
    let subuserRole = localStorage.getItem("subuser_role")

    const kyb = kybStyle();
    const adminRole = ['SD', 'AD', 'CO', 'SW', 'BS']
    const isChildAcct = (localStorage.getItem('userType') == "CO" && localStorage.getItem("isAlias") == "true")

    useEffect(() => {
 
        if(!adminRole.includes(user)){
            history.push('/overview')
        }

        else if(user == "ME"){
            history.push('/dashboard')
        }

        else if(user == "SW" && subuserRole == "ME"){
            history.push('/complete_profile')
        }

        else if((user == 'SD' || user == 'AD' || user == 'BS') && localStorage.getItem('KYBType') != 'creation'){
            history.push('/submissions_kyb/')
        }

        else if(isChildAcct) {
            history.push('/dashboard')
        }
        
        else{
            getData()
        }


        
    },[])



    const [childData, setChildData] = React.useState()
    const [doneFetch, setDoneFetch] = React.useState(false)
    const [alertMessage, setAlertMessage] = React.useState("")
    const [showAlert, setShowAlert] = React.useState(true)
    const [step, setStep] = React.useState(user == "AD" || user == "SD" || user == "BS" ? 0 : null)
    const [bt, setBT] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [failedStep1, setFailedStep1] = React.useState()
    const [failedStep2, setFailedStep2] = React.useState()
    const [failedStep3, setFailedStep3] = React.useState()
    const [failedStep4, setFailedStep4] = React.useState()
    const [failedStep5, setFailedStep5] = React.useState()
    const [failedStep6, setFailedStep6] = React.useState()
    const [offSpin, setOffSpin] = React.useState(true)
    const [submissionId,setSubmissionId] = React.useState("")
    const [openModal, setOpenModal] = React.useState(false)
    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
    const isMac = window.screen.width * window.devicePixelRatio == 1440 && window.screen.height * window.devicePixelRatio == 900;
    const isSmallReso = window.screen.width * window.devicePixelRatio == 1331 && window.screen.height * window.devicePixelRatio == 669;
    const isDell = window.screen.width * window.devicePixelRatio == 1360 && window.screen.height * window.devicePixelRatio == 765;

    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }        

    const reviewCardsDivRefs = {
        'business_info_div_ref': React.createRef(),
        'contact_info_div_ref': React.createRef(),
        'business_document_div_ref': React.createRef(),
        'signatory_info_div_ref': React.createRef(),
        'moa_nda_div_ref': React.createRef(),
        'bank_account_div_ref': React.createRef(),
    };

    let alertDiv = <div hidden={showAlert} 
                        className="alert-message">
                        {alertMessage}
                    </div>
        
    async function getData(){
        try{
            let url;
            let enterpriseId = localStorage.getItem("enterpriseId");
            let userId = localStorage.getItem('userId')       

            if((user == "AD" || user == "SD" || user == "BS" ) && (enterpriseId != "" || enterpriseId != null) && localStorage.getItem("KYBType") == "creation") {
                url = process.env.REACT_APP_API_URL + `/api/kyb/${enterpriseId}/`;
            }

            else {
                url = process.env.REACT_APP_API_URL + `/api/kyb/${userId}/`;
            }

            let response = await axios.get(url,yourConfig)

            console.log(response.data)

            if(response.data.status == "failed"){
                setAlertMessage(response.data.message)
                setShowAlert(false)
                setLoading(!loading)
                backToTop()
            }

            else{
                setSubmissionId(response.data.id)
                setShowAlert(true)
                setDoneFetch(true)
                setChildData(response.data)
                setLoading(!loading)
                localStorage.setItem('KYBStep', response.data.step)
                localStorage.setItem('spocEmail', response.data.email)
                if(response.data.step > 7) {
                    localStorage.setItem('KYBType', 'created')
                }
                setStep(response.data.step)
                setBT(response.data.business_type)
                setFailedStep1(response.data.step_1_has_error)
                setFailedStep2(response.data.step_2_has_error)
                setFailedStep3(response.data.step_3_has_error)
                setFailedStep4(response.data.step_4_has_error)
                setFailedStep5(response.data.step_5_has_error)
                setFailedStep6(response.data.step_6_has_error)
            }
            
        }
        
        catch(error){
            setAlertMessage(error)
            setShowAlert(false)
            setTimeout(() => {
                setShowAlert(true)
            }, 5000)
            backToTop()
            console.log(error)
        }
    }

    async function submitStep(params, steps){
        // restartTimer()
        setOffSpin(false)
    
        try{
            let url = process.env.REACT_APP_API_URL + "/api/kyb/";
            let method;

            if((user == "AD" || user == "SD" || user == "BS") && steps == 1) {
               method = axios.post
            }

            else {
                method = axios.patch
            }

            if(steps === 1 || steps === 2 || steps === 6){
                params.submission_id = submissionId
            }

            else if(steps === 4){
                params.append("submission_id", submissionId)
            }

            let response = await method(url,params, yourConfig)

            console.log(response)
            //response
            if(response.data.status == "failed"){
                setShowAlert(false)
                backToTop()
                setOffSpin(true)
                if(step == 1 || step == 2) {
                    setAlertMessage(response.data.message);
                    setTimeout(() => {
                        setShowAlert(true)
                    }, 5000)

                }

                else {
                    message.error("Something Went Wrong", 3)
                }
            }

            else if(steps <= 6 ){
                if((user == "AD" || user == "SD" || user == "BS") && steps == 1) {
                    localStorage.setItem('enterpriseId', response.data.data.user)
                    localStorage.setItem('spocEmail', response.data.data.email)
                    // history.push(`/submissions_kyb/${response.data.data.user}/`)
                }     
                backToTop()
                setShowAlert(true)
                setOffSpin(true)
                setChildData(response.data.data)
                setSubmissionId(response.data.data.id)
                setStep(response.data.data.step)
                setBT(response.data.data.business_type)
                localStorage.setItem('KYBStep', response.data.data.step)
                if(response.data.data.step == 7){
                    window.location.reload()
                }
            }
        }
        
        catch(error){
            if((error.response)&&(error.response.status == 401)){
                Handle401()
            }
            else{
                console.log(error)
                setOffSpin(true)
                setShowAlert(false)
                backToTop()
                setAlertMessage(error.response.data.message);
                setTimeout(() => {
                    setShowAlert(true)
                }, 5000)
            }
        }

    }

    function backFunction() {
        localStorage.setItem('KYBType', '')
        localStorage.setItem('enterpriseId', 0)
        localStorage.setItem('spocEmail', '')
        history.push('/submissions_kyb/')
    }

    const footerNote = ""
        // <div hidden={kybStep > 6 || user !== "CO"} align="center" className="footer-note">
        //     You can edit and update your previous submissions on our <b>REVIEW PAGE</b> after completing this application
        // </div>

    return (
        <div className="top-48" style={{
            padding: isMobile ? '10px 16px 80px 16px' : 
                    kybStep >= 7 ? '16px 0px 20px 0px' : 
                    '20px 0px 20px 0px'
        }}>

            <div hidden={user == "CO" || user == 'SW'} style={{
                marginBottom: '34px',
                marginLeft: isSmallReso ? 90 : isDell ? 60 : isMac ? 110 : '325px'
            }}>
                <div>
                    <button style={{
                        background: '#FFFFFF',
                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.102751)',
                        borderRadius: '40px',
                        color: '#F5922F',
                        fontWeight: '600',
                        border: '1px solid #fff',
                        width: '96px',
                        height: '40px',
                        marginBottom: '12px',
                        cursor: 'pointer',
                        outline: 0,

                    }}
                    onClick={() => backFunction()}>
                        Back
                    </button>
                </div>

                <div style={{
                    fontSize: isSmallReso || isDell ? 24 : 28,
                    fontWeight: '600',
                    color: '#000000'
                }}>
                    Create Enterprise Account
                </div>
                <div style={{
                    fontSize: isSmallReso || isDell ? 12 : 16,
                    fontWeight: 'bold',
                    color: '#54575F'
                }}>
                    by Sales Admin
                </div>
            </div>
            
            <div style={{
                display: isMobile ? 'block' : 'flex', 
                justifyContent:'center', 
            }}>
                
                <div style={{display: isMobile ? 'block' : 'flex'}}>
                    <div hidden={kybStep == 8 || kybStep == 9} style={{fontSize: isMobile || isHighReso ? '16px' : '20px',
                        position: isMobile ? 'relative' : 'fixed', 
                        marginRight: isMobile ? '0px' : '270px !important',
                        marginTop: isMobile ? '20px': '40px',
                        display: isMobile && kybStep == 10 ? 'none' : 'block',
                        zIndex: isMobile ? 0 : 100,
                    }}>
                        <ProgressCard step={step}
                                        error1={failedStep1}
                                        error2={failedStep2}
                                        error3={failedStep3}
                                        error4={failedStep4}
                                        error5={failedStep5}
                                        error6={failedStep6}
                                        reviewCardsDivRefs={reviewCardsDivRefs}
                        />
                    </div>

                    <div style={{marginLeft: isMobile || kybStep == 8 || kybStep == 9 ? '0px' : 
                                isMac ? '25vw' : 
                                isHighReso ? '20vw' : 
                                '25vw', marginTop: isMobile ? '20px' : '0px'}}>
                        <div hidden={loading}>
                            <Card className={kyb.formCardStyle}>
                                <div align="center">
                                    <Spin size="large" />
                                </div>

                            </Card>
                        </div>
                        <div hidden={!loading}>
                            <div align="right" className="bottom-16" hidden={isMobile}>
                                <a href='/dashboard' className={`text-${segments} wc-header`}>
                                    Back to Dashboard
                                </a>
                            </div>
                        {
                            step == 0 ?
                            <BusinessInfo segments={segments}  submitStep={submitStep} offSpin={offSpin} datas={childData} alertMessage={alertDiv}/> : 
                            step == 1 ?
                            <ContactInfo segments={segments} footerNote={footerNote} offSpin={offSpin} submitStep={submitStep} alertMessage={alertDiv}/> :
                            step == 2 ?
                            <BusinessDocument segments={segments} footerNote={footerNote} offSpin={offSpin} submitStep={submitStep} alertMessage={alertDiv} businessType={bt} datas={childData} /> :
                            step == 3 ?
                            <SignatoryInformation segments={segments} footerNote={footerNote} offSpin={offSpin} submitStep={submitStep} alertMessage={alertDiv}/> :
                            step == 4 ?
                            <MoaNda segments={segments} footerNote={footerNote} offSpin={offSpin} submitStep={submitStep} alertMessage={alertDiv} datas={childData} /> :
                            step == 5 ?
                            <BankAccount segments={segments} footerNote={footerNote} offSpin={offSpin} submitStep={submitStep} alertMessage={alertDiv} datas={childData} /> : 
                            step >= 6 ?
                            <ReviewCard segments={segments} datas={childData} openModal={openModal} cardDivsRefs={reviewCardsDivRefs}/>
                            :''
                        }
                        </div>
                    
                    </div>
                </div>

            </div>
        </div>
        
    )
}

export default MainKyb;