import React from 'react';
import { BuxRoundBlue } from '../../../../../../static/icons/dashboard_icons/';
import { MasterWalletIcon, PayoutIconBalance, } from '../../../../../../static/images//multiwallet';
import { Button, Icon, Input, Modal, Typography } from 'antd';
import { history } from '../../../../../../store/history';

const TransferMoneyModal = (props) => {
    const [params, setParams] = React.useState({ amount: '' });
    const { segments, details, isLoading, buxBalance, onNext, reqMoney, reqMoneyDetails } = props;
    const userType = localStorage.getItem('userType');
    const balance = buxBalance && parseFloat(buxBalance);
    const testMode = (history.location.pathname.indexOf('/test') > -1);

    const handleChange = (event) => {
        setParams({
            mode: userType === 'SW' ? "to_master" : "to_sub",
            target_user_id: details && details.sub_id,
            target_user_email: details && details.email,
            amount: autoFormat(event.target.value)
        })
    }

    const autoFormat = (str) => {
        str = str.toString();
        if (str !== null && str.indexOf(".") > -1) {
            str = str.slice(0, (str.indexOf(".")) + 2 + 1);
            return parseFloat(str.replace(/[^\d\.]/g, ''));
        }
        return str;
    }

    const resetFields = () => {
        setParams({ amount: '' });
    }

    const insufficientBal = () => {
        if ((balance ? parseFloat(balance) : 0) < params['amount']) {
            return true;
        }
        return false;
    }

    React.useEffect(() => {
        resetFields();
    }, [props.visible])

    React.useEffect(() => {
        if (reqMoney) {
            setParams({
                mode: userType === 'SW' ? "to_master" : "to_sub",
                target_user_id: details && details.sub_id,
                target_user_email: details && details.email,
                amount: reqMoneyDetails && autoFormat(reqMoneyDetails.amount),
                description: reqMoneyDetails.description,
            })
        } else {
            setParams({
                mode: userType === 'SW' ? "to_master" : "to_sub",
                target_user_id: details && details.sub_id,
                target_user_email: details && details.email,
                amount: '',
            })
        }
    }, [reqMoney])

    return (
        <Modal
            maskClosable
            onCancel={() => props.onClose()}
            visible={props.visible}
            footer={false}
            closeIcon={<Icon type="close" className={`text-${segments}`} />}
            width={530}
        >
            <Typography style={styles.title}>{reqMoney ? 'Transfer Money' : 'Enter Amount'}</Typography>
            <div style={styles.sourceFunds}>
                <Typography style={styles.font}>Source of funds:</Typography>
                <div style={styles.balDiv}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img alt='No Image' src={testMode ? BuxRoundBlue : PayoutIconBalance} style={{ height: 16 }} />
                        <Typography style={{ ...styles.font, padding: '0 8px' }}>{testMode? 'Master Balance' : 'Transferable Balance'}</Typography>
                    </div>
                    <Typography style={{ ...styles.font, fontWeight: 'bold' }}>₱{balance ? balance.toLocaleString("en-US", { minimumFractionDigits: 2 }) : 0.00}</Typography>
                </div>
            </div>
            <div style={{...styles.inputDiv, padding: reqMoney? '24px 0 0 0' : '12px 0 0 0'}}>
                <label style={styles.label}>{reqMoney ? 'Amount' : 'Enter transfer amount'}</label>
                {!reqMoney ?
                    <Input
                        size='large'
                        addonBefore="₱"
                        type='number'
                        value={params['amount']}
                        style={{ ...styles.input, border: insufficientBal() ? '1px solid #C0492C' : '1px solid #EEE', color: reqMoney ? '#DDD' : '#2B2D33' }}
                        onChange={handleChange}
                        disabled={reqMoney}
                    />
                    :
                    <Typography
                        style={{
                            ...styles.input,
                            fontWeight: '700',
                            fontSize: 14,
                            border: insufficientBal() && '1px solid #C0492C',
                            padding: insufficientBal()? '12px' : '6px 0',
                        }}>₱{params['amount']}</Typography>
                }
                <Typography style={styles.errorMsg} hidden={!insufficientBal()}>Insufficient balance</Typography>
            </div>
            <div style={styles.inputDiv} hidden={!reqMoney}>
                <label style={styles.label}>Description</label>
                <Typography style={{ ...styles.description, fontSize: 14 }}>{reqMoneyDetails && reqMoneyDetails.description}</Typography>
            </div>
            <div>
                <Typography style={{ ...styles.font, fontWeight: '500', padding: reqMoney? '24px 0 8px 0' : '8px 0' }}>Transfer money to:</Typography>
                <div style={{ ...styles.userDetails, border: !reqMoney && '2px solid #EEEEEE', padding: !reqMoney ? '8px' : '8px 0' }}>
                    <img src={MasterWalletIcon} alt='No Image' />
                    <div style={{ padding: '0 12px' }}>
                        <div>
                            <Typography style={{ ...styles.font, fontWeight: 'bold', color: '#2B2D32' }}>{details && details.full_name}</Typography>
                            <Typography style={{ ...styles.font, fontSize: 12, color: '#909196' }}>{details && details.sub_id}</Typography>
                        </div>
                        <Typography style={{ ...styles.font, fontWeight: 'normal', fontSize: 14, color: '#2B2D33' }}>{details && details.email}</Typography>
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'right', paddingTop: 12 }}>
                <button 
                    className={`btn--${segments}`}
                    disabled={params['amount'] < 1 || !params['amount'] || insufficientBal()}
                    onClick={() => onNext(params)} loading={isLoading}>Next</button>
            </div>
        </Modal>
    )
}

const styles = {
    title: {
        color: '#000',
        fontSize: 28,
        fontWeight: 'bold',
        textAlign: 'left',
        paddingBottom: '20px'
    },
    msg: {
        color: '#2B2D33',
        fontSize: 16,
        textAlign: 'left',
        padding: '12px 0'
    },
    btn: {
        height: 40,
        backgroundColor: '#F5922F',
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
        borderRadius: 4,
    },
    input: {
        fontSize: 16,
        margin: '4px 0px 0px 0px',
        color: '#2B2D33'
    },
    sourceFunds: {
        backgroundColor: '#F5F5F5',
        padding: '12px 16px'
    },
    balDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    font: {
        color: 'rgba(43, 45, 50, 0.8)',
        fontSize: 16,
        fontWeight: '500'
    },
    label: {
        fontSize: 16,
        color: 'rgba(43, 45, 50, 0.8)'
    },
    inputDiv: {
        padding: '12px 0 0 0'
    },
    userDetails: {
        display: 'flex',
        padding: 12
    },
    errorMsg: {
        color: '#C0492C',
        fontSize: 14,
        padding: '4px 0'
    },
    description: {
        borderRadius: 4,
        fontSize: 16,
        color: 'rgba(43, 45, 50, 0.8)',
        padding: '12px 0 8px 0'
    }
}

export default TransferMoneyModal;