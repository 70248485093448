import React from "react";
import { isMobile } from "react-device-detect";
import Main from "./layouts/Main";
import MobileMain from "./layouts/Mobile/MobileMain";
import MobileMore from "./layouts/Mobile/mobAppMore";
import LoginAndSignUp from "./screens/login_and_signup/main";
import Maintenance from "./screens/maintenance/main";
import { Router, Route, Switch } from "react-router-dom";
import { history } from "./store/history";
import "antd/dist/antd.css";
import "./App.css";

import WelcomeScreen from "./screens/welcome_screen/welcome_screen";
import Home from "./components/home/home";
import Dashboard from "./screens/dashboard/dashboard";
import Orders from "./screens/payment_request/index";
import PaymentSchedule from "./components/recurring/schedules/index";
import CreditDebit from "./screens/payment_request/credit_debit/credit_debit";
import Shipments from "./components/shipments/shipments";
import Customers from "./components/customers/customers";
import Funds from "./screens/payout";
import OldFunds from "./components/funds/funds";
import Fund from "./components/AdminComponents/payout/funds";
import Developer from "./screens/integration/integration_main";
import KajabiOffers from "./components/integration/kajabi/offers_main";
import PayoutSchedule from "./components/funds/payoutSchedule";
// import QuadX from './components/logistics/quadx_recon';
import Card from "./components/card";
import UpdateAtmPin from "./components/card/updateAtmPin";
import UpgradePhysicalCard from "./components/card/upgradePhysicalCard";
import NotifPromotionPage from "./components/notification/promotion_page";
import MyProfile from "./screens/my_profile";

import ChargedDetails from "./screens/payment_request/credit_debit/charged_details";
import MobileChargedDetails from "./components/Mobile/orders/debit_credit/charged_details";
import TopUps from "./components/card/top_ups";
import BillsPayment from "./components/card/bills_payment";
import BillsPaymentSuccess from "./components/card/bills_payment/billsPaymentSuccess";
import RequestCvv from "./components/card/request_cvv";

import Login from "./screens/login_and_signup/login/main";
import SignUp from "./screens/login_and_signup/sign_up/sign_up";
import MoreAbout from "./screens/more_about/main";
import VerifyEmail from "./components/verify_email/verify_email";
import ForgotPassword from "./screens/login_and_signup/forgot_password/forgot_password";
import ResendVerification from "./components/resend_verification/resend_verification";
import VerifyingEmailForm from "./components/verifying_email/verifying_email";
import ResetPassword from "./screens/login_and_signup/reset_password/reset_password";
import VerifyNumber from "./components/verify_number/verify_number";
import Username from "./components/username/username";
import CompleteOrder from "./components/complete_order/complete_order";
import CustomerDetails from "./components/customer_details/customer_details";
import OrderDetail from "./components/order_detail/order_detail";
import LinkStores from "./screens/integration/link_stores";
import MobileLinkStores from "./components/link_stores/mobile_link_stores";
import EcomLogin from "./components/ecommerce/login";
import BasicInfo from "./components/basic_info/basic_info";
import EmailAndPass from "./components/email_and_pass/email_and_pass";
import BusinessDetails from "./components/business_detail/business_detail";
import BusinessAddress from "./components/business_address/business_address";
import Billing from "./components/billing/billing";
import Profile from "./layouts/Profile";
import Notification from "./components/notification";
import OnlineBanking from "./components/checkout/onlinBanking";
import GrabPay from "./components/checkout/grabpay";
import Shopify from "./components/checkout/shopify";
import KajabiCheckout from "./components/checkout/kajabi";
import ShortCheckout from "./components/checkout/short";
import Gcash from "./components/checkout/gcash";
import XenditC from "./components/checkout/xendit";
import LinkCreditCard from "./components/link_credit_card/link_credit_card";
import CardDetail from "./components/card_detail/card_detail";
import UpdateCard from "./components/update_card/update_card";
import Sentro from "./components/sentro/sentro";
import PaymentDetails from "./components/payment_details/payment_details_render";
import ExpiredLink from "./components/payment_details/expiredLink";
import Faq from "./components/faq/faq";
import UpgradeApplication from "./components/kyc/upgrade_application";
import AccountLevels from "./screens/account_levels";

import Kyc from "./components/AdminComponents/kyc/kyc_view";
// import Kyc2 from './components/AdminComponents/kyc/kyc_view2';
import Kyc2 from "./components/AdminComponents/kyc/kyc_user_list_2";
import Kyc3 from "./components/AdminComponents/kyc/kyc_user_list_3";
import KYCIndividual from "./components/AdminComponents/kyc/kyc_individual_view";
import KYCIndividual2 from "./components/AdminComponents/kyc/kyc_user_2";
import KYCIndividual3 from "./components/AdminComponents/kyc/kyc_user_3";
import Overview from "./screens/overview";
import Ledger from "./components/AdminComponents/ledger/ledger";
import BuxLedger from "./components/AdminComponents/ledger/bux_ledger";
import AdminDashboard from "./components/AdminComponents/admin_dashboard/admin_dashboard";
import BankLedger from "./components/AdminComponents/admin_dashboard/bank_ledger";
import Recon from "./components/AdminComponents/recon/recon";
import ReverseRecon from "./components/AdminComponents/recon/recon_reverse";
import DPRecon from "./components/AdminComponents/recon/recon_dragonpay";
import XenditRecon from "./components/AdminComponents/recon/recon_xendit";
import SentroRecon from "./components/AdminComponents/recon/recon_sentro";
import GCashRecon from "./components/AdminComponents/recon/recon_gcash";
import PayAndGoRecon from "./components/AdminComponents/recon/recon_bti";
import DA5Recon from "./components/AdminComponents/recon/recon_da5";
import PosibleRecon from "./components/AdminComponents/recon/recon_posible";
import ECPayRecon from "./components/AdminComponents/recon/recon_ecpay";
import ReconPayout from "./components/AdminComponents/recon/recon_payout";
import ReconGeneric from "./components/AdminComponents/recon/recon_generic";
import TransferMoneyRecon from "./components/AdminComponents/recon/multiwallet/transfer_money";
import BayadCenterRecon from "./components/AdminComponents/recon/recon_bayad_center";
import Wallet from "./components/AdminComponents/wallet/wallet";
import Holidays from "./components/AdminComponents/holiday/holidays";
import Users from "./screens/admin/users/users";
import MultiwalletUsers from "./components/AdminComponents/multiwallet/multiwallet_users/index";
import BuxTest from "./screens/admin/users/bux-test/components/bux-test.jsx";
import MerchantIDs from "./screens/admin/users/merchant_id";
import GCashSubMIDs from "./screens/admin/users/gcash_submid";
import Settings from "./components/AdminComponents/settings/settings";
import ContentSettings from "./components/AdminComponents/settings/content";
import Partners from "./components/AdminComponents/partners/partners";
import Grants from "./screens/admin/users/grants";
import Fees from "./components/AdminComponents/fees/fees";
import PromoCode from "./components/AdminComponents/promotion";
import Payouts from "./components/AdminComponents/payout/payouts";
import Ecom from "./components/ecomx/ecomx";
import LearnMorePage from "./components/learn_more_page_ecomx/learn_more_page_ecomx";
import Terms from "./components/terms/terms_condition";
import PrivacyPolicy from "./components/terms/privacy_policy";
import TermsAndPrivacy from "./screens/terms_privacy/terms_privacy_main";
import BtiTerms from "./components/terms/bti_terms";
import DA5Terms from "./components/terms/da5_term";
import Location from "./components/locator/locator";
import OldAccount from "./components/account_settings/account_settings";
import Account from "./screens/settings";
import FraudManagement from "./components/AdminComponents/fraud_management";
import SystemNotification from "./components/AdminComponents/system_notification";
import ResponseLog from "./components/AdminComponents/response_log/response_log";
import Endorsement from "./components/AdminComponents/endorsement";
import UserEndorsement from "./components/AdminComponents/user_endorsement";
import AddMoneySettlement from "./components/AdminComponents/add_money_settlement/add_money_settlement";
import OpenFinance from "./components/AdminComponents/open_finance";
import GCashSubMidInfo from "./components/kyc/application_form/gcash_submid";

//Mobile component
import UpgradeMobile from "./components/kyc/upgradeMobile";
import MobileBusinessUpgrade from "./components/complete_profile/mobile_business_upgrade";
import MobileEnterpriseUpgrade from "./components/upgrade_to_enterprise/mobile/mobile_main_enterprise";
import MobileOrders from "./components/Mobile/orders/index";
import GenerateCode from "./components/Mobile/generate_code/generate_code";
import MobileDashboard from "./components/Mobile/dashboard/dashboard";
import MobileOrderDetails from "./components/Mobile/order_details/mobile_order_details";
import MobileFunds from "./components/Mobile/funds/funds";
import MobilePayout from "./components/Mobile/payout/payout";
import MobileAccount from "./components/account_settings/accountMobile";
import MobileCompleteOrder from "./components/Mobile/complete/complete_order";
import SearchOrder from "./components/Mobile/search_order/search_order";
import SearchPayout from "./components/Mobile/search_payout/search_payout";
import Pricing from "./screens/pricing/pricing";
import MobilePricing from "./components/pricing/pricingMobile";
import MessengerLink from "./components/messenger_link/messenger_link";
import MobileCard from "./components/Mobile/card";
import MobileSignUp4_0 from "./components/sign_up/sign_up_component/sign_up_4_0";
import MobileWallet from "./components/Mobile/wallet";

import PageNotFound from "./components/404/404";

import BuyerShippingForm from "./components/buyer_shipping_form/buyer_shipping_form";
import EcomAuthorize from "./components/ecommerce/authorize";
import SignUpPromo from "./components/sign_up/sign_up_component/signupPromo";

import Checkout from "./components/checkout/NewCheckout/checkout";
// import CompleteProfile from "./components/complete_profile/complete_profile";
import CompleteProfile from "./components/kyc/application_form/index";
import ReviewApplication from "./components/complete_profile/review_application";
import MobileKycIndividual from "./components/AdminComponents/kyc/kyc_admin_mobile";
import UpgradeToBusiness from "./components/upgrade_to_business/upgrade_to_business";
import UpgradeEnterprise from "./components/upgrade_to_enterprise/mainEnterprise";
import MainEnterprise from "./screens/kyb/index";
import KybView from "./components/AdminComponents/kyc/kyb";
import KYBStages from "./components/AdminComponents/kyb_main/kyb_stages/index";
import NewBank from "./components/account_settings/bank_account/newBank";

import ChangeNumber from "./components/account_settings/change_contact/change_number";
import SurveyBusinessMain from "./components/survey_business/survey_business_main";
import EnterpriseSurvey from "./components/survey_business/enterprise_survey";

import ReconEnterprise from "./components/recon_enterprise/index";
import MobileReconEnterprise from "./components/Mobile/recon_mobile";

import KYBIndividualView from "./components/AdminComponents/kyb_main/kyb_main";

import UserAccess from "./components/AdminComponents/userAccount/user_account";
import UserScope from "./components/AdminComponents/userAccount/user_scope";

import PasswordExpiry from "./screens/login_and_signup/update_password/update_password";
import TestModePayment from "./components/test_mode/testmode_payment";
import QrDetails from "./components/account_settings/bux_qr/qr_details";
// import ShopifyTutorial from './components/tutorials/shopify/shopify_tutorial'
import ShopifyTutorial from "./components/tutorials/shopify_v2";
import AdminUserHistory from "./components/AdminComponents/users/usersComponent/userHistory";
import EditPermission from "./screens/settings/edit_permission";

// Multiwallet components
import {
  SubUserLoginSignup,
  AddMoney,
  MasterWalletPage,
  TransferMoney,
  SubwalletUserHistory,
  SubwalletMainPage,
} from "./components/multiwallet";
import MultiwalletMobile from "./components/Mobile/multiwallet";
import SecureCheckout from "./screens/checkout/tppCheckout";
import ReconTPP from "./components/AdminComponents/recon/recon_tpp";
import AdminAdvancing from "./components/AdminComponents/advancing/root_page";
import Advancing from "./components/AdminComponents/advancing/index";
import HowItWorks from "./screens/how_it_works/how_it_works";
import Annoucement from "./components/annoucement.js";
import PromoAddEditView from "./components/AdminComponents/promotion/add_edit_view";
import PromotionDraft from "./components/AdminComponents/promotion/custom_component/draft";
import Vouchers from "./components/AdminComponents/promotion/vouchers/vouchers";
import VoucherSinglePage from "./components/AdminComponents/promotion/vouchers/voucher_single_page";

import UserFeedback from "./components/AdminComponents/user_feedback";

import ShopifyAuthorize from "./screens/shopify/authorize";
import ShopifyLogin from "./screens/shopify/login";
import FeedbackSummaryReports from "./components/AdminComponents/user_feedback/feedback_summary_reports";
import PaymentRequestTutorial from "./components/Mobile/orders/tutorial";
import PayoutTutorial from "./components/Mobile/payout/tutorial";
import PrestaShopTutorial from "./components/tutorials/presta_shop";
import WoocommerceTutorial from "./components/tutorials/woocommerce";
import KajabiTutorial from "./components/tutorials/kajabi";
import { Transfer } from "./screens/Transfer/routes/merchant/Transfer.jsx";
import {
  AdminLimits,
  ApproverLimits,
  ApproverTransfers,
} from "./screens/Transfer/index.js";
import AdminUserManagement from "./screens/Transfer/routes/merchant_admin/AdminUserManagement.jsx";
import { FundTransfers } from "./screens/fund_transfer_management";
import { RevenueWallet } from "./screens/revenue_wallet";
import { Remittances as MerchantRemittances } from "./screens/remittance-history/components/remittances.jsx";
import { RemittanceView as MerchantRemittanceView } from "./screens/remittance-history/components/remittance-view.jsx";

// CashBux Admin Menu
import { Admins } from "./screens/admin/cashbux/features/admins/components/admins.jsx";
import { AdminView } from "./screens/admin/cashbux/features/admins/components/admin-view.jsx";
import CreateAdmin from "./screens/admin/cashbux/features/admins/components/create-admin.jsx";
import { Submissions } from "./screens/admin/cashbux/features/submissions/components/submissions.jsx";
import { SubmissionView } from "./screens/admin/cashbux/features/submissions/components/submission-view.jsx";
import { Remittances } from "./screens/admin/cashbux/features/remittances/components/remittances.jsx";
import { Revenues } from "./screens/admin/cashbux/features/revenues/components/revenues.jsx";
import { Payouts as CashbuxPayouts } from "./screens/admin/cashbux/features/payouts/components/payouts.jsx";
import { RemittanceView } from "./screens/admin/cashbux/features/remittances/components/remittance-view.jsx";
import MerchantFeeConfig from "./screens/admin/cashbux/features/fee_config/fee-config-view.jsx";
import CashbuxCashOutRecon from "./screens/admin/cashbux/features/recon/recon-cash-out.js";
import CashbuxDebitRecon from "./screens/admin/cashbux/features/recon/recon-debit.js";
import CashbuxSettlement from "./screens/admin/cashbux/features/settlement/settlement.js";

/*
class App extends React.Component {

  render() {
      return (
        <Router history={history}>
          <Switch>
            <Route component={Maintenance} />
          </Switch>
        </Router>
      );
    }
}*/
class App extends React.Component {
  render() {
    console.log(process.env.REACT_APP_ROOT_URL);
    console.log(window.location.hostname);
    if (process.env.REACT_APP_ROOT_URL === window.location.hostname) {
      return (
        <Router history={history}>
          <Switch>
            <Route
              exact
              path={"/login"}
              render={() => (
                <LoginAndSignUp>
                  <Login />
                </LoginAndSignUp>
              )}
            />
            <Route
              exact
              path={"/signup"}
              render={() => (
                <LoginAndSignUp>
                  <SignUp />
                </LoginAndSignUp>
              )}
            />
            <Route exact path={"/more_about"} render={() => <MoreAbout />} />
            <Route
              exact
              path={"/password_expiry"}
              render={() => (
                <LoginAndSignUp>
                  <PasswordExpiry />
                </LoginAndSignUp>
              )}
            />
            <Route
              exact
              path={"/forgot_password"}
              render={() => (
                <LoginAndSignUp>
                  <ForgotPassword />
                </LoginAndSignUp>
              )}
            />
            <Route
              exact
              path={"/forgot_password/:email"}
              render={(props) => (
                <LoginAndSignUp>
                  <ForgotPassword {...props} />
                </LoginAndSignUp>
              )}
            />
            <Route
              exact
              path={"/resend_verification"}
              render={() => (
                <LoginAndSignUp>
                  <ResendVerification />
                </LoginAndSignUp>
              )}
            />

            <Route
              exact
              path={"/account_settings/update_mobile/"}
              render={() =>
                isMobile ? (
                  <ChangeNumber />
                ) : (
                  <LoginAndSignUp>
                    <ChangeNumber />
                  </LoginAndSignUp>
                )
              }
            />

            <Route
              exact
              path={"/signup_promo"}
              render={() => (
                <LoginAndSignUp>
                  <SignUpPromo />
                </LoginAndSignUp>
              )}
            />

            <Route
              exact
              path={"/signup_promo"}
              render={() => (
                <LoginAndSignUp>
                  <SignUpPromo />
                </LoginAndSignUp>
              )}
            />

            <Route exact path={"/expired_link/"} component={ExpiredLink} />
            <Route
              exact
              path={"/password_reset/:id/:code/"}
              component={ResetPassword}
            />
            <Route exact path={"/sentro/login/"} component={Sentro} />
            <Route
              exact
              path={"/email/verifying/:id/:verification_code/"}
              component={VerifyingEmailForm}
            />
            <Route exact path={"/email/verify"} component={VerifyEmail} />
            <Route exact path={"/verify_number"} component={VerifyNumber} />
            <Route exact path={"/login_next"} component={EcomLogin} />
            <Route exact path={"/authorize"} component={EcomAuthorize} />
            <Route
              exact
              path={"/shopify/authorize"}
              component={ShopifyAuthorize}
            />
            <Route
              exact
              path={"/shopify/login_next"}
              component={ShopifyLogin}
            />
            <Route exact path={"/username"} component={Username} />
            <Route
              exact
              path={"/mobile/password_expiry"}
              component={PasswordExpiry}
            />
            <Route exact path={"/mobile/username"} component={Username} />
            <Route exact path={"/order_details/:id/"} component={OrderDetail} />
            <Route
              exact
              path={"/charged_details/:id/:uuid/"}
              component={ChargedDetails}
            />
            <Route
              exact
              path={"/mobile/charged_details/:id/:uuid/"}
              component={MobileChargedDetails}
            />
            <Route
              exact
              path={"/payout_schedule/"}
              component={PayoutSchedule}
            />
            <Route exact path={"/checkout/:uuid/"} component={Checkout} />
            <Route
              exact
              path={"/cybersource/:uuid/"}
              component={SecureCheckout}
            />
            <Route exact path={"/QR/:trade_name"} component={QrDetails} />
            <Route exact path={"/online_banking/"} component={OnlineBanking} />
            <Route exact path={"/grabpay/:uuid/"} component={GrabPay} />
            <Route exact path={"/gcash/:uuid/"} component={Gcash} />
            <Route exact path={"/xendit/:uuid/"} component={XenditC} />
            <Route exact path={"/payment/:uuid/"} component={PaymentDetails} />
            <Route exact path={"/faq"} component={Faq} />
            <Route exact path={"/map"} component={Location} />
            <Route exact path={"/messenger"} component={MessengerLink} />
            <Route exact path={"/ecomx"} component={LearnMorePage} />
            <Route exact path={"/ecomx/registration"} component={Ecom} />
            <Route exact path={"/terms"} component={Terms} />
            <Route exact path={"/privacy_policy"} component={PrivacyPolicy} />
            <Route
              exact
              path={"/terms_and_privacy"}
              component={TermsAndPrivacy}
            />
            <Route exact path={"/bti_terms"} component={BtiTerms} />
            <Route exact path={"/da5_terms"} component={DA5Terms} />
            <Route
              exact
              path={"/shipment/:uuid/"}
              component={BuyerShippingForm}
            />
            <Route exact path={"/link_bank"} component={NewBank} />
            <Route
              exact
              path={"/survey_business"}
              component={SurveyBusinessMain}
            />
            <Route
              exact
              path={"/survey_enterprise"}
              component={EnterpriseSurvey}
            />
            <Route
              exact
              path={"/test/payment/:uuid/"}
              component={PaymentDetails}
            />
            <Route exact path={"/test/checkout/:uuid/"} component={Checkout} />
            <Route exact path={"/test/xendit/:uuid/"} component={XenditC} />
            <Route
              exact
              path={"/test/bux_payment/:uuid/"}
              component={TestModePayment}
            />
            <Route
              exact
              path={"/test/cybersource/:uuid/"}
              component={SecureCheckout}
            />
            <Route exact path={"/test/QR/:trade_name/"} component={QrDetails} />
            <Route exact path={"/shopify/:id/:uuid/"} component={Shopify} />
            <Route
              exact
              path={"/shopify_tutorial/"}
              component={ShopifyTutorial}
            />
            <Route
              exact
              path={"/kajabi/:merchant_id/:offer_id/"}
              component={KajabiCheckout}
            />
            <Route
              exact
              path={"/short/:merchant_id/"}
              component={ShortCheckout}
            />
            <Route
              exact
              path={"/voucher/:code/:description/:banner_url/"}
              component={VoucherSinglePage}
            />
            <Route
              exact
              path={"/presta_shop_tutorial/"}
              component={PrestaShopTutorial}
            />
            <Route
              exact
              path={"/woocommerce_tutorial/"}
              component={WoocommerceTutorial}
            />
            <Route
              exact
              path={"/kajabi_tutorial/"}
              component={KajabiTutorial}
            />
            <Route
              exact
              path={"/customer_details/"}
              component={CustomerDetails}
            />
            <Route
              path={"/my_profile/basic_information"}
              render={() => (
                <Profile>
                  <BasicInfo />
                </Profile>
              )}
            />
            <Route
              path={"/my_profile/email_and_password"}
              render={() => (
                <Profile>
                  <EmailAndPass />
                </Profile>
              )}
            />

            <Route
              path={"/my_profile/business_detail"}
              render={() => (
                <Profile>
                  <BusinessDetails />
                </Profile>
              )}
            />
            <Route
              path={"/my_profile/business_address"}
              render={() => (
                <Profile>
                  <BusinessAddress />
                </Profile>
              )}
            />
            <Route
              path={"/my_profile/billing"}
              render={() => (
                <Profile>
                  <Billing />
                </Profile>
              )}
            />

            <Route
              path={"/mobile/orders"}
              render={() => (
                <MobileMain>
                  <MobileOrders />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/generate_code"}
              render={() => (
                <MobileMain title="Generate Link">
                  <GenerateCode />
                </MobileMain>
              )}
            />
            <Route
              path={"/mobile/dashboard"}
              render={() => (
                <MobileMain title={"Dashboard"}>
                  <MobileDashboard />
                </MobileMain>
              )}
            />
            <Route
              path={"/mobile/wallet"}
              render={() => (
                <MobileMain title={"Wallet"}>
                  <MobileWallet />
                </MobileMain>
              )}
            />
            <Route
              path={"/mobile/funds"}
              render={() => (
                <Main>
                  <Funds />
                </Main>
              )}
            />
            <Route
              path={"/mobile/recon"}
              render={() => (
                <MobileMain title={"Reconciliation"}>
                  <MobileReconEnterprise />
                </MobileMain>
              )}
            />
            <Route
              path={"/mobile/payout"}
              render={() => (
                <MobileMain>
                  <MobilePayout />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/more"}
              render={() => (
                <Main>
                  <MobileMore />
                </Main>
              )}
            />

            <Route
              path={"/mobile/pricing"}
              render={() => (
                <MobileMain title={"Pricing"}>
                  <MobilePricing />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/account_settings"}
              render={() => (
                // <MobileMain title={"Settings"}>
                <MobileAccount />
                // </MobileMain>
              )}
            />

            <Route
              path={"/mobile/link_stores"}
              render={() => (
                <MobileMain title={"Link Stores"}>
                  <LinkStores />
                </MobileMain>
              )}
            />
            <Route
              path={"/mobile/upgrade"}
              render={() => (
                <MobileMain title={"Upgrade"}>
                  <UpgradeMobile />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/business_upgrade"}
              render={() => (
                <MobileMain>
                  <MobileBusinessUpgrade />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/enterprise_upgrade"}
              render={() => (
                <MobileMain>
                  <MobileEnterpriseUpgrade />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/review_application"}
              render={() => (
                <MobileMain title={"KYC Application"}>
                  <ReviewApplication />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/card"}
              render={() => (
                <Main title={"EON Card"}>
                  <MobileCard />
                </Main>
              )}
            />

            <Route
              path={"/mobile/top_ups"}
              render={() => (
                <MobileMain title={"Top Ups"}>
                  <TopUps />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/bills_payment"}
              render={() => (
                <MobileMain title={"Bills Payment"}>
                  <BillsPayment />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/request_cvv"}
              render={() => (
                <MobileMain title={"EON Card"}>
                  <RequestCvv />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/physical_card_upgrade"}
              render={() => (
                <MobileMain title={"Physical Card"}>
                  <UpgradePhysicalCard />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/bills_payment_success"}
              render={() => (
                <MobileMain title={"Bill Payment Success"}>
                  <BillsPaymentSuccess />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/notifications"}
              render={() => (
                <MobileMain title={"Notifications"}>
                  <Notification />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/promotion_page"}
              render={() => (
                <MobileMain title="Promotion">
                  <NotifPromotionPage />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/integration"}
              render={() => (
                <MobileMain title="Integration">
                  <Developer />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/multiwallet"}
              render={() => (
                <MobileMain title="Multi-wallet">
                  <MultiwalletMobile />
                </MobileMain>
              )}
            />
            <Route
              exact
              path={"/mobile/resend_verification"}
              component={ResendVerification}
            />
            <Route
              exact
              path={"/mobile/order_details/:id/"}
              component={MobileOrderDetails}
            />
            <Route
              exact
              path={"/mobile/order/search/:orderType/"}
              component={SearchOrder}
            />
            <Route
              exact
              path={"/mobile/fund/search/:payoutType/"}
              component={SearchPayout}
            />
            <Route exact path={"/mobile/login"} component={Login} />
            <Route exact path={"/mobile/signup"} component={MobileSignUp4_0} />

            <Route
              exact
              path={"/upgrade_enterprise/"}
              component={MainEnterprise}
            />
            <Route
              exact
              path={"/link_credit_card/"}
              component={LinkCreditCard}
            />
            <Route exact path={"/card_detail/"} component={CardDetail} />
            <Route exact path={"/update_card/"} component={UpdateCard} />
            <Route
              exact
              path={"/complete_order/:uuid/"}
              component={CompleteOrder}
            />
            <Route
              exact
              path={"/mobile/complete_order/:uuid"}
              component={MobileCompleteOrder}
            />
            <Route exact path={"/"} component={Home} />

            <Route
              path={"/dashboard"}
              render={() => (
                <Main>
                  <Dashboard />
                </Main>
              )}
            />
            <Route
              path={"/welcome_page"}
              render={() => (
                <Main>
                  <WelcomeScreen />
                </Main>
              )}
            />

            <Route path={"/announcement"} render={() => <Annoucement />} />

            <Route
              path={"/upgrade"}
              render={() => (
                <Main>
                  <UpgradeApplication />
                </Main>
              )}
            />
            <Route
              path={"/link_stores"}
              render={() => (
                <Main>
                  <LinkStores />
                </Main>
              )}
            />
            <Route
              path={"/vouchers"}
              render={() => (
                <Main>
                  <Vouchers />
                </Main>
              )}
            />
            <Route
              path={"/complete_profile"}
              render={() => <CompleteProfile />}
            />
            <Route path={"/account_levels"} render={() => <AccountLevels />} />
            <Route path={"/how_it_works"} render={() => <HowItWorks />} />
            <Route
              path={"/upgrade_to_business"}
              render={() => (
                <Main>
                  <UpgradeToBusiness />
                </Main>
              )}
            />
            <Route
              path={"/upgrade_to_enterprise"}
              render={() => (
                <Main>
                  <UpgradeEnterprise />
                </Main>
              )}
            />
            <Route
              path={"/card"}
              render={() => (
                <Main>
                  <Card />
                </Main>
              )}
            />
            <Route
              path={"/request_cvv"}
              render={() => (
                <Main>
                  <RequestCvv />
                </Main>
              )}
            />
            <Route
              path={"/cards/top_ups"}
              render={() => (
                <Main>
                  <TopUps />
                </Main>
              )}
            />
            <Route
              path={"/cards/bills_payment"}
              render={() => (
                <Main>
                  <BillsPayment />
                </Main>
              )}
            />
            <Route
              path={"/bills_payment_success"}
              render={() => (
                <Main>
                  <BillsPaymentSuccess />
                </Main>
              )}
            />
            <Route
              path={"/update_atm_pin/step_1"}
              render={() => (
                <Main>
                  <UpdateAtmPin step={2} />
                </Main>
              )}
            />
            <Route
              path={"/update_atm_pin/step_2"}
              render={() => (
                <Main>
                  <UpdateAtmPin step={2} />
                </Main>
              )}
            />
            <Route
              path={"/physical_card_upgrade"}
              render={() => (
                <Main>
                  <UpgradePhysicalCard />
                </Main>
              )}
            />
            <Route
              path={"/account_settings"}
              render={() => (
                <Main>
                  <Account />
                </Main>
              )}
            />
            <Route
              path={"/pricing"}
              render={() => (
                <Main>
                  <Pricing />
                </Main>
              )}
            />
            <Route
              path={"/orders"}
              render={() => (
                <Main>
                  <Orders />
                </Main>
              )}
            />
            <Route
              path={"/payment_schedules"}
              render={() => (
                <Main>
                  <PaymentSchedule />
                </Main>
              )}
            />
            <Route
              path={"/credit_debit"}
              render={() => (
                <Main>
                  <CreditDebit />
                </Main>
              )}
            />
            <Route
              path={"/shipment_tracking"}
              render={() => (
                <Main>
                  <Shipments />
                </Main>
              )}
            />
            <Route
              path={"/customers"}
              render={() => (
                <Main>
                  <Customers />
                </Main>
              )}
            />

            <Route
              path={"/funds"}
              render={() => (
                <Main>
                  <Funds />
                </Main>
              )}
            />
            <Route
              path={"/funds_enterprise"}
              render={() => (
                <Main>
                  <Funds />
                </Main>
              )}
            />

            {/* Merchant Maker Route */}
            <Route
              path={"/transfer"}
              render={() => (
                <Main>
                  <Transfer />
                </Main>
              )}
            />

            <Route 
              path={"/remittances"}
              render={() => (
                <Main>
                  <MerchantRemittances />                  
                </Main>
              )}
            />

            {/* Merchant Approver Routes */}
            <Route
              path={"/transfer_requests"}
              render={() => (
                <Main>
                  <ApproverTransfers />
                </Main>
              )}
            />

            <Route
              path={"/limit_requests"}
              render={() => (
                <Main>
                  <ApproverLimits />
                </Main>
              )}
            />

            {/* Merchant Admin Routes */}
            <Route
              path={"/limits_management"}
              render={() => (
                <Main>
                  <AdminLimits />
                </Main>
              )}
            />

            <Route
              path={"/peer_management"}
              render={() => (
                <Main>
                  <AdminUserManagement />
                </Main>
              )}
            />

            {/* Merchant Admin & Maker Fund Transfer Route */}
            <Route
              path={"/fund_transfer_management"}
              render={() => (
                <Main>
                  <FundTransfers />
                </Main>
              )}
            />

            {/* Revenue Wallets Dashboard Route */}
            <Route
              path={"/revenue_wallet"}
              render={() => (
                <Main>
                  <RevenueWallet />
                </Main>
              )}
            />

            <Route
              path={"/enterprise_payout"}
              render={() => (
                <Main>
                  <Fund />
                </Main>
              )}
            />
            <Route
              path={"/ledger"}
              render={() => (
                <Main>
                  <Ledger />
                </Main>
              )}
            />
            <Route
              path={"/bux_ledger"}
              render={() => (
                <Main>
                  <BuxLedger />
                </Main>
              )}
            />
            <Route
              path={"/overview"}
              render={() => (
                <Main>
                  <Overview />
                </Main>
              )}
            />

            <Route
              path={"/recon/reverse"}
              render={() => (
                <Main>
                  <ReverseRecon />
                </Main>
              )}
            />

            <Route
              path={"/recon/7_eleven"}
              render={() => (
                <Main>
                  <Recon />
                </Main>
              )}
            />

            <Route
              path={"/recon/dragonpay"}
              render={() => (
                <Main>
                  <DPRecon />
                </Main>
              )}
            />

            <Route
              path={"/recon/bayad_center"}
              render={() => (
                <Main>
                  <BayadCenterRecon />
                </Main>
              )}
            />

            <Route
              path={"/recon/xendit"}
              render={() => (
                <Main>
                  <XenditRecon />
                </Main>
              )}
            />
            <Route
              path={"/recon/sentro"}
              render={() => (
                <Main>
                  <SentroRecon />
                </Main>
              )}
            />
            <Route
              path={"/recon/gcash"}
              render={() => (
                <Main>
                  <GCashRecon />
                </Main>
              )}
            />
            <Route
              path={"/recon/payngo"}
              render={() => (
                <Main>
                  <PayAndGoRecon />
                </Main>
              )}
            />
            <Route
              path={"/recon/posible"}
              render={() => (
                <Main>
                  <PosibleRecon />
                </Main>
              )}
            />
            <Route
              path={"/recon/da5"}
              render={() => (
                <Main>
                  <DA5Recon />
                </Main>
              )}
            />
            <Route
              path={"/recon/ecpay"}
              render={() => (
                <Main>
                  <ECPayRecon />
                </Main>
              )}
            />
            <Route
              path={"/recon/payout"}
              render={() => (
                <Main>
                  <ReconPayout />
                </Main>
              )}
            />

            <Route
              path={"/recon/generic"}
              render={() => (
                <Main>
                  <ReconGeneric />
                </Main>
              )}
            />

            <Route
              path={"/recon/transfer_money"}
              render={() => (
                <Main>
                  <TransferMoneyRecon />
                </Main>
              )}
            />

            <Route
              path={"/recon/unionbank"}
              render={() => (
                <Main>
                  <ReconTPP />
                </Main>
              )}
            />

            <Route
              path={"/recon"}
              render={() => (
                <Main>
                  <ReconEnterprise />
                </Main>
              )}
            />

            <Route
              path={"/admin/advancing"}
              render={() => (
                <Main>
                  <AdminAdvancing />
                </Main>
              )}
            />

            <Route
              path={"/settings"}
              render={() => (
                <Main>
                  <Settings />
                </Main>
              )}
            />
            <Route
              path={"/add_money_settlement"}
              render={() => (
                <Main>
                  <AddMoneySettlement />
                </Main>
              )}
            />

            <Route
              path={"/content_settings"}
              render={() => (
                <Main>
                  <ContentSettings />
                </Main>
              )}
            />

            <Route
              path={"/partners"}
              render={() => (
                <Main>
                  <Partners />
                </Main>
              )}
            />
            <Route
              path={"/grants"}
              render={() => (
                <Main>
                  <Grants />
                </Main>
              )}
            />

            <Route
              exact
              path={"/cashbux/admins"}
              render={() => (
                <Main>
                  <Admins />
                </Main>
              )}
            />

            <Route
              exact
              path={"/cashbux/admins/create"}
              render={() => (
                <Main>
                  <CreateAdmin />
                </Main>
              )}
            />

            <Route
              path={"/cashbux/admins/:id"}
              render={() => (
                <Main>
                  <AdminView />
                </Main>
              )}
            />

            <Route
              exact
              path={"/cashbux/submissions"}
              render={() => (
                <Main>
                  <Submissions />
                </Main>
              )}
            />

            <Route
              path={"/cashbux/submissions/:id"}
              render={() => (
                <Main>
                  <SubmissionView />
                </Main>
              )}
            />

            <Route
              exact
              path={"/cashbux/remittances"}
              render={() => (
                <Main>
                  <Remittances />
                </Main>
              )}
            />

            <Route
              path={"/cashbux/remittances/:id"}
              render={() => (
                <Main>
                  < RemittanceView/>
                </Main>
              )}
            />

            <Route
              exact
              path={"/cashbux/revenues"}
              render={() => (
                <Main>
                  <Revenues />
                </Main>
              )}
            />

            <Route
              exact
              path={"/cashbux/payouts"}
              render={() => (
                <Main>
                  <CashbuxPayouts />
                </Main>
              )}
            />

            <Route
              exact
              path={"/cashbux/merchant_configs"}
              render={() => (
                <Main>
                  <MerchantFeeConfig />
                </Main>
              )}
            />

            <Route
              path={"/cashbux/recon_cash_out"}
              render={() => (
                <Main>
                  <CashbuxCashOutRecon />
                </Main>
              )}
            />

            <Route
              path={"/cashbux/recon_pos_debit"}
              render={() => (
                <Main>
                  <CashbuxDebitRecon />
                </Main>
              )}
            />

            <Route
              path={"/cashbux/settlement"}
              render={() => (
                <Main>
                  <CashbuxSettlement />
                </Main>
              )}
            />


            <Route
              path={"/fees"}
              render={() => (
                <Main>
                  <Fees />
                </Main>
              )}
            />

            <Route
              path={"/holidays"}
              render={() => (
                <Main>
                  <Holidays />
                </Main>
              )}
            />

            <Route
              path={"/wallets"}
              render={() => (
                <Main>
                  <Wallet />
                </Main>
              )}
            />

            <Route
              path={"/reports"}
              render={() => (
                <Main>
                  <AdminDashboard />
                </Main>
              )}
            />

            <Route
              path={"/bank/ledger"}
              render={() => (
                <Main>
                  <BankLedger />
                </Main>
              )}
            />
            <Route
              path={"/users/multiwallets"}
              render={() => (
                <Main>
                  <MultiwalletUsers />
                </Main>
              )}
            />
            <Route
              path={"/users"}
              render={() => (
                <Main>
                  <Users />
                </Main>
              )}
            />
            <Route
              path={"/bux_test"}
              render={() => (
                <Main>
                  <BuxTest />
                </Main>
              )}
            />
            <Route
              path={"/merchant_ids"}
              render={() => (
                <Main>
                  <MerchantIDs />
                </Main>
              )}
            />
            <Route
              path={"/gcash_submids"}
              render={() => (
                <Main>
                  <GCashSubMIDs />
                </Main>
              )}
            />
            <Route
              path={"/payouts"}
              render={() => (
                <Main>
                  <Payouts />
                </Main>
              )}
            />

            <Route
              exact
              path={"/integration/kajabi/"}
              render={() => (
                <Main>
                  <KajabiOffers />
                </Main>
              )}
            />

            <Route
              path={"/integration"}
              render={() => (
                <Main>
                  <Developer />
                </Main>
              )}
            />

            <Route
              path={"/user_access"}
              render={() => (
                <Main>
                  <UserAccess />
                </Main>
              )}
            />

            <Route
              path={"/user_scope"}
              render={() => (
                <Main>
                  <UserScope />
                </Main>
              )}
            />

            <Route path={"/submissions/:id/"} component={KYCIndividual} />
            <Route
              path={"/mobile/submissions/:id"}
              component={MobileKycIndividual}
            />
            <Route path={"/submissions_kyc2/:id/"} component={KYCIndividual2} />
            <Route path={"/submissions_kyc3/:id/"} component={KYCIndividual3} />
            <Route
              path={"/submissions_kyb/:id/"}
              component={KYBIndividualView}
            />
            <Route path={"/admin/advance/:id/"} component={Advancing} />
            <Route
              path={"/admin/user_details/:id/"}
              component={AdminUserHistory}
            />

            <Route
              path={"/submissions/"}
              render={() => (
                <Main>
                  <Kyc />
                </Main>
              )}
            />
            <Route
              path={"/submissions_kyc2/"}
              render={() => (
                <Main>
                  <Kyc2 />
                </Main>
              )}
            />

            <Route
              path={"/submissions_kyc3/"}
              render={() => (
                <Main>
                  <Kyc3 />
                </Main>
              )}
            />

            <Route
              path={"/submissions_kyb/"}
              render={() => (
                <Main>
                  <KYBStages />
                </Main>
              )}
            />

            <Route
              path={"/old_submissions_kyb/"}
              render={() => (
                <Main>
                  <KybView />
                </Main>
              )}
            />

            <Route
              path={"/promotion"}
              render={() => (
                <Main>
                  <PromoCode />
                </Main>
              )}
            />

            <Route
              path={"/new_promotion/"}
              render={() => (
                <Main>
                  <PromoAddEditView />
                </Main>
              )}
            />

            <Route
              path={"/view_promotion/:id"}
              render={() => (
                <Main>
                  <PromoAddEditView />
                </Main>
              )}
            />

            <Route
              path={"/edit_promotion/:id"}
              render={() => (
                <Main>
                  <PromoAddEditView />
                </Main>
              )}
            />

            <Route
              path={"/promotion_draft"}
              render={() => (
                <Main>
                  <PromotionDraft />
                </Main>
              )}
            />

            <Route
              path={"/notifications"}
              render={() => (
                <Main>
                  <Notification />
                </Main>
              )}
            />

            <Route
              path={"/promotion_page"}
              render={() => (
                <Main>
                  <NotifPromotionPage />
                </Main>
              )}
            />

            <Route
              path={"/test/dashboard"}
              render={() => (
                <Main>
                  <Dashboard />
                </Main>
              )}
            />
            <Route
              path={"/test/orders"}
              render={() => (
                <Main>
                  <Orders />
                </Main>
              )}
            />
            <Route
              path={"/test/payment_schedules"}
              render={() => (
                <Main>
                  <PaymentSchedule />
                </Main>
              )}
            />
            <Route
              path={"/test/multiwallet"}
              render={() => (
                <Main>
                  <MasterWalletPage />
                </Main>
              )}
            />
            <Route
              path={"/test/wallet/transfer_money"}
              render={() => (
                <Main>
                  <TransferMoney />
                </Main>
              )}
            />
            <Route
              path={"/test/user_history/subwallet/:id"}
              render={() => (
                <Main>
                  <SubwalletUserHistory />
                </Main>
              )}
            />
            <Route
              path={"/test/wallet/add_money"}
              render={() => (
                <Main>
                  <AddMoney />
                </Main>
              )}
            />

            <Route
              path={"/gcash_submid"}
              render={() => (
                <Main>
                  <GCashSubMidInfo />
                </Main>
              )}
            />

            <Route
              path={"/mobile/gcash_submid"}
              render={() => (
                <MobileMain title={"GCash Sub MID"}>
                  <GCashSubMidInfo />
                </MobileMain>
              )}
            />

            <Route
              path={"/test/integration"}
              render={() => (
                <Main>
                  <Developer />
                </Main>
              )}
            />
            <Route
              path={"/test/account_settings"}
              render={() => (
                <Main>
                  <Account />
                </Main>
              )}
            />
            <Route
              path={"/fraud_management"}
              render={() => (
                <Main>
                  <FraudManagement />
                </Main>
              )}
            />
            <Route
              path={"/system_notification"}
              render={() => (
                <Main>
                  <SystemNotification />
                </Main>
              )}
            />
            <Route
              path={"/response_log"}
              render={() => (
                <Main>
                  <ResponseLog />
                </Main>
              )}
            />

            <Route
              path={"/status_endorsement"}
              render={() => (
                <Main>
                  <Endorsement />
                </Main>
              )}
            />

            <Route
              path={"/user_endorsement"}
              render={() => (
                <Main>
                  <UserEndorsement />
                </Main>
              )}
            />

            <Route
              path={"/multiwallet"}
              render={() => (
                <Main>
                  <MasterWalletPage />
                </Main>
              )}
            />

            <Route
              path={"/wallet/add_money"}
              render={() => (
                <Main>
                  <AddMoney />
                </Main>
              )}
            />

            <Route
              path={"/wallet/transfer_money"}
              render={() => (
                <Main>
                  <TransferMoney />
                </Main>
              )}
            />

            <Route
              path={"/user_history/subwallet/:id"}
              render={() => (
                <Main>
                  <SubwalletUserHistory />
                </Main>
              )}
            />

            <Route
              path={"/open_finance"}
              render={() => (
                <Main>
                  <OpenFinance />
                </Main>
              )}
            />

            <Route
              path={"/my_profile"}
              render={() => (
                <Main>
                  <MyProfile />
                </Main>
              )}
            />

            <Route
              path={"/edit_permissions"}
              render={() => (
                <Main>
                  <EditPermission />
                </Main>
              )}
            />

            <Route
              path={"/user_feedback"}
              render={() => (
                <Main>
                  <UserFeedback />
                </Main>
              )}
            />

            <Route
              path={"/user_feedback_summary"}
              render={() => (
                <Main>
                  <FeedbackSummaryReports />
                </Main>
              )}
            />

            <Route
              path={"/payment_request/mobile/how_to_use"}
              render={() => (
                <MobileMain title={"How to use Payment Request"}>
                  <PaymentRequestTutorial />
                </MobileMain>
              )}
            />

            <Route
              path={"/payout/mobile/how_to_use"}
              render={() => (
                <MobileMain title={"How to use Payout"}>
                  <PayoutTutorial />
                </MobileMain>
              )}
            />

            <Route
              path={"/test/subwallet/kyc/:id/"}
              component={KYCIndividual}
            />
            <Route path={"/subwallet/kyc/:id/"} component={KYCIndividual} />
            <Route exact path={"/:trade_name/test/"} component={QrDetails} />
            <Route exact path="/:trade_name" component={QrDetails} />
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      );
    } else {
      return (
        <Router history={history}>
          <Switch>
            <Route
              exact
              path={"/login"}
              render={() => (
                <SubUserLoginSignup>
                  <Login />
                </SubUserLoginSignup>
              )}
            />

            <Route
              exact
              path={"/test/login"}
              render={() => (
                <SubUserLoginSignup>
                  <Login />
                </SubUserLoginSignup>
              )}
            />

            <Route
              exact
              path={"/signup"}
              render={() => (
                <SubUserLoginSignup>
                  <SignUp />
                </SubUserLoginSignup>
              )}
            />

            <Route
              exact
              path={"/test/signup"}
              render={() => (
                <SubUserLoginSignup>
                  <SignUp />
                </SubUserLoginSignup>
              )}
            />

            <Route
              exact
              path={"/forgot_password"}
              render={() => (
                <SubUserLoginSignup>
                  <ForgotPassword />
                </SubUserLoginSignup>
              )}
            />

            <Route
              exact
              path={"/test/forgot_password"}
              render={() => (
                <SubUserLoginSignup>
                  <ForgotPassword />
                </SubUserLoginSignup>
              )}
            />

            <Route
              exact
              path={"/password_expiry"}
              render={() => (
                <SubUserLoginSignup>
                  <PasswordExpiry />
                </SubUserLoginSignup>
              )}
            />

            <Route
              path={"/dashboard"}
              render={() => (
                <Main>
                  <Dashboard />
                </Main>
              )}
            />

            <Route
              path={"/test/dashboard"}
              render={() => (
                <Main>
                  <Dashboard />
                </Main>
              )}
            />

            <Route
              path={"/orders"}
              render={() => (
                <Main>
                  <Orders />
                </Main>
              )}
            />
            <Route
              path={"/funds"}
              render={() => (
                <Main>
                  <Funds />
                </Main>
              )}
            />

            <Route
              path={"/account_settings"}
              render={() => (
                <Main>
                  <Account />
                </Main>
              )}
            />

            <Route
              path={"/complete_profile"}
              render={() => <CompleteProfile />}
            />

            <Route
              path={"/test/complete_profile"}
              render={() => <CompleteProfile />}
            />

            <Route
              path={"/multiwallet"}
              render={() => (
                <Main>
                  <MasterWalletPage />
                </Main>
              )}
            />

            <Route
              path={"/test/multiwallet"}
              render={() => (
                <Main>
                  <MasterWalletPage />
                </Main>
              )}
            />

            <Route
              path={"/test/orders"}
              render={() => (
                <Main>
                  <Orders />
                </Main>
              )}
            />
            <Route
              path={"/funds_enterprise"}
              render={() => (
                <Main>
                  <Funds />
                </Main>
              )}
            />

            {/* Merchant Maker Route */}
            <Route
              path={"/transfer"}
              render={() => (
                <Main>
                  <Transfer />
                </Main>
              )}
            />

            <Route 
              path={"/remittances/:id"}
              render={() => (
                <Main>
                  <MerchantRemittanceView />                  
                </Main>
              )}
            />

            <Route 
              path={"/remittances"}
              render={() => (
                <Main>
                  <MerchantRemittances />                  
                </Main>
              )}
            />

            

            {/* Merchant Approver Routes */}
            <Route
              path={"/transfer_requests"}
              render={() => (
                <Main>
                  <ApproverTransfers />
                </Main>
              )}
            />

            <Route
              path={"/limit_requests"}
              render={() => (
                <Main>
                  <ApproverLimits />
                </Main>
              )}
            />

            {/* Merchant Admin Routes */}
            <Route
              path={"/limits_management"}
              render={() => (
                <Main>
                  <AdminLimits />
                </Main>
              )}
            />

            <Route
              path={"/peer_management"}
              render={() => (
                <Main>
                  <AdminUserManagement />
                </Main>
              )}
            />

            <Route
              path={"/fund_transfer_management"}
              render={() => (
                <Main>
                  <FundTransfers />
                </Main>
              )}
            />

            {/* Revenue Wallets Dashboard Route */}
            <Route
              path={"/revenue_wallet"}
              render={() => (
                <Main>
                  <RevenueWallet />
                </Main>
              )}
            />

            <Route
              path={"/recon"}
              render={() => (
                <Main>
                  <ReconEnterprise />
                </Main>
              )}
            />

            <Route
              path={"/integration"}
              render={() => (
                <Main>
                  <Developer />
                </Main>
              )}
            />

            <Route
              path={"/wallet/add_money"}
              render={() => (
                <Main>
                  <AddMoney />
                </Main>
              )}
            />

            <Route
              path={"/test/wallet/add_money"}
              render={() => (
                <Main>
                  <AddMoney />
                </Main>
              )}
            />

            <Route
              path={"/wallet/transfer_money"}
              render={() => (
                <Main>
                  <TransferMoney />
                </Main>
              )}
            />

            <Route
              path={"/bux_wallet"}
              render={() => (
                <Main>
                  <SubwalletMainPage />
                </Main>
              )}
            />

            <Route
              path={"/test/bux_wallet"}
              render={() => (
                <Main>
                  <SubwalletMainPage />
                </Main>
              )}
            />

            <Route
              path={"/test/wallet/transfer_money"}
              render={() => (
                <Main>
                  <TransferMoney />
                </Main>
              )}
            />

            <Route
              exact
              path={"/forgot_password"}
              render={() => (
                <LoginAndSignUp>
                  <ForgotPassword />
                </LoginAndSignUp>
              )}
            />

            <Route
              exact
              path={"/update_mobile/sub_wallet/"}
              render={() =>
                isMobile ? (
                  <ChangeNumber />
                ) : (
                  <LoginAndSignUp>
                    <ChangeNumber />
                  </LoginAndSignUp>
                )
              }
            />

            <Route
              path={"/pricing"}
              render={() => (
                <Main>
                  <Pricing />
                </Main>
              )}
            />

            <Route
              path={"/notifications"}
              render={() => (
                <Main>
                  <Notification />
                </Main>
              )}
            />

            <Route
              exact
              path={"/resend_verification"}
              render={() => (
                <LoginAndSignUp>
                  <ResendVerification />
                </LoginAndSignUp>
              )}
            />

            <Route
              path={"/mobile/bux_wallet"}
              render={() => (
                <MobileMain title="BUx Wallet">
                  <MultiwalletMobile />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/dashboard"}
              render={() => (
                <MobileMain title={"Dashboard"}>
                  <MobileDashboard />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/pricing"}
              render={() => (
                <MobileMain title={"Pricing"}>
                  <MobilePricing />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/more"}
              render={() => (
                <Main>
                  <MobileMore />
                </Main>
              )}
            />

            <Route
              path={"/mobile/funds"}
              render={() => (
                <MobileMain>
                  <Funds />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/orders"}
              render={() => (
                <MobileMain>
                  <MobileOrders />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/generate_code"}
              render={() => (
                <MobileMain title="Generate Link">
                  <GenerateCode />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/notifications"}
              render={() => (
                <MobileMain title={"Notifications"}>
                  <Notification />
                </MobileMain>
              )}
            />

            <Route
              exact
              path={"/mobile/signup"}
              render={() => (
                <SubUserLoginSignup>
                  <SignUp />
                </SubUserLoginSignup>
              )}
            />

            <Route
              path={"/mobile/bux_wallet"}
              render={() => (
                <MobileMain title="Bux Wallet">
                  <MultiwalletMobile />
                </MobileMain>
              )}
            />

            <Route
              path={"/mobile/wallet"}
              render={() => (
                <MobileMain title={"Wallet"}>
                  <MobileWallet />
                </MobileMain>
              )}
            />

            <Route
              path={"/my_profile"}
              render={() => (
                <Main>
                  <MyProfile />
                </Main>
              )}
            />

            <Route
              path={"/edit_permissions"}
              render={() => (
                <Main>
                  <EditPermission />
                </Main>
              )}
            />

            <Route
              path={"/payment_request/mobile/how_to_use"}
              render={() => (
                <MobileMain title={"How to use Payment Request"}>
                  <PaymentRequestTutorial />
                </MobileMain>
              )}
            />

            <Route
              path={"/payout/mobile/how_to_use"}
              render={() => (
                <MobileMain title={"How to use Payout"}>
                  <PayoutTutorial />
                </MobileMain>
              )}
            />
            <Route
              path={"/test/user_history/subwallet/:id"}
              render={() => (
                <Main>
                  <SubwalletUserHistory />
                </Main>
              )}
            />

            <Route
              path={"/test/subwallet/kyc/:id/"}
              component={KYCIndividual}
            />
            <Route path={"/subwallet/kyc/:id/"} component={KYCIndividual} />
            <Route
              exact
              path={"/upgrade_enterprise/"}
              component={MainEnterprise}
            />
            <Route exact path={"/mobile/login"} component={Login} />
            <Route exact path={"/checkout/:uuid/"} component={Checkout} />
            <Route
              exact
              path={"/cybersource/:uuid/"}
              component={SecureCheckout}
            />
            <Route exact path={"/grabpay/:uuid/"} component={GrabPay} />
            <Route exact path={"/gcash/:uuid/"} component={Gcash} />
            <Route exact path={"/xendit/:uuid/"} component={XenditC} />
            <Route exact path={"/payment/:uuid/"} component={PaymentDetails} />
            <Route
              exact
              path={"/test/payment/:uuid/"}
              component={PaymentDetails}
            />
            <Route exact path={"/test/checkout/:uuid/"} component={Checkout} />
            <Route exact path={"/test/xendit/:uuid/"} component={XenditC} />
            <Route
              exact
              path={"/test/bux_payment/:uuid/"}
              component={TestModePayment}
            />
            <Route
              exact
              path={"/password_reset/:id/:code/"}
              component={ResetPassword}
            />
            <Route
              exact
              path={"/password_update/:id/:code/"}
              component={ResetPassword}
            />
            <Route exact path={"/terms"} component={Terms} />
            <Route exact path={"/privacy_policy"} component={PrivacyPolicy} />
            <Route
              exact
              path={"/terms_and_privacy"}
              component={TermsAndPrivacy}
            />
            <Route exact path={"/link_bank"} component={NewBank} />
            <Route
              exact
              path={"/email/verifying/:id/:verification_code/"}
              component={VerifyingEmailForm}
            />
            <Route exact path={"/email/verify"} component={VerifyEmail} />
            <Route
              exact
              path={"/mobile/resend_verification"}
              component={ResendVerification}
            />
            <Route
              exact
              path={"/mycheckout/:trade_name/"}
              component={QrDetails}
            />
            <Route
              exact
              path={"/mycheckout/:trade_name/test/"}
              component={QrDetails}
            />
            <Route exact path={"/"} component={QrDetails} />
            <Route
              exact
              path={"/test/cybersource/:uuid/"}
              component={SecureCheckout}
            />
            <Route exact path={"/test/qr"} component={QrDetails} />
            <Route
              exact
              path={"/survey_enterprise"}
              component={EnterpriseSurvey}
            />
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      );
    }
  }
}

export default App;
