import React, { useEffect } from 'react';
import { Input, Select, Icon } from 'antd';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import TextInput from '../../../kyb/forms/custom_component/textInput'
import { METHOD, OTC, WB, EW, CARD, BNPL } from '../channel_const'
import BankInfo from '../../../../static/icons/bank_info.svg';
import UpayList from '../../../checkout/NewCheckout/upayList';
import { PCHC_LIST, INSTAPAY_LIST } from '../../../constants/constants'

const QRCustomerDetails = props => {
    
    const { Option } = Select;
    const { TextArea } = Input; 
    const {
        headerDetails, customPlaceholder,
        custName, setCustName,
        custEmail, setCustEmail,
        custMobile, setCustMobile,
        additional, setAdditional,
        additional2, setAdditional2,
        data, paymentType, handlePaymentChange,
        testMode, disabledChannels,
        paymentChannel, amount,
        handleChannelChange,
        leaveNote, setLeaveNote, Icons,
        requireAdd1, requireAdd2, hasClicked
    } = props;

    const [bankModalVisibility, setBankModalVisibility] = React.useState(false)
    const [wbFiltered, setWbFiltered] = React.useState([]);
    const [ewFiltered, setEwFiltered] = React.useState([]);
    const [instapayPaymentChannel, setInstapayPaymentChannel] = React.useState();
    
    useEffect(() => {
        if (data) {
            const instapayChannelsToRemove = [
                'instapay',
                'instapay_allbank',
            ].filter(item => item != data.instapay_payment_channel);
            setWbFiltered(WB.filter(item => !(instapayChannelsToRemove.includes(item.code))));
            setEwFiltered(EW.filter(item => !(instapayChannelsToRemove.includes(item.code))));
            setInstapayPaymentChannel(data.instapay_payment_channel);
        }
    }, [data]);

    const xendit_enabled = data && data.cc_enabled === 'Xendit'
    const ubp_enabled = data && data.cc_enabled === 'UnionBank'

    let channelDisabledCount = 0

    let mapObject = paymentType[0] === 'OTC' ? OTC :
    paymentType[0] === 'WB' ? wbFiltered :
        paymentType[0] === 'EW' ? ewFiltered :
            paymentType[0] === 'CARD' ? CARD :
                paymentType[0] === 'BNPL' ? BNPL : [];

    const channel_option = mapObject.map((item, key) => {
        if (disabledChannels.includes(item.code)) {
            channelDisabledCount++
        }

        return (
            <Option
                style={{width: '100%'}}
                key={key}
                value={[item.code, item.name, key]}
                hidden={(disabledChannels && disabledChannels.includes(item.code)) ||
                    // (testMode && (item.code === 'instapay' || item.code === 'paygate')) ||
                    (data && data.user_role === "ME" && (item.code === "MLH" || item.code === 'i2i')) ||
                    (!xendit_enabled && item.code === 'visamc') ||
                    (!ubp_enabled && item.code === 'ubpcs') ||
                    ((['bdo_instapay', 'ps_instapay', 'sb_instapay']).includes(item.code) && disabledChannels.includes(instapayPaymentChannel))
                    // (data && data.user_role === "SW" && (item.code === "instapay" || item.code === 'paygate'))
                    }
                    >
                {item.name}
            </Option>
        )
    })

    const hideWBMethod = () => {
        let channels = wbFiltered.length;
        wbFiltered.map((ch, i) => {
            if (((disabledChannels && disabledChannels.includes(ch.code))) || ((['bdo_instapay', 'ps_instapay', 'sb_instapay']).includes(ch.code) && disabledChannels.includes(instapayPaymentChannel))){
                channels = channels - 1;
            }
        }) 
        if (channels === 0) {
            return true;
        }
        return false;
    }

    const hideEWMethod = () => {
        let channels = ewFiltered.length;
        ewFiltered.map((ch, i) => {
            if ((disabledChannels && disabledChannels.includes(ch.code))){
                channels = channels - 1;
            }
        }) 
        if (channels === 0) {
            return true;
        }
        return false;
    }

    const hideCDMethod = () => {
        let channels = CARD.length;
        CARD.map((ch, i) => {
            if ((disabledChannels && disabledChannels.includes(ch.code)) ||
                (!xendit_enabled && ch.code === 'visamc') ||
                (!ubp_enabled && ch.code === 'ubpcs') ){

                channels = channels - 1;
            }
        }) 
        if (channels === 0) {
            return true;
        }
        return false;
    }

    const emailError = (email) => {
        const emailFormat = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        if (!email || !email.length) { 
            return false;
        }
        if(emailFormat.test(email)){
            return(false);
        }
        else{
            return(true);
        }
    }
    

    return(
        <div className="qr-cust-info top-20" align="left">
            {headerDetails(`Customer Information`)}
            <div>
                <Grid container>
                    <Grid item lg={12} sm={12} xs={12}>
                        {customPlaceholder(`Customer Name`)}
                        <Input
                            placeholder="Customer Name"
                            size="large"
                            style={{ height: '40px' }}
                            name="name" value={custName}
                            onChange={(e) => setCustName(e.target.value)} />
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                        <div style={{ width: !isMobile ? '95%' : '100%' }}>
                            {customPlaceholder(`Email`)}
                            <Input placeholder={"Email Address"} type="email" size="large" 
                                name={"email"} value={custEmail} onChange={(e) => setCustEmail(e.target.value)} height="40px" 
                                style={{
                                    borderColor: emailError(custEmail) ? '#E24C4C' : ''
                                }} />
                            <div hidden={!emailError(custEmail)} align="left" style={{marginTop: 3, color: '#E24C4C', fontSize: '12px', lineHeight: '12px', marginBottom: '5px'}}>{'Email format is invalid'}</div>
                        </div>
                    </Grid>
                    <Grid item lg={6} sm={12} xs={12}>
                        <div style={{ width: '100%' }}>
                            {customPlaceholder(`Mobile Number`)}
                            <Input
                                placeholder="9123456789"
                                type="number"
                                size="large"
                                addonBefore={'+63'}
                                name="mobile" value={custMobile}
                                onChange={(e) => { if (e.target.value.length <= 10) { setCustMobile(e.target.value) } }} />
                        </div>
                    </Grid>
                </Grid>

                <div hidden={data && (!data.show_field_1 && !data.show_field_2)} className="top-20">
                    {headerDetails(`Additional Details`)}
                    <div className="top-12">
                        <div hidden={data && !data.show_field_1}>
                            {customPlaceholder(data && data.field_1)}
                            <Input
                                placeholder={data && data.field_1}
                                size="large"
                                value={additional}
                                style={{ height: '40px', border: requireAdd1 && hasClicked && '1px solid #C0492C' }}
                                name="addtional"
                                onChange={(e) => setAdditional(e.target.value)} />
                            <label hidden={!requireAdd1 || !hasClicked} style={{fontSize: 12, color: '#C0492C' }}>This field is required</label>
                        </div>

                        <div hidden={data && !data.show_field_2}>
                            {customPlaceholder(data && data.field_2)}
                            <Input
                                placeholder={data && data.field_2}
                                size="large"
                                value={additional2}
                                style={{ height: '40px', border: requireAdd2 && hasClicked && '1px solid #C0492C' }}
                                name="addtional"
                                onChange={(e) => setAdditional2(e.target.value)} />
                            <label hidden={!requireAdd2 || !hasClicked} style={{fontSize: 12, color: '#C0492C' }}>This field is required</label>
                        </div>
                    </div>
                </div>

                <div className="top-20">
                    {headerDetails(`Payment Method`)}
                    <Grid container>
                        <Grid item lg={6} sm={12} xs={12}>
                            <div style={{ width: !isMobile ? '95%' : '100%' }}>
                                {customPlaceholder(`Payment Method`)}
                                <Select
                                    style={{ width: !isMobile ? '95%' : '100%', color: '#2b2d32'}}
                                    size="large"
                                    value={paymentType[1]}
                                    optionFilterProp="children"
                                    suffixIcon={
                                        <Icon type="caret-down" style={{color: "#000000"}} />
                                    }
                                    onChange={e => handlePaymentChange(e)}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {
                                        METHOD.map((item, key) => {
                                            return(
                                                <Option 
                                                    key={key} 
                                                    value={[item.code, item.name]} 
                                                    hidden={
                                                            (data && (!testMode && (!data.cc_enabled && item.code == "CARD") || 
                                                                    (disabledChannels.includes('billease') && item.code == "BNPL")) ||
                                                                    ((amount && amount < 500) && item.code == "BNPL") ||
                                                                    data && data.user_role === 'ME' && item.code === 'BNPL' || 
                                                                    data && data.user_role === 'SW' && item.code === 'CARD' && data.has_cashbux_privilege === false ||
                                                                    // data && data.user_role === 'SW' && item.code === 'BNPL' ||
                                                                    (item.code === 'WB' && hideWBMethod()) ||
                                                                    (item.code === 'EW' && hideEWMethod()) ||
                                                                    (item.code === 'CARD' && hideCDMethod()))
                                                        }
                                                    >
                                                        {item.name}
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        </Grid>

                        <Grid item lg={6} sm={12} xs={12}>
                            <div style={{ width: '100%' }}>
                                {customPlaceholder(`Select Channel`)}
                                <Select
                                    disabled={!amount}
                                    placeholder="Channel"
                                    style={{ width: '100%', height: '40px' }}
                                    size="large"
                                    value={paymentChannel[1]}
                                    optionFilterProp="children"
                                    suffixIcon={
                                        <Icon type="caret-down" style={{color: "#000000"}} />
                                    }
                                    onChange={e => handleChannelChange(e)}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {channel_option}
                                </Select>
                            </div>
                        </Grid>
                    </Grid>
                    {paymentChannel[0] == instapayPaymentChannel || paymentChannel[0] == "paygate" ?
                        (<div className="top-20" align="center">
                            <img 
                                style={{width: isMobile && '90%', }}
                                src={
                                paymentChannel[0] == instapayPaymentChannel ?
                                    Icons.InstapayList :
                                        paymentChannel[0] == "paygate" ? 
                                            Icons.PaygateList : ""
                            } alt={paymentChannel[0]}/>

                            {
                                paymentChannel[0] == instapayPaymentChannel &&
                                    <a href onClick={()=>setBankModalVisibility(true)} 
                                        style={{
                                            fontWeight: 'bold', 
                                            marginLeft: '-56px', 
                                            marginTop: '-12px' }}>
                                        <span className="right-8" style={{color: "#2b2d33"}} >
                                            and
                                        </span> 
                                        <span style={{ 
                                            color: '#F5922F', 
                                            textDecoration: 'underline', }}>
                                                more!
                                        </span>
                                    </a>
                            }
             
                        </div>
                        ):
                    ""}
                    <Grid item lg={12} sm={12} xs={12}>
                        {customPlaceholder(`Leave a Note`)}
                        <TextArea
                            placeholder="Type here"
                            rows={1}
                            size="large"
                            name="note" value={leaveNote}
                            onChange={(e) => setLeaveNote(e.target.value)} />
                    </Grid>
                </div>
            </div>

            <UpayList instapayPaymentChannel={instapayPaymentChannel} channel={paymentChannel[0]} modalOpen={bankModalVisibility} modalClose={setBankModalVisibility} instaList={INSTAPAY_LIST} pchcList={PCHC_LIST} />

        </div>
    )
}

export default QRCustomerDetails;
