import axios from 'axios'
import { Handle401 } from '../handle401/handle401'
import { USER_TYPE } from '../constants/constants';

const authorizedAxiosRequest = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    });
}

const unauthorizedAxiosRequest = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });
}

const multiwalletServices = {
    // done in test mode
    getSubwalletUsers: async (isTestMode = false, search = '', start = '', end = '', status = '', kycLevel = null, pageSize = 10, page = 1) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/' : '/api/multiwallet/'
            const res = await authorizedAxiosRequest().get(`${endpoint}?start=${start}&end=${end}&search=${search}&status=${status}${kycLevel && `&kyc_level=${kycLevel}`}&page=${page}&page_size=${pageSize}`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getEnterpriseDetails: async (isTestMode, trade_name) => {
        let endpoint = isTestMode ? '/api/sandbox/enterprise/' : '/api/enterprise/'
        let url = process.env.REACT_APP_API_URL + endpoint + `${trade_name}/details/`;
        try {
            const res = await axios.get(url);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    // done in test mode
    getMultiwalletAnalytics: async (isTestMode, start, end) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/analytics/' : '/api/multiwallet/analytics/'
            const res = await authorizedAxiosRequest().get(`${endpoint}?start=${start}&end=${end}&summary=Custom`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    updateMultiwalletBanner: async (isTestMode, params) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/settings/' : '/api/multiwallet/settings/'
            const res = await authorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getTransferHistory: async (isTestMode, type = '', search = '', start = '', end = '', status = null, summary = 'All', pageSize = 10, page = 1) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/transfers/' : '/api/multiwallet/transfers/'
            const res = await authorizedAxiosRequest().get(`${endpoint}?type=${type}&search=${search}&start=${start}&end=${end}${status && `&status=${status}`}&summary=${summary}&page_size=${pageSize}&page=${page}`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    batchTransferMoney: async (isTestMode, params, transfer_money_uid) => {
        try {
            //params = { ...params, transfer_money_uid: transfer_money_uid};
            //console.log(params)
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/transfers/batch/' : '/api/multiwallet/transfers/batch/?transfer_money_uid='+transfer_money_uid
            const res = await authorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    //done in test mode
    createSubWallet: async (isTestMode, params) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/' : '/api/multiwallet/'
            const res = await authorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    //Not used
    getPaymentRequest: async (status, start = '', end = '', summary = 'All', token) => {
        try {
            const res = await authorizedAxiosRequest().get(`/api/orders/?type=${status}&start=${start}&end=${end}&summary=${summary}&page_size=10&token=${token}`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    getSubwalletTransferTranxById: async (isTestMode, id, type, status = '', search = '', start = '', end = '', summary = 'All', pageSize = 10, page = 1) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/transfers/' : '/api/multiwallet/transfers/'
            const res = await authorizedAxiosRequest().get(`${endpoint}${id}/?type=${type}&status=${status}&search=${search}&start=${start}&end=${end}&summary=${summary}&pageSize=${pageSize}&page=${page}`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    transferMoney: async (isTestMode, params, transfer_money_uid) => {
        try {

            params = { ...params, transfer_money_uid: transfer_money_uid};
            
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/transfers/' : '/api/multiwallet/transfers/'
            const res = await authorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    submitOtp: async (params) => {
        const otpMode = Object.keys(params).length <= 1 ? 'email_otp' : 'otp';
        try {
            const response = await authorizedAxiosRequest().post(`/api/${otpMode}/`, params);
            return response.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
            else {
                return error.response.data;
            }
        }
    },
    getOtpEmail: async (subj) => {
        try {
            const response = await authorizedAxiosRequest().get(`/api/email_otp/?subject=${subj}`);
            return response.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getOTP: async (isTestMode) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/otp/' : '/api/otp/'
            const response = await authorizedAxiosRequest().get(endpoint);
            return response.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getAddMoneyHistoryById: async (isTestMode, id, status = 'Paid', search = '', start = '', end = '', summary = 'All', pageSize = 10, page = 1, token,) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/history/' : '/api/multiwallet/history/' 
            const res = await authorizedAxiosRequest().get(`${endpoint}${id}/add_money/?type=${status}&search=${search}&start=${start}&end=${end}&summary=${summary}&page_size=${pageSize}&page=${page}&token=${token}`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getSubwalletPaymentReqHistory: async (isTestMode, id, status = 'Paid', search = '', start = '', end = '', summary = 'All', token, pageSize = 10, page = 1) => {
        try {
            let endpoint = isTestMode ? `/api/sandbox/multiwallet/history/${id}` : `/api/multiwallet/history/${id}`
            const res = await authorizedAxiosRequest().get(`${endpoint}/orders/?type=${status}&search=${search}&start=${start}&end=${end}&summary=${summary}&token=${token}&pageSize=${pageSize}&page=${page}`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    createSubwalletByBatch: async (isTestMode, params) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/batch/' : '/api/multiwallet/batch/'
            const res = await authorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    validateSubwalletByBatch: async (isTestMode, params) => {
        try {
            let endpoint = isTestMode ? `/api/sandbox/multiwallet/batch/validate/` : `/api/multiwallet/batch/validate/`
            const res = await authorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    getAddMoneyHistory: async (isTestMode, token, type, page, page_size = 10, start = "", end = "", summary = "Today", search = "", payment_methods = "", is_export = "", email = "") => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/add_money/' : '/api/multiwallet/add_money/' 
            const res = await authorizedAxiosRequest().get(`${endpoint}?token=${token}&type=${type}&page=${page}&page_size=${page_size}&start=${start}&end=${end}&summary=${summary}&search=${search}&payment_methods=${payment_methods}&is_export=${is_export}&email=${email}`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },

    cancelPayment: async (isTestMode, id) => {
        try {
            let params = {
                id,
                reason: "--"
            }
            let endpoint = isTestMode ? '/api/sandbox/cancel/' : '/api/orders/cancel_order/'
            const res = await authorizedAxiosRequest().post(endpoint, params)
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    
    getSubwalletPayoutHistory: async (isTestMode, id, status = 'Paid', search = '', start = '', end = '', summary = 'All', token, pageSize = 10, page = 1) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/history/' : '/api/multiwallet/history/'
            const res = await authorizedAxiosRequest().get(`${endpoint}${id}/payout/?type=${status}&search=${search}&start=${start}&end=${end}&summary=${summary}&token=${token}&pageSize=${pageSize}&page=${page}`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getMasterWalletDetails: async (isTestMode) => {
        let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
        let trade_name = window.location.hostname.replace(remove_part, "");
        try {
            let endpoint = isTestMode ? '/api/sandbox/enterprise/' : '/api/enterprise/'
            const res = await authorizedAxiosRequest().get(`${endpoint}${trade_name}/details`);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    exportMasterWalletCsv: async (isTestMode, email, type, tableType = 'all', search = '', start = '', end = '', summary = 'All', kycLevel = null, pageSize = 10, page = 1) => {
        const endpoint = type === 'subwallets' ?
                            isTestMode ? `/api/sandbox/multiwallet/export/?email=${email}` : `/api/multiwallet/export/?email=${email}` 
                            : 
                            isTestMode ? `/api/sandbox/multiwallet/transfers/export/?email=${email}&type=${tableType}` : `/api/multiwallet/transfers/export/?email=${email}&type=${tableType}`;
        try {
            const query_params = type === 'subwallets' ?
                                    kycLevel === "All Time" ?
                                        `&search=${search}&start=${start}&end=${end}`
                                        :
                                        `&search=${search}&start=${start}&end=${end}${kycLevel && `&summary=${summary}&kyc_level=${kycLevel}`}&page_size=${pageSize}&page=${page}`
                                    :
                                    `&search=${search}&start=${start}&end=${end}${kycLevel && `&summary=${summary}&kyc_level=${kycLevel}`}&page_size=${pageSize}&page=${page}`
        
            const res = await authorizedAxiosRequest().get(endpoint + query_params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    validateTransferMoneyCsv: async (isTestMode, params) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/transfers/batch/validate/' : `api/multiwallet/transfers/batch/validate/`
            const res = await authorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    sendRequestPayment: async (isTestMode, params) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/transfers/request/' : `api/multiwallet/transfers/request/`
            const res = await authorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getRequestPaymentDetails: async (isTestMode, uid) => {
        try {
            let endpoint = isTestMode ? `/api/sandbox/payment/${uid}/` : `/api/payment/${uid}/`;
            const res = await unauthorizedAxiosRequest().get(endpoint);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    transferRequestMoney: async (isTestMode, params) => {
        try {
            let endpoint = isTestMode ? '/api/sandbox/multiwallet/transfers/request/process/' : '/api/multiwallet/transfers/request/process/'
            const res = await authorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getPaymentReqUserHistory: async (id, type = 'Paid', search = '', start = '', end = '', summary = 'All', pageSize = 10, page = 1) => {
        try {
            let endpoint = `/api/${id}/orders/?type=${type}&start=${start}&end=${end}&summary=${summary}`;
            const res = await authorizedAxiosRequest().get(endpoint);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    getPayoutUserHistory: async (id) => {
        try {
            let endpoint = `/api/${id}/payouts/`;
            const res = await authorizedAxiosRequest().get(endpoint);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
    convertSubUser: async (params) => {
        try {
            let endpoint = '/api/multiwallet/convert_subuser/'
            const res = await authorizedAxiosRequest().post(endpoint, params);
            return res.data;
        } catch (error) {
            if ((error.response) && (error.response.status == 401)) {
                Handle401()
            }
        }
    },
}

export default multiwalletServices;