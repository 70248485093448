import React from 'react';
import ButtonGroup from 'antd/lib/button/button-group';
import SubwalletTable from './subwallet_table';
import TranxHistoryTable from './transaction_history_table';
import { Button, Icon, message } from 'antd';
import { history } from '../../../../../store/history';
import ExportCsvModal from './modal/exportCsvModal';
import CreateSubWalletModal from '../create_subwallet/create_sub_wallet_modal';
import ImportBatchModal from '../create_subwallet/import_batch_modal';
import UploadCsvModal from '../create_subwallet/upload_csv_modal';
import TablePreviewModal from '../create_subwallet/table_preview_modal';
import { jsonToCSV } from 'react-papaparse';
import { TransferMoneyIcon } from '../../../../../static/images/multiwallet';
import { useLocation } from 'react-router';
import CreateSubSuccessModal from './modal/success_create_subwallet_modal';
import multiwalletServices from '../../../multiwalletServices';
import { useDispatch } from '../../../../../__test__/react-redux-hooks';
import multiwalletActions from '../../../multiwalletActions';
import { segmentStroke } from '../../../../constants/constants';

const template = [
    {
        "First Name": 'Johny',
        "Last Name": 'Bravo',
        "Email": 'johny@sample.com',
        "Mobile Number": '09123456789',
    },
    {
        "First Name": 'John',
        "Last Name": 'Week',
        "Email": 'weekjohn@ubx.ph',
        "Mobile Number": '09221234555',
    },
    {
        "First Name": 'Sam',
        "Last Name": 'Smyth',
        "Email": 'sammy@sample.com',
        "Mobile Number": '09191231232',
    },
]

const MasterWalletTable = (props) => {
    const [createBtnStyle, setCreateBtnStyle] = React.useState({ color: '#1DD28B', backgroundColor: 'transparent' });
    const [selected, setSelected] = React.useState('Sub-wallet')
    const [showExportModal, setShowExportModal] = React.useState(false);
    const [email, setEmail] = React.useState(null);
    const [createSubShow, setCreateSubShow] = React.useState(false);
    const [importBatchShow, setImportBatchShow] = React.useState(false);
    const [uploadCsvShow, setUploadCsvShow] = React.useState(false);
    const [tablePreviewShow, setTablePreviewShow] = React.useState(false);
    const [tableData, setTableData] = React.useState(null);
    const location = useLocation();
    const selectedTab = location.state ? location.state.selectedTab : null;
    const [exportFilters, setExportFilters] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [createSuccessShow, setCreateSuccessShow] = React.useState(false);
    const [createSuccessData, setCreateSuccessData] = React.useState(null);
    const [displayData, setDisplayData] = React.useState(null);
    const dispatch = useDispatch();
    const { buxBalance } = props;
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const segments = localStorage.getItem('segments')

    const ButtonTab = ({ rootStyle }) => {

        const status = ['Sub-wallet', 'Transaction History'];

        const handleClick = (event) => {
            setSelected(event.target.value);
        }

        return (
            <div style={rootStyle}>
                <ButtonGroup>
                    {
                        status.map((btn, i) => {
                            return (
                                <Button
                                    key={btn}
                                    value={btn}
                                    onClick={handleClick}
                                    style={{
                                        ...styles.btnGroup,
                                        color: btn === selected ? '#FFF' : '#2b2d32',
                                        backgroundColor: btn === selected ? segmentStroke[0] : '#FFF',
                                        borderRadius: i === 0 ? '4px 0 0 4px' : i === 1 ? '0 4px 4px 0' : 0
                                    }}
                                >
                                    {btn}
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </div>
        )
    }

    const exportTemplate = () => {
        const csv = jsonToCSV(template);
        const blob = new Blob([csv]);
        const a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
        a.download = "create_wallet_template.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }


    const onCreateBtnHover = () => {
        setCreateBtnStyle({
            color: '#FFF',
            backgroundColor: '#1DD28B'
        });
    }

    const onCreateBtnLeave = () => {
        setCreateBtnStyle({
            color: '#1DD28B',
            backgroundColor: 'transparent'
        });
    }

    const onConfirm = (data) => {
        setTableData(data);
        setUploadCsvShow(false);
        setTablePreviewShow(true);
    }

    const onBatchCreation = async (data) => {
        setIsLoading(true);
        try {
            const res = await multiwalletServices.createSubwalletByBatch(testMode, data);
            if (res.status === 'success') {
                setCreateSuccessData(res);
                setTablePreviewShow(false);
                setCreateSuccessShow(true);

            } else {
                message.error(res.message);
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const setFilters = (filters) => {
        setExportFilters(filters);
    }

    const flatData = (data) => {
        const array = data && Object.keys(data).map((key, i) => {
            return ({
                first_name: data[i]['data']['First Name'],
                last_name: data[i]['data']['Last Name'],
                email: data[i]['data']['Email'],
                contact: data[i]['data']['Mobile Number'],
            })
        });
        return array;
    }

    const onUploadCsv = async (results) => {
        try {
            const res = await multiwalletServices.validateSubwalletByBatch(testMode, flatData(results));
            arrangeData(res, flatData(results));
        } catch (error) {
            console.log(error);
        }
    }

    const arrangeData = (res, data) => {
        let goodData = new Array;
        res && res.errors && data.map((arr, i) => {
          arr['remarks'] = res.errors[i][0];
          goodData.push(arr);
        });
        setDisplayData(goodData);
      }

    const exportEmail = async () => {
        const type = selected === 'Sub-wallet' ? 'subwallets' : 'tranx_history';
        try {
            const res = await multiwalletServices.exportMasterWalletCsv(testMode, email, type, ...exportFilters);
            console.log('EXPORT_EMAIL', res);
            if (res.status === 'success') {
                message.success(res.message);
                setShowExportModal(false);
            } else {
                message.error('Error please try again.');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getMultiwalletAnalytics = async (start = '', end = '') => {
        try {
            const res = await multiwalletServices.getMultiwalletAnalytics(testMode, start, end);
            if (res) {
                dispatch(multiwalletActions.setBalances(res.balances));
                const analytics = {
                    master_to_sub: res.master_to_sub,
                    sub_to_master: res.sub_to_master,
                    total_sub_users: res.sub_users
                }
                dispatch(multiwalletActions.setAnalytics(analytics));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onTransferSuccess = () => {
        getMultiwalletAnalytics();
    }

    React.useEffect(() => {
        if (selectedTab) {
            setSelected('Transaction History');
            history.replace(`/multiwallet`, {});
        }
    }, [selected])

    return (
        <div style={styles.root}>
            <div style={styles.btnContainer}>
                <ButtonTab />
                <div>
                    <button
                        // onClick={exportCsv}
                        hidden={selected !== 'Sub-wallet'}
                        name='exportBtn'
                        onMouseOver={onCreateBtnHover}
                        onMouseLeave={onCreateBtnLeave}
                        className={`outline-btn--${segments} btn-height right-8 `}
                        onClick={() => setCreateSubShow(true)}
                    >
                        <Icon type="plus" className={`right-4`} /> Create sub-wallet
                    </button>

                    <button className={`outline-btn--${segments} btn-height right-8`} onClick={() => history.push('wallet/transfer_money')} >
                        <img alt='No Image' src={TransferMoneyIcon} style={{ marginTop: '-3px', marginRight: 8 }} />
                        Transfer money
                    </button>
                    <button className={`outline-btn--${segments} btn-height`}
                        onClick={() => setShowExportModal(true)} >
                            <Icon type='download' className="right-4" /> Download CSV
                    </button>
                </div>
            </div>
            <div style={styles.body}>
                {
                    selected === 'Sub-wallet' && selectedTab !== 'tranx' ?
                        <SubwalletTable segments={segments} setFilters={setFilters} buxBalance={buxBalance} onTransferSuccess={onTransferSuccess} />
                        :
                        <TranxHistoryTable segments={segments} setFilters={setFilters} />
                }
            </div>
            <ExportCsvModal segments={segments} visible={showExportModal} onClose={() => setShowExportModal(false)}
                onSend={exportEmail} onChange={(e) => setEmail(e)}
            />
            <CreateSubWalletModal balance={props.balance} segments={segments} visible={createSubShow} onClose={() => setCreateSubShow(false)} onImportBatch={() => { setImportBatchShow(true); setCreateSubShow(false); }} />
            <ImportBatchModal segments={segments} visible={importBatchShow} onClose={() => setImportBatchShow(false)} onSkipStep={() => setUploadCsvShow(true) / setImportBatchShow(false)} exportTemplate={exportTemplate} />
            <UploadCsvModal segments={segments} visible={uploadCsvShow} onClose={() => setUploadCsvShow(false)} exportTemplate={exportTemplate} onConfirm={onConfirm} onUploadCsv={onUploadCsv} />
            <TablePreviewModal segments={segments} visible={tablePreviewShow} onClose={() => setTablePreviewShow(false)}
                onConfirm={onBatchCreation} data={displayData} isLoading={isLoading} />
            <CreateSubSuccessModal segments={segments} visible={createSuccessShow} onClose={() => setCreateSuccessShow(false)} details={createSuccessData} />
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
        padding: '40px 0'
    },
    body: {
        backgroundColor: '#FFF',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        margin: '38px 0'
    },
    btnGroup: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        height: 40,
        padding: '0 32px',
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    createBtn: {
        fontSize: 16,
        fontWeight: '600',
        height: 40,
        borderColor: '#1DD28B',
        marginRight: 8,
    },
    exportBtn: {
        fontSize: 16,
        fontWeight: '600',
        padding: '8px, 16px, 8px, 16px',
        border: '1px solid #1DD28B',
        height: 40,
        // marginRight: 8,
        color: '#FFF',
        backgroundColor: '#1DD28B'
    },
}

export default MasterWalletTable;
