import React from 'react';
import SettingButtons from './setting_buttons';
import MyCheckout from './my_checkout';
import MultiwalletSettings from './multiwallet';
import TransactionFees from './transaction_fees';
import EmailNotif from './email_notif';
import SmsNotif from './sms_notif';
import UserAccess from './user_access';
import StatementOfTransactions from './statement_of_transactions';
import BankAccount from '../../components/account_settings/bank_account/bankAccount';
import * as Icons from '../../static/new_segment/icons';
import { isMobile } from 'react-device-detect';
import { Icon, Typography } from 'antd';
import { history } from '../../store/history';
import MetaTag from '../../components/meta_tag/meta_tag';

const AccountSettings = () => {
    // const [hash, setHash] = React.useState(!isMobile ? '#my_checkout' : '#');
    const segments = localStorage.getItem('segments');
    const hash = window.location.hash;
    const isMultiwallet = localStorage.getItem("is_multiwallet") === 'true';
    const isChild = localStorage.getItem("is_child") === 'true';
    const userType = localStorage.getItem('userType');
    const subuserRole = localStorage.getItem('subuser_role');
    const kybRequired = localStorage.getItem('kybRequired') === 'true';
    const has_cashbux_privilege = localStorage.getItem('has_cashbux_privilege') === 'true';
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const subCheckout = localStorage.getItem('sub_checkout_enabled') === 'true';
    const defined_wallet_settings = JSON.parse(localStorage.getItem('defined_wallet_settings'));
    const checkout_enabled = defined_wallet_settings.checkout_enabled;
    const hideSmsNotifications = localStorage.getItem("sms_notifications_enabled") !== 'true';

    // checking for enabled/disabled/hidden settings tab depending on account status
    const kybStep = localStorage.getItem("KYBStep");
    const approvedStep = [11, 12]
    const level = localStorage.getItem("Level");
    const hideTab = (
                        (userType === "CO" && localStorage.getItem("KYBStep") < 11) && 
                        (
                        (!testMode || localStorage.getItem("userType") === "CO") && 
                        (!approvedStep.includes(parseInt(kybStep)) && !testMode)
                        )
                    ) 
                    || 
                    ((level <= 0 && userType == 'ME') ||
                    (level <= 0 && userType == 'SW' && subuserRole == 'ME') || 
                    (level != 5 && userType == 'SW' && subuserRole == 'CO' && kybRequired) && !testMode) 

    const status = [
        {
            name: 'My Checkout',
            icon: Icons.SettingPayout,
            hash: '#my_checkout',
            hidden: hideTab || (userType == 'SW' && !subCheckout)
        },
        {
            name: 'Multi-wallet',
            icon: Icons.SettingMultitwallet,
            hash: '#multi_wallet',
            hidden: segments.includes('me_', 'sme') || !isMultiwallet,
        },
        {
            name: 'Transaction fees',
            icon: Icons.SettingTranxFees,
            hash: '#transaction_fees',
            hidden: testMode
        },
        {
            name: 'Statement of Transactions',
            icon: Icons.StatementOfTranx,
            hash: '#open_finance',
            hidden: (userType === 'SW' && subuserRole == 'ME') || 
                    (userType === 'SW' && subuserRole == 'CO' && level != 5 && (kybRequired || has_cashbux_privilege)) || 
                    testMode,
        },
        {
            name: 'Bank Account',
            icon: Icons.BankAccount,
            hash: '#bank_account',
            hidden: testMode || hideTab,
        },
        {
            name: 'Email notification',
            icon: Icons.SettingEmailNotif,
            hash: '#email_notification',
            hidden: testMode
        },
        {
            name: 'SMS notification',
            icon: Icons.SettingSmsNotif,
            hash: '#sms_notification',
            hidden: testMode || hideSmsNotifications
        },
        {
            name: 'User access',
            icon: Icons.SettingUserAccess,
            hash: '#user_access',
            hidden: ['me_', 'gig'].includes(segments) || 

                   (userType === 'SW' && subuserRole == 'ME') ||
                   (userType === 'SW' && subuserRole == 'CO' && level != 5 && kybRequired) || 
                   testMode || isChild,
        },
    ];

    const onTabChange = (tab) => {
        // setHash(tab.target.value);
        window.location.hash = tab.target.value;
    }

    const renderBackText = (hash) => {
        switch (hash) {
            case '#my_checkout':
                return 'My Checkout';
            case '#multi_wallet':
                return 'Mult-wallet';
            case '#transaction_fee':
                return 'Transcation Fee';
            case '#email_notification':
                return 'Email Notification';
            case '#sms_notification':
                return 'SMS Notification';
            case '#user_access':
                return 'User access';
            case '#open_finance':
                return 'Statement of Transactions';
            case '#bank_account':
                return 'Bank Account';
            case '#':
            case '':
                return 'Settings';
            default:
                return 'Settings';
        }
    }

    React.useEffect(() => {
        if (hash === '' && !isMobile && !hideTab) {
            window.location.hash = '#my_checkout';
        }
    }, [])

    return (
        <div style={styles.root}>
            <MetaTag title='Settings' />
            <div hidden={!isMobile} style={styles.mobileHeader}>
                <a className={`link-text--${segments}`} onClick={() => history.goBack()}><Icon type='arrow-left' theme='outlined' /></a>
                <Typography style={styles.title}>{renderBackText(hash)}</Typography>
            </div>
            <div style={styles.btnDiv} hidden={hash !== '' && isMobile}>
                <SettingButtons status={status}
                    selected={hash} segments={segments}
                    handleClick={(e) => onTabChange(e)}
                    settingName='Settings' />
            </div>
            <div hidden={hash === ''} style={styles.contentDiv}>
                {
                    hash === '#my_checkout' ? <MyCheckout hidden={hideTab ||(userType === 'SW' && parseInt(level) < 1 && !testMode && !subCheckout)} /> :
                        hash === '#multi_wallet' ? <MultiwalletSettings /> :
                            hash === '#transaction_fees' ? <TransactionFees /> :
                                hash === '#email_notification' ? <EmailNotif /> :
                                    hash === '#user_access' ? <UserAccess /> :
                                        hash === '#open_finance' ? <StatementOfTransactions /> :
                                            hash === '#bank_account' ? <BankAccount hidden={hideTab || (userType === 'SW' && parseInt(level) < 1 && !testMode && !subCheckout)} /> :
                                                hash === '#sms_notification' ? <SmsNotif /> :    
                                                    null

                }
            </div>
        </div>
    )
}

const styles = {
    root: {
        width: '100%',
        padding: isMobile ? '0 0 122px 0' : '96px 32px 32px 0',
        display: 'flex',
        height: '100%',
        backgroundColor: 'transparent',
        flexDirection: isMobile && 'column',
    },
    btnDiv: {
        width: isMobile ? '100%' : '30%'
    },
    contentDiv: {
        width: isMobile ? '100%' : '532px'
    },
    title: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: 'bold',
        padding: '0 16px'
    },
    mobileHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 16px',
        backgroundColor: '#FFF',
        margin: '0 0 32px 0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)'
    }
}

export default AccountSettings;