import React from "react";
import { Link } from "react-router-dom";
import { Icon, Dropdown, Menu, Pagination, Table } from "antd";
import { UserDataCell } from "../../../../../Transfer/components";

export function AdminsList({
  data,
  isLoading,
  error,
  currentPage,
  setCurrentPage,
}) {
  const pageSize = 10;

  return (
    <div>
      {error ? (
        <div style={{ padding: "16px", color: "red" }}>
          Error fetching data. Please try again by refreshing the page.
        </div>
      ) : (
        <>
          <Table
            rowClassName="table-row-light"
            dataSource={data ? data.results : []}
            columns={[
              {
                title: "Merchant ID",
                dataIndex: "id",
                key: "id",
                fixed: "left",
              },
              {
                title: "Merchant Name",
                dataIndex: "business_name",
                key: "business_name",
              },
              {
                title: "Name & Email",
                dataIndex: "full_name",
                key: "full_name",
                render: (_, record) => (
                  <UserDataCell name={record.full_name} email={record.email} />
                ),
              },
              {
                title: "Rail Type",
                dataIndex: "rail_type",
                key: "rail_type",
              },
              {
                title: "Created Date",
                dataIndex: "created_at",
                key: "created_at",
              },
              {
                title: "Created By",
                dataIndex: "created_by",
                key: "created_by",
                render: (_, record) => (
                  <UserDataCell
                    name={record.created_by_name}
                    email={record.created_by_email}
                  />
                ),
              },
              {
                title: "Action",
                key: "action",
                render: (_, record, index) => (
                  <Dropdown
                    overlay={<MenuItems record={record} />}
                    trigger={["click"]}
                    placement="bottomLeft"
                  >
                    <Icon type="ellipsis" />
                  </Dropdown>
                ),
              },
            ]}
            pagination={false}
            loading={isLoading}
            scroll={{ x: true }}
            style={{ maxWidth: "82dvw" }}
          />
          <Pagination
            size="small"
            total={(data && data.total) || 0}
            defaultPageSize={pageSize}
            pageSize={pageSize}
            current={currentPage}
            onChange={setCurrentPage}
            style={{ paddingTop: "12px", textAlign: "right" }}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </>
      )}
    </div>
  );
}

function MenuItems({ record }) {
  return (
    <Menu>
      <Menu.Item>
        <Link to={`/cashbux/admins/${record.id}`}>Settings</Link>
      </Menu.Item>
    </Menu>
  );
}
